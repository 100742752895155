<template>
<div>
    <div v-show="!ui.editing">

        <v-menu min-width="300">
            <template v-slot:activator="{on, attrs}">
                <v-btn large text v-bind="attrs" v-on="on">
                    <v-icon>
                        mdi-view-module
                    </v-icon>
                    Components
                </v-btn>
            </template>

            <v-list>
                <v-list-item v-for="(element, index) in componentList" :key="'componentList_'+index" style="border-bottom: 1px solid whitesmoke;" @click="add(element)">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{element.label}}
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <!-- <v-icon>mdi-card-plus-outline</v-icon> -->
                        <img v-if="element.image" :src="element.image" style="max-height: 120px; max-width: 200px;"/>
                    </v-list-item-action>
                </v-list-item>

            </v-list>           
        </v-menu>

        <v-btn @click="addTextBlock" class="themed-button-dark">Add Content Block</v-btn>

        <v-switch :true-falue="true" :false-value="false" v-model="model.enableFrench" label="Enable French Content"/>

        <v-container fluid style="width: 100%; max-width: none !important;">
            <v-row>
                <!-- <v-col v-if="ui.componentSelector" cols="3"> -->
                    <!-- <h3>Click to add a component</h3> -->
                    <!-- <v-switch v-for="item in pageComponentList" :key="`component_toggle_${item.component}`"  v-model="pageComponent[item.component]" :true-value="true" :false-value="false" :label="item.description"/> -->

                <!-- </v-col> -->
                <v-col :cols="ui.componentSelector ? 9 : 12">
                    <!-- PAGE LEVEL: {{level}} -->

                    <!-- <h3>Components on page</h3> -->
                    <!-- <v-switch v-model="ui.preview" :true-value="true" :false-value="false" label="Preview"/> -->
                    <v-btn :color="ui.preview ? 'green' : 'white'" fab small @click="ui.preview=!ui.preview" style="position: fixed; bottom: 15px; right: 15px;">
                        <v-icon :color="ui.preview ? 'white' : 'green'">
                            mdi-monitor-eye
                        </v-icon>
                    </v-btn>
                
                    <div v-if="!ui.preview">
                        <draggable v-model="model.definition" :disabled="ui.expansionPanels!=undefined" style="width: 100%;">
                            <v-card v-for="(element, index) in model.definition" :key="'definition_'+index" class="pa-0 hoverable">
                                <v-card-text>
                                    <v-card-actions>
                                        <div>
                                            <v-icon style="margin: 5px;">mdi-menu</v-icon>
                                            {{element.component}}
                                        </div>
                                        <v-spacer></v-spacer>
                                        <v-btn icon>
                                            <v-icon @click="edit(element)">
                                                mdi-pencil
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon color="red" @click="remove(index)">
                                            <v-icon>mdi-delete</v-icon> 
                                        </v-btn>                                
                                    </v-card-actions>

                                    <v-card-text class="pa-0">
                                        <component 
                                            :is="element.component+'_Preview'"
                                            :site="site"
                                            :page="structure.page"
                                            :config="model.definition[index].config"
                                            :language="language"
                                        />                                
                                    </v-card-text>

                                </v-card-text>
                            </v-card>            
                        </draggable>
                    </div>

                    <div v-else :class="[{'preview':ui.preview}]">
                        <component 
                            v-for="(element, index) in model.definition" 
                            :key="'definition_'+index"
                            :is="element.component+'_Preview'"
                            :site="site"
                            :page="structure.page"
                            :config="model.definition[index].config"
                            :language="language"
                        />                    
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>    
    <v-dialog persistent fullscreen v-model="ui.editing" :retain-focus="false">
        <v-card v-if="ui.editing">
            <v-card-title/>
            <v-card-text>
                <Editor v-if="['TextBlock','Resources','PodCast','Stories'].includes(editing.component)" v-model="editing.config" :component="componentMap[editing.component]" :site="site" :page="structure.page" :structure="structure" :language="language" :languages="languages" @ok="ui.editing=false" @save="saveConfig"/>
                <component v-else :is="editing.component" v-model="editing.config" :site="site" :page="structure.page" :structure="structure" :language="language" :languages="languages" @save="saveConfig"/>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!['TextBlock','Resources','PodCast','Stories'].includes(editing.component)" color="success" @click="ui.editing=false">
                    Ok
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import draggable from 'vuedraggable'
import MainBanner from './lib/editMainBanner'
import PageBanner from './lib/editPageBanner'
import CalloutCards from './lib/editCalloutCards'
import ArticleBrowser from './componentEditors/ArticleBrowser'
import PodCast from './componentEditors/PodCast'
import NavBar from './lib/editNavBar' 
import Partners from './lib/editPartners' 
import PageNav from './lib/editPageNav'
import CTATwitter from './lib/editCTATwitter'
import Stories from './componentEditors/Stories'
// import TextBlock from './lib/editTextBlock'
import TextBlock from './componentEditors/TextBlock'
import MediaBlock from './lib/editMediaBlock'
import ClinicalTools from './lib/editClinicalTools'
import NewsSection from './lib/editNews'
import HighlightResources from './lib/editHighlightResources'
import Team from './lib/editTeam'
import Members from '@/components/Admin/lib/editMembers.vue'
import Resources from './componentEditors/Resources'
import Search from './componentEditors/Search'
import Profile from './componentEditors/Profile'
import FindUs from './componentEditors/FindUs'
// import SiteMap from './componentEditors/NoEditor'

import FindUs_Preview from '@/components/FindUs/find_us.vue'
import MainBanner_Preview from '@/components/Banner/mainBanner/mainBanner.vue'
import PageBanner_Preview from '@/components/Banner/pageBanner/pageBanner'
import CalloutCards_Preview from '@/components/Section/Pages/CalloutCards.vue'
import NavBar_Preview from '@/components/Nav/NavBar/NavBar.vue'
import PageNav_Preview from '@/components/Nav/pageNav/pageNav.vue'
import TextBlock_Preview from '@/components/Section/TextBlock/TextBlock.vue'
import MediaBlock_Preview from '@/components/Section/MediaBlock/MediaBlock.vue'
import Resources_Preview from '@/components/Resources/Resources'

import Editor from './editor.vue'

import Image_CallOutCards from '../assets/callOutCards.png'
import Image_TextBlock from '../assets/textBlock.png'
import Image_NavBar from '../assets/navBar.png'
import Image_Resources from '../assets/resources.png'
import Image_Articles from '../assets/articles.png'
import Image_MainBanner from '../assets/mainBanner.png'
import Image_PageBanner from '../assets/pageBanner.png'
import Image_PageNav from '../assets/pageNav.png'
import Image_ArticleBrowser from '../assets/articleBrowser.png'
export default {
    props: {
        structure: {
            type: Object,
            required: true
        },
        level: {
            type: Number,
            required: true
        },
        site: {
            type: Object,
            required: true
        }
    },
    components: {
        // SiteMap,
        draggable,
        MainBanner,        
        NavBar,
        PageBanner,
        PageNav,
        CalloutCards,
        ArticleBrowser,
        CTATwitter,
        Stories,
        TextBlock,
        MediaBlock,
        ClinicalTools,
        NewsSection,
        HighlightResources,
        Editor,
        Search,
        Partners,

        MainBanner_Preview,
        NavBar_Preview,
        PageBanner_Preview,
        PageNav_Preview,   
        CalloutCards_Preview,
        TextBlock_Preview, 
        MediaBlock_Preview,
        FindUs_Preview,
        Resources_Preview,

        Team,
        Members,
        Resources,
        PodCast,
        Profile,
        FindUs
    },
    created: function(){
        let model = JSON.stringify(this.$attrs.value)
        model = JSON.parse(model)
        this.model = model
        if(this.model.enableFrench == undefined){
            this.model.enableFrench = false
        }
        if(model.definition.length==0){
            let componentList = this.componentList
            let navBarIndex = componentList.map(record => record.component).indexOf('NavBar')
            let pageNavIndex = componentList.map(record => record.component).indexOf('PageBanner')
            this.add(componentList[navBarIndex])
            this.add(componentList[pageNavIndex])
            let self = this
            setTimeout(() => {
                self.ui.editing=false
            }, 800);
        }
    },
    data: function(){
        return {
            ui: {
                componentSelector: false,
                expansionPanels: undefined,
                preview: false,
                editing: false
            },
            model: [],
            editing: null
        }
    },
    methods: {
        addTextBlock: function(){
            let componentList = this.componentList
            let componentIndex = componentList.map(record => record.component).indexOf('TextBlock')
            this.add(componentList[componentIndex])
        },
        edit: function(definition){
            // let editing = JSON.stringify(definition)
            // this.editing = JSON.parse(editing)
            this.editing = definition
            this.ui.editing = true
        },
        saveConfig: function(data){
            // this.model.definition[data.definitionIndex].config = data.config
            console.log('save config',{
                model: this.model, 
                data,
                // definition:  this.model.definition[data.definitionIndex]
            })
        },
        saveSlides: function(data){
            this.model.mainBanner.slides = data
            this.$root.site.home.definition.mainBanner.slides = data
        },
        saveNavBar: function(data){            
            this.model.navBar = data
            // this.$root.site.home.definition.navBar = data
            console.log('saveNavBar',data)
        },
        savePageNav: function(data){
            this.model.pageNav = data
        },
        add: function(component) {
            let definition = JSON.stringify(component)
            definition = JSON.parse(definition)
            definition.config = {}
            this.model.definition.push(definition)
            this.edit(this.model.definition[this.model.definition.length-1])
            // this.ui.expansionPanels = this.model.definition.length-1
        },
        remove: function(index){
            if(confirm('Remove component from page?')){
                this.model.definition.splice(index, 1)
            }
        }
    },
    computed: {
        componentMap: function(){
            return {
                TextBlock,
                ArticleBrowser,
                Resources,
                PodCast,
                Stories
            }
        },
        // model: function(){
        //     return this.$attrs.value
        // },
        language: function(){
            return this.$store.getters.language
        },
        languages: function(){
            return this.$store.getters.languages
        },
        // pageComponentList: function(){
        //     let output = [
        //         {
        //             component: 'mainBanner',
        //             description: "Landing page hero banner"
        //         },
        //         {
        //             component: 'navBar',
        //             description: 'Main Nav Bar'
        //         },
        //         {
        //             component: 'customNav',
        //             description: "Custom Link Nav Bar"
        //         },
        //         {
        //             component: 'pageNav',
        //             description: "subpage nav bar"
        //         }
        //     ]

        //     return output
        // }
        componentList: function(){
            let level = this.level
            let output = [
                {
                    component: 'TextBlock',
                    label: 'Content Block',
                    image: Image_TextBlock
                },
                {
                    component: 'CalloutCards',
                    label: 'Callout Card(s)',
                    image: Image_CallOutCards
                },
                // {
                //     component: 'Resources',
                //     label: 'Resource Card(s)',
                //     image: Image_Resources
                // },
                // {
                //     component: 'NewsSection',
                //     label: 'Recent News Card(s)',
                //     image: Image_Articles
                // },
                {
                    component:'NavBar',
                    label: 'Main Navigation Bar',
                    image: Image_NavBar
                },
                {
                    component:'MainBanner',
                    label: 'Banner/Carousel',
                    image: Image_MainBanner
                },
                {
                    component:'PageBanner',
                    label: 'Banner with breadcrumb navigation',
                    image: Image_PageBanner
                },
                // {
                //     component:'HighlightResources'
                // },
                {
                    component:'PageNav',
                    label: 'Navigation Highlights',
                    image: Image_PageNav
                },
                {
                    component: 'ArticleBrowser',
                    label: 'Article Browser',
                    image: Image_ArticleBrowser
                },
                // {
                //     component: 'CTATwitter'
                // },
                // {
                //     component: 'Stories',
                //     label: 'Stories Section',
                //     image: null
                // },
                // {
                //     component: 'MediaBlock'
                // },
                {
                    component: 'ClinicalTools',
                    label: 'Clinical Tools',
                    image: null
                },
                {
                    component: 'ClinicalGuides',
                    label: 'Clinical Guides',
                    image: null
                },
                {
                    component: 'Pins',
                    label: 'Pins',
                    image: null
                },
                {
                    component: 'Profile',
                    label: 'Profile [label]',
                    image: null
                },
                {
                    component: 'SiteMap',
                    label: 'SiteMap [label]',
                    image: null
                },
                {
                    component: 'Team',
                    label: 'Team [label]',
                    image: null
                },
                {
                    component: 'Members',
                    label: 'Members [label]',
                    image: null
                },
                {
                    component: 'PodCast',
                    label: 'PodCast [label]',
                    image: null
                },
                {
                    component: 'Search',
                    label: 'Search [label]',
                    image: null
                },
                {
                    component: 'Partners',
                    label: 'Partners [label]',
                    image: null
                },
                // {
                //     component: 'SiteMap',
                //     label: 'SiteMap',
                //     image: null
                // }
            ]

            // for(let i=0; i<list.length; i++){
            //     let item = list[i]
            //     if(item.component=='PageNav'){
            //         if(level<=1){
            //             output.push(item)
            //         }
            //     }else{
            //         output.push(item)
            //     }
            // }
            return output
        }
    },
    watch: {
        model: {
            deep: true,
            handler: function(){
                this.$emit('input',this.model)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.preview{
    border: 1px solid red;
}
.hoverable:hover{
    border: 1px solid orange;
}
</style>