<template>
  <div>
        <div class="calcDescription vteManagement">
                <h2>Venous Thromboembolism (VTE) Information for Patients</h2>
        </div>
        <div class="vteManagement calcItem groupItem">
            <template v-if="debug">
                <v-btn small @click="completeMe">completeMe</v-btn>
                <v-switch v-model="ui.showKT" :true-value="true" :false-value="false" label="Show KT"/>

                <v-card style="position: fixed; bottom: 0px; left: 0px;">
                    <v-card-title>Context</v-card-title>
                    <v-card-text style="max-height: 800px; overflow-y: auto;">
                        <json-viewer :value="context"/>
                    </v-card-text>
                </v-card>
                
                <v-card style="position: fixed; bottom: 0px; right: 0px;">
                    <v-card-title>form</v-card-title>
                    <v-card-text style="max-height: 800px; overflow-y: auto;">
                        <json-viewer :value="form"/>
                    </v-card-text>
                </v-card>        
            </template>


            <div id="vteManagement">
                <v-form ref="vteManagement" v-model="validForm">
                    <p class="question" style="border-style: none;">We hope this tool can help you address some of your questions and support your conversation with your health care team. Please fill out this form to obtain information at the end. Since your information is not stored in our database, you have the ability to download the information as a pdf document for future reference.</p>
                    <template v-for="question in questions">
                        <div v-if="question.display" :key="`question-${question.id}`" :id="`vteManagement-question-${question.id}`" class="question">
                            <div class="question-body">
                                <span class="debug" v-if="debug">{{ question.id }} - </span>{{ question.body[language] }}
                            </div>
                            <div class="question-subtitle" v-if="question.subtitle && question.subtitle[language] && question.subtitle[language].length>0">
                                {{ question.subtitle[language] }}
                            </div>
                            <div class="question-input">

                                <v-text-field outline v-if="question.type=='textbox'" v-model="form[question.id]" required :rules="rules.answered"/>

                                <v-radio-group dense v-if="question.type=='radio'" v-model="form[question.id]" required :rules="rules.answered">
                                    <v-radio v-for="(choice, choiceIndex) in question.choices" :key="`question-${question.id}-choice-${choiceIndex}`" :value="choice.value">
                                        <template v-slot:label>
                                            <span v-html="choice.label[language]"/>
                                        </template>
                                    </v-radio>
                                </v-radio-group>

                                <div v-if="question.type=='checkbox'">
                                    <v-checkbox dense v-for="(choice, choiceIndex) in question.choices" :key="`question-${question.id}-choice-${choiceIndex}`" :value="choice.value" v-model="form[question.id]" @click="checkboxLogic({question,choice})"  required :rules="rules.answered" :hide-details="choiceIndex!=question.choices.length-1">
                                        <template v-slot:label>
                                            <span v-html="choice.label[language]"/>
                                        </template>
                                    </v-checkbox>
                                </div>
                            </div>
                        </div>                    
                    </template>
                </v-form>

                <template v-if="validForm || ui.showKT">
                    <v-card id="vteManagement-recommendations">
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn depressed alt="Download PDF" title="Download PDF" dark color="#293172" @click="download">
                                Download Summary
                                <v-icon right>
                                    mdi-download-circle-outline
                                </v-icon>                            
                            </v-btn>
                        </v-card-actions>
                        <vue-html2pdf
                            :show-layout="true"
                            :float-layout="false"
                            :enable-download="true"
                            :html-to-pdf-options="{enableLinks:true,filename:'recommendation'}"
                            :preview-modal="false"
                            :paginate-elements-by-height="1200"
                            :pdf-quality="2"
                            :manual-pagination="false"
                            pdf-format="letter"
                            pdf-orientation="portrait"
                            pdf-content-width="100%"
    
                            @progress="onProgress($event)"
                            @hasStartedGeneration="hasStartedGeneration()"
                            @hasGenerated="hasGenerated($event)"
                            ref="html2Pdf"
                            >
                            <div style="padding: 15px;" class="recommendations" slot="pdf-content">
                                <h3 align="center">
                                    Personalized Venous Thromboembolism (VTE) Information Summary
                                </h3>

                                <p>
                                    Dear {{form[1]}},
                                    <br><br>
                                    We know that discovering you have a clot can be quite confusing and stressful. We hope this tool can help you address some of your questions and support your conversation with your health care team.
                                    You have been diagnosed with a clot in: <span v-html="myAnswers(4)"/>
                                </p>

                                <template v-for="(kt, kt_id) in kts">

                                    <div class="section" v-if="kt_id==3"  :key="`kt-header-${kt_id}`">
                                        Why do you have a clot?
                                    </div>

                                    <div v-if="kt_id==5" :key="`kt-header-${kt_id}`">
                                        <div class="section">
                                            When you were diagnosed with your clot, you were started on
                                        </div>
                                        <span v-html="myAnswers(6)"/>                                    
                                    </div>

                                    <div class="section" v-if="kt_id==11 && gotQuestions"  :key="`kt-header${kt_id}`">
                                        If you wanted some additional information, please see below. Please review your concern(s) with your healthcare team. 
                                    </div>

                                    
                                    <div class="kt-prompt" v-if="kt.trigger.includes(true) || ui.showKT" :key="`kt-${kt_id}`">
                                        <span class="debug" v-if="ui.showKT">{{ kt_id }}</span>
                                        <div v-if="kt.body[language]" v-html="kt.body[language]"/>
                                    </div>                
                                </template>
    
                                <hr>

                                <h3>Questionnaire</h3>
                                <v-list dense>
                                    <template v-for="question in questions">
                                        <v-list-item style="border: 1px solid lightgrey; border-radius: 3px; margin: 5px;" :three-line="true" class="userAnswer" v-if="form[question.id]!=null && form[question.id].length>0" :key="`summary-q-${question.id}`">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <strong>{{ question.body[language] }}</strong>
                                                </v-list-item-title>
                                                <div style="font-size: 0.7em; color: #3d3d3d;" v-if="question.subtitle && question.subtitle[language]">
                                                    {{ question.subtitle[language] }}
                                                </div>
                                                <div class="myAnswers" style="font-size: 0.9em; margin: 5px 0px 5px 15px;" v-html="myAnswers(question.id)"/>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list>

                                <section v-html="css"/>    
                            </div>
                        </vue-html2pdf>    
                    </v-card>
                </template>

                <template v-else>
                    <h3 align="center" style="margin: 25px;">Complete the questionnaire for your personalized VTE information summary</h3>
                    <v-list dense>
                        <v-list-item  v-for="question in required" :key="`required-${question.id}`" @click="scrollTo(question)">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ question.body[language] }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                    </v-list>

                </template>

            </div>
        </div>


        <v-snackbar v-model="ui.snackbar.display">
            <span v-html="ui.snackbar.message"/>
            <template v-slot:action="{ attrs }">
                <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="ui.snackbar.display = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
     
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
export default {
    name: "vteManagement",
    props: {
        language: {
            type: String,
            required: false,
            default: "en-ca"
        }
    },
    components: {
        VueHtml2pdf
    },
    created(){
        this.init()
    },
    data(){
        return {
            ui: {
                snackbar: {
                    display: false,
                    message: 'Generating PDF'
                },
                showKT: false
            },
            validForm: false,
            ready: false,
            form: null,
            rules: {
                answered: [
                    v => !!v || 'Required',
                    v => !!v && v.length >0 || 'Required',
                ]
            },
            timer: null
        }
    },
    methods: {
        completeMe(){
            this.form = {"1":"Brian","2":"no","3":"yes > post-op afib","4":["lungs"],"5":["surgery","trauma"],"6":["warfarin","abixaban"],"7":[4,6]}
        },
        init(){
            this.form = {}

            let questions = this.questions
            for(let i=0; i<questions.length; i++){
                let question = questions[i]
                this.$set(this.form,question.id,question.type=='checkbox' ? [] : null)
            }

            let self = this
            setTimeout(() => {
                self.ready = true
            }, 800);
        },
        checkboxLogic({question, choice}){
            let checked = this.form[question.id].includes(choice.value)

            if(checked){
                if(choice.exclusive){
                    this.form[question.id] = [choice.value]
                }else{
                    let selections = this.form[question.id]
                    let choices = question.choices
                    for(let i=0; i<selections.length; i++){
                        let value = selections[i]
                        let index = choices.findIndex((choice)=>{return choice.value==value})
                        let choice = choices[index]
                        if(choice.exclusive){
                            this.form[question.id].splice(i,1)
                        }
                    }
                }

            }
        },
        myAnswers(qid, type){
            let language = this.language
            let questions = this.questions
            let form = this.form

            let index = questions.findIndex((question)=>{return question.id==qid})
            let question = questions[index]

            if(question.type=='checkbox'){
                let selections = form[qid]
                let choices = question.choices
                let output = type=='string' ? '' : '<ul>'
    
                for(let i=0; i<selections.length; i++){
                    let value = selections[i]
                    let choiceIndex = choices.findIndex((choice)=>{return choice.value==value})
                    if(type=='string'){
                        if(output.length>0){
                            output += ', '
                        }
                        output += choices[choiceIndex].label[language]
                    }else{
                        output += `<li>${choices[choiceIndex].label[language]}</li>`
                    }
                }
                output+=`</ul>`
                
                return output
            }

            if(question.type=='radio'){
                let value = form[qid]
                let choices = question.choices
                let choiceIndex = choices.findIndex((choice)=>{return choice.value==value})
                let output = choices[choiceIndex].label[language]
                
                return output
            }
            return form[qid]
        },
        download(){
            let self = this
            this.ui.snackbar.message = "Generating PDF ..."
            this.ui.snackbar.display = true
            clearTimeout(self.timer)
            self.timer = setTimeout(() => {
                self.$refs.html2Pdf.generatePdf()
            }, 800);
        },
        onProgress(e){
            this.ui.snackbar.display = true
            this.ui.snackbar.message = e + '% complete'
        },
        hasStartedGeneration(){
            console.log('hasStartedGeneration')
        },
        hasGenerated(e){
            console.log('hasGenerated',e)
        },
        hasSelected(targets, answers){
            if(!targets || !answers || answers.length==0){
                return undefined
            }

            for(let i=0; i<targets.length; i++){
                let value = targets[i]
                if(answers.includes(value)){
                    return true
                }
            }

            return false
        },
        scrollTo(question){
            document.getElementById(`vteManagement-question-${question.id}`).scrollIntoView(800)
        }
    },
    computed: {
        debug(){
            return this.$store.getters.debug
        },
        questions(){
            function makeTextbox({id, display, valid, body, subtitle, footer}){
                let output = {
                    display,
                    valid,
                    id,
                    type: 'textbox',
                    form: null,
                    body, subtitle, footer
                }

                return output
            }
            function makeCheckbox({id, display, valid, body, subtitle, footer, choices}){
                let output = {
                    display,
                    valid,
                    id,
                    type: 'checkbox',
                    form: [],
                    body, subtitle, footer,
                    choices
                }

                return output
            }
            function makeRadio({id, display, valid, body, subtitle, footer, choices}){
                let output = {
                    display,
                    valid,
                    id,
                    type: 'radio',
                    form: null,
                    body, subtitle, footer,
                    choices
                }

                return output
            }
            let context = this.context
            let form = this.form
            return [
                makeTextbox({
                    id: 1, 
                    valid: (form && form[1]!=null && form[1].length>0),
                    display: true,
                    optional: false,
                    body: {"en-ca": "What is your name?"}
                }),
                makeRadio({
                    id: 2, 
                    valid: (form && form[2]!=null && form[2].length>0),
                    display: true,
                    optional: false,
                    body: {
                        "en-ca": "Is this your first VTE?"
                    },
                    subtitle: {
                        "en-ca":"Venous Thromboembolism (VTE) is a general term encompassing a blood clot that forms in a vein that may / may not embolize (break free and travel to other places in the body). The term VTE encompasses both deep vein thrombosis (DVT) and pulmonary embolism (PE)."
                    },
                    choices:[
                        {
                            label:{
                                "en-ca":"Yes, this is my first VTE"
                            },
                            value:"yes"
                        },
                        {
                            label:{
                                "en-ca":"No, I have had at least one VTE before"
                            },
                            value:"no"
                        }
                    ]
                }),
                makeRadio({
                    id: 3, 
                    valid: context.firstVTE===true || (form && form[3]!=null),
                    display: context.firstVTE===false,
                    optional: false,
                    body: {
                        "en-ca": "Are you already taking anticoagulants?"
                    },
                    subtitle: {
                        "en-ca":"Anticoagulants are “blood thinners”, which are used to treat and prevent blood clots."
                    },
                    choices:[
                        // {
                        //     label: {
                        //         "en-ca": "Yes, due to atrial fibrillation"
                        //     },
                        //     value: "yes > afib"
                        // },
                        {
                            label: {
                                "en-ca": "Yes, due to venous thromboembolism in leg(s) or lung(s)"
                            },
                            value: "yes > vte in leg or lung"
                        },
                        {
                            label: {
                                "en-ca": "Yes, for other reasons"
                            },
                            value: "yes > other"
                        },                        
                        // {
                        //     label: {
                        //         "en-ca": "Yes, due to a mechanical heart valve"
                        //     },
                        //     value: "yes > heart valve"
                        // },
                        // {
                        //     label: {
                        //         "en-ca": "Yes, due to post-operative atrial fibrillation"
                        //     },
                        //     value: "yes > post-op afib"
                        // },
                        // {
                        //     label: {
                        //         "en-ca": "Yes, due to venous thromboembolism in leg(s) or lung(s)"
                        //     },
                        //     value: "yes > vte in leg or lung"
                        // },
                        {
                            label: {
                                "en-ca": "No, I am not taking anticoagulants or antiplatelets"
                            },
                            value: "no"
                        },
                    ]
                }),
                makeCheckbox({
                    id: 4, 
                    valid:  (form && form[4]!=null && form[4].length>0),
                    display: true,
                    optional: false,
                    body: {
                        "en-ca": "Where is your clot?"
                    },
                    choices:[
                        {
                            label: {
                                "en-ca": "Your leg(s)"
                            },
                            value: "legs",
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "Your lung(s)"
                            },
                            value: "lungs",
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "Somewhere other than your leg(s) or your lung(s)"
                            },
                            value: "other",
                            exclusive: true
                        },

                    ]
                }),
                // makeCheckbox({
                //     id: 5, 
                //     valid: (form && form[5]!=null && form[5].length>0),
                //     display: true,
                //     optional: false,
                //     body: {
                //         "en-ca": "In the last three months have you had any of the following medical events:"
                //     },
                //     subtitle: {
                //         "en-ca": "There are many factors that could have contributed to you getting a clot. Please select all that apply to you."
                //     },
                //     choices:[
                //         {
                //             label: {
                //                 "en-ca": "Recent accident"
                //             },
                //             value: "recent accident",
                //             exclusive: false
                //         },
                //         {
                //             label: {
                //                 "en-ca": "surgery "
                //             },
                //             value: "recent surgery",
                //             exclusive: false
                //         },
                //         {
                //             label: {
                //                 "en-ca": "Recent COVID-19 infection "
                //             },
                //             value: "recent COVID-19",
                //             exclusive: false
                //         },
                //         {
                //             label: {
                //                 "en-ca": "Trauma"
                //             },
                //             value: "trauma",
                //             exclusive: false
                //         },
                //         {
                //             label: {
                //                 "en-ca": "None of the above apply to me"
                //             },
                //             value: "none",
                //             exclusive: true
                //         },

                //     ]
                // }),
                makeCheckbox({
                    id: 5, 
                    valid: (form && form[5]!=null && form[5].length>0),
                    display: true,
                    optional: false,
                    body: {
                        "en-ca": "Considering the last three months, do any of the following apply"
                    },
                    choices:[
                        {
                            label: {
                                "en-ca": "Hospitalization"
                            },
                            value: "recent accident",
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "Surgery"
                            },
                            value: "surgery",
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "COVID-19 infection"
                            },
                            value: "COVID-19",
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "Major trauma"
                            },
                            value: "trauma",
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "Increase in estrogen for you (i.e., hormonal replacement, birth control, pregnancy)"
                            },
                            value: "increase in estrogen",
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "None of the above"
                            },
                            value: "none",
                            exclusive: true
                        },

                    ]
                }),
                makeCheckbox({
                    id: 6, 
                    valid: context.takingAnticoagulantForLegOrLung===false || (form && form[6]!=null && form[6].length>0),
                    display: context.takingAnticoagulantForLegOrLung===true,
                    optional: false,
                    body: {
                        "en-ca": "Which of these medications has your doctor started you on?"
                    },
                    subtitle: {
                        "en-ca": "Please select all that apply."
                    },
                    choices:[
                        {
                            label: {
                                "en-ca": "Rivaroxaban or Xarelto<sup>TM</sup>"
                            },
                            value: "rivaroxaban",
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "Apixaban or Eliquis<sup>TM</sup>"
                            },
                            value: "abixaban",
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "Edoxaban or Lixiana<sup>TM</sup>"
                            },
                            value: "edoxaban",
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "Dabigatran or Pradaxa<sup>TM</sup>"
                            },
                            value: "dabigatran",
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "Warfarin"
                            },
                            value: "warfarin",
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "Low molecular weight heparin (e.g., enoxaparin (Lovenox™), tinzaparin (Innohep™), dalteparin (Fragmin™))"
                            },
                            value: "heparin",
                            exclusive: false
                        },

                    ]
                }),
                makeCheckbox({
                    id: 7, 
                    valid: (form && form[7]!=null && form[7].length>0),
                    display: true,
                    optional: false,
                    body: {
                        "en-ca": "What additional information do you want to know about?"
                    },
                    subtitle: {
                        "en-ca": "Please select all that apply."
                    },
                    choices:[
                        {
                            label: {
                                "en-ca": "What are my chances of getting a clot again?"
                            },
                            value: 1,
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "How long will I need to take an anticoagulant?"
                            },
                            value: 2,
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "What is my risk of bleeding?"
                            },
                            value: 3,
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "What is the cost of these drugs?"
                            },
                            value: 4,
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "Do I have to be on a special diet?"
                            },
                            value: 5,
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "When can I start exercising again?"
                            },
                            value: 6,
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "How do I know if the clot in my leg (DVT) is back?"
                            },
                            value: 7,
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "How do I know if the clot in my lung (PE) is back?"
                            },
                            value: 8,
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "Do I need another diagnostic test?"
                            },
                            value: 9,
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "What is post-thrombotic syndrome (PTS)?"
                            },
                            value: 10,
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "Should I use compression stockings?"
                            },
                            value: 11,
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "If I continue taking my anticoagulant medication beyond 6-12 months, will I need to change the dose?"
                            },
                            value: 12,
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "I don’t need any additional information"
                            },
                            value: 13,
                            exclusive: true
                        }

                    ]
                })
            ]
        },
        questionMap(){
            let output = {}
            let questions = this.questions
            for(let i=0; i<questions.length; i++){
                let question = questions[i]
                output[question.id] = question
            }

            return output
        },
        context(){
            if(this.ready){
                let form = this.form
                let firstVTE = form[2]!=null ? form[2]=='yes' : undefined
                let takingAnticoagulantForLegOrLung = form[3] != null ? form[3] == 'yes > vte in leg or lung' : undefined
                let hasLegClot = form[4].length>0 ? form[4].includes('legs') : undefined
                let hasLungClot = form[4].length>0 ? form[4].includes('lungs') : undefined
                let otherClot = form[4].length>0 ? (form[4].includes('other')) : undefined
                let provokedClot = form[5].length>0 ? !form[5].includes('none') : undefined
                // let unprovokedClot = form[5].length>0 ? !provokedClot : undefined
                let unprovokedClot = provokedClot==undefined ? undefined : !provokedClot //this.hasSelected(['none'],form[5])
                // let exitAlert = ['yes > afib','yes > heart valve','yes > post-op afib'].includes(form[3])
                let exitAlert = ['yes > other'].includes(form[3]) || this.hasSelected(['other'],form[4])
    
                return {
                    firstVTE,
                    takingAnticoagulantForLegOrLung,
                    hasLegClot,
                    hasLungClot,
                    otherClot,
                    provokedClot,
                    unprovokedClot,
                    exitAlert,
                    recurrentRisk: {
                        surgery: this.hasSelected(['surgery'], this.form[5]), //recurrentRisk!=undefined ? this.hasSelected(['surgery'], this.form[5]) : undefined,
                        other: this.hasSelected(['recent accident','COVID-19','trauma','increase in estrogen'], this.form[5]), //recurrentRisk!=undefined ? this.hasSelected(['recent accident','COVID-19','trauma','increase in estrogen'], this.form[5]) : undefined,
                    }
                }
            }else{
                return {}
            }

        },
        kts(){
            let context = this.context
            let form = this.form
            let questions = this.questions
            let language = this.language
            return {
                1: {
                    body: {
                        "en-ca": `
                            <p><strong>Deep Vein Thrombosis (DVT)</strong>: a blood clot occurring in the deep veins of the body, most commonly in the legs. </p>

                            Signs of DVT:
                            <ul>
                                <li>Swelling in impacted limb (distal or farther down than clot) </li>
                                <li>Pain and tenderness in affected area</li>
                                <li>Warmth in affected area</li>
                                <li>Discolouration in affected area </li>
                            </ul>
                            <br>
                            You should monitor the following over time: 
                            <ul>
                                <li>Limb swelling</li>
                                <li>Color</li>
                                <li>Pain</li>
                                <li>Limb use and limitations </li>
                            </ul>

                            <p>
                                For more information on DVT, <a href='https://thrombosiscanada.ca/resource/47/en-ca' target='_blank'>click here</a>.<br>
                                For more information on arm DVT, <a href='https://thrombosiscanada.ca/resource/53/en-ca' target='_blank'>click here</a>.
                            </p>
                        `
                    },
                    references: [],
                    trigger: [context.hasLegClot, context.otherClot]
                },
                2: {
                    body: {
                        "en-ca": `
                            <p><strong>Pulmonary Embolism (PE)</strong>: blood clot that occurs in your lungs, which has travelled from your legs or pelvis.</p>

                            PE symptoms:
                            <ul>
                                <li>Sudden onset shortness of breath (dyspnea)</li>
                                <li>Chest pain that worsens with breathing</li>
                                <li>Fainting (syncope)</li>                            
                            </ul>
                            <br>
                            Signs of PE:
                            <ul>
                                <li>Rapid breathing (tachypnea)</li>
                                <li>Increased heart rate (tachycardia)</li>
                                <li>Low oxygen in blood, which can result in confusion, restlessness, and shortness of breath</li>
                                <li>Low blood pressure</li>
                                <li>Coughing up blood</li>                            
                            </ul>
                            <br>
                            You should monitor the following over time: 
                            <ul>
                                <li>Pain and breathing</li>
                                <li>Activity limitations</li>                            
                            </ul>
                            <br>

                            For more information on PE, <a href='https://thrombosiscanada.ca/resource/48/en-ca' target='_blank'>click here</a>. 
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [context.hasLungClot, context.otherClot]
                },
                3: {
                    body: {
                        "en-ca": `
                            <p>Your clot was a provoked clot; it is likely that your selection(s): (${this.myAnswers(5, 'string')}) contributed to your clot. </p>
                            
                            <p>There are many risk factors that may contribute to the development of a venous thrombotic embolism (VTE). Some risk factors may contribute more than others. Should you have a risk factor identified, your clot is referred to as a provoked VTE. Should there not be a risk factor identified, it is referred to as an unprovoked VTE.  </p>
                            <p>Once you have had a VTE, you are more likely to have another.  If it is possible to remove a risk factor (i.e., if that risk factor is transient) that contributed to the development of your VTE, the likelihood of recurrent VTE is lower.</p>
                                                
                            <p>
                            ${this.context.recurrentRisk && this.context.recurrentRisk.surgery ? 'Risk of recurrence due to surgical risk factors is 1% at 1 year and 3% at 5 years.<br>' : ''}
                            ${this.context.recurrentRisk && this.context.recurrentRisk.other ? 'Risk of recurrence due to non-surgical risk factors is 5% at 1 year and 15% at 5 years.<br>' : ''}
                            </p>

                            Some risk factors are highly provoking including:
                            <ul>
                                <li>Surgery</li>
                                <li>Trauma</li>
                            </ul>
                            <br>
                            Some risk factors can be seen as contributing:
                            <ul>
                                <li>Long term immobilization</li>
                                    <ul>
                                        <li>Bed rest</li>
                                        <li>Extremity injury resulting in a cast</li>
                                        <li>Long distance travel (> 8 hours)</li>
                                    </ul>
                                <li>Estrogen</li>
                                    <ul>
                                        <li>Pregnancy</li>
                                        <li>Estrogen therapy</li>
                                    </ul>
                                <li>Other patient factors</li>
                                    <ul>
                                        <li>Obesity</li>
                                        <li>Smoking</li>
                                        <li>Family history of VTE</li>
                                        <li>Age (over 60)</li>
                                        <li>Genetic factors </li>
                                    </ul>
                            </ul>
                        
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [context.provokedClot]
                },
                4: {
                    body: {
                        "en-ca": `
                            <p>Your clot is likely unprovoked (also known as ‘idiopathic’), as none of the predictive factors apply to you. </p>

                            <p>Now that you’ve had a clot, your risk of having another clot is higher than it was prior to your first clot. There is a cumulative risk of recurrence with unprovoked clots when not taking anticoagulants, with a risk of 10% at 1 year and 30% at 3 years.</p>

                            <p>Men have a 75% (1.75-fold) higher risk of recurrence than women. </p>
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [context.unprovokedClot]
                },           
                5: {
                    body: {
                        "en-ca": `
                            <p>Rivaroxaban is an oral anticoagulant. Anticoagulants are commonly referred to as blood thinners. They work by making you take longer to form a blood clot. This effect prevents any new bad clots from forming and prevents any clots that are there from getting any bigger. After a few days of taking an anticoagulant, your clot should be “stable”. This means that the clot there should be stuck firmly in place and will not move anywhere in your body. At this time, it is safe for you to initiate/resume regular activities. Being active, within reason, is recommended. You should listen to your body, be sure not to push yourself too hard that you are left with pain / shortness of breath that does not resolve quickly. </p>
                            <p>For more information, <a href='https://thrombosiscanada.ca/resource/6/en-ca' target='_blank'>click here</a>.</p>
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[6].includes('rivaroxaban')]
                },
                6: {
                    body: {
                        "en-ca": `
                            <p>Apixaban is an oral anticoagulant. Anticoagulants are commonly referred to as blood thinners. They work by making you take longer to form a blood clot. This effect prevents any new bad clots from forming and prevents any clots that are there from getting any bigger. After a few days of taking an anticoagulant, your clot should be “stable”. This means that the clot there should be stuck firmly in place and will not move anywhere in your body. At this time, it is safe for you to initiate/resume regular activities. Being active, within reason, is recommended. You should listen to your body, be sure not to push yourself too hard that you are left with pain / shortness of breath that does not resolve quickly. </p>
                            <p>For more information, <a href='https://thrombosiscanada.ca/resource/3/en-ca' target='_blank'>click here</a>.</p>
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[6].includes('abixaban')]
                },
                7: {
                    body: {
                        "en-ca": `
                            <p>Edoxaban is a blood thinner and anticoagulant. Anticoagulants are commonly referred to as blood thinners. They work by making you take longer to form a blood clot. This effect prevents any new bad clots from forming and prevents any clots that are there from getting any bigger. After a few days of taking an anticoagulant, your clot should be “stable”. This means that the clot there should be stuck firmly in place, and will not move anywhere in your body. At this time, it is safe for you to initiate/resume regular activities. Being active, within reason, is recommended. You should listen to your body, be sure not to push yourself so hard that you are left with pain / shortness of breath that does not resolve quickly. 
                            <p>For more information, <a href='https://thrombosiscanada.ca/resource/10/en-ca' target='_blank'>click here</a>.
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[6].includes('edoxaban')]
                },
                8: {
                    body: {
                        "en-ca": `
                            <p>Dabigatran is an oral anticoagulant. Anticoagulants are commonly referred to as blood thinners. They work by making you take longer to form a blood clot. This effect prevents any new bad clots from forming and prevents any clots that are there from getting any bigger. After a few days of taking an anticoagulant, your clot should be “stable”. This means that the clot should be stuck firmly in place and will not move anywhere in your body. At this time, it is safe for you to initiate/resume regular activities. Being active, within reason, is recommended. You should listen to your body, be sure not to push yourself so hard that you are left with pain / shortness of breath that does not resolve quickly. </p>
                            <p>For more information, <a href='https://thrombosiscanada.ca/wp-content/uploads/2016/10/M159-Dabigatran_Oct2016.pdf' target='_blank'>click here</a>.</p>
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[6].includes('dabigatran')]
                },
                9: {
                    body: {
                        "en-ca": `
                            <p>Warfarin is an anticoagulant, more commonly referred to as a blood thinner. Blood thinners work by making your body take longer to form blood clots. This effect prevents any new bad clots from forming and prevents any clots that are there from getting any bigger. After a few days of taking an anticoagulant, your clot should be “stable”. This means that the clot should be stuck firmly in place and will not move anywhere in your body. Warfarin therapy requires regular blood testing (INR) to ensure you are taking the right amount of warfarin to thin your blood.</p>
                            <p>For more information on Warfarin, <a href='https://thrombosiscanada.ca/wp-content/uploads/2016/10/M159-Warfarin_Oct2016.pdf' target='_blank'>click here</a>.</p>
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[6].includes('warfarin')]
                },
                10: {
                    body: {
                        "en-ca": `
                            <p> LMWH are anticoagulants, which are more commonly referred to as blood thinners. They work by making you take longer to form a blood clot. This effect prevents any new bad clots from forming and prevents any clots that are already there from getting any bigger. After a few days of taking an anticoagulant, your clot should be “stable”. This means that the clot should be stuck firmly in place and will not move anywhere in your body. Given that these are injectable products they are often used short term for specific situations</p>
                            <p>For more information on low molecular weight heparin, <a href='https://thrombosiscanada.ca/wp-content/uploads/2016/10/M159-LMWH_Oct2016.pdf' target='_blank'>click here</a>.</p>`,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[6].includes('heparin')]
                },
                11: {
                    body: {
                        "en-ca": `
                            <p><strong>What are my chances of getting a clot again?</strong></p>                            
                            <p>If you have a provoked clot, and do not take any anticoagulants, your risk of having another clot is greater than it was before. This risk varies and is on a continuum. For a provoked clot, the likelihood of having another clot depends on the circumstances. If your provoked clot was caused by surgical factors, the chances of developing another clot are approximately 1% in the first year and 3% at 5 years. If your provoked clot was caused by non-surgical factors, the chances of developing another clot are approximately 5% in the first year and 15% at 5 years. </p>
                            <p>If you had no identifiable risk factors (an unprovoked clot), and do not take any anticoagulant, your risk of having another clot is higher (10% at 1 year and 30% at 5 years), as we cannot mitigate any risk factors.</p>
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[7].includes(1)]
                },
                12: {
                    body: {
                        "en-ca": `
                            <p><strong>How long will I need to take an anticoagulant?</strong></p>
                            <p>You will need to take an anticoagulant for a minimum of 3 months. It is important to take your anticoagulant as directed, trying not to miss any doses, to ensure your clot is treated well. If there was no external cause for your clot, you may be advised to remain on an anticoagulant long-term.</p>
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[7].includes(2)]
                },
                13: {
                    body: {
                        "en-ca": `
                            <p><strong>What is my risk of bleeding?</strong></p>
                            <p>The use of an anticoagulant is associated with a risk of bleeding (e.g., blood in stools, blood in urine, nosebleeds) which depends a lot on your personal situation. The risk of bleeding is highest when you first start taking the anticoagulant (first 3 months are highest risk period).  It is important to understand the balance of risk (i.e., bleeding) and benefit (i.e., prevent another clot) in your specific situation. Early on, the need to treat your clot outweighs the risk of bleeding. </p>
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[7].includes(3)]
                },
                14: {
                    body: {
                        "en-ca": `
                            <p><strong>What is the cost of these drugs?</strong></p>
                            <p>The cost of anticoagulant treatments varies quite broadly and vary based on your drug coverage and provincial pharmacy plans. Depending on the medication, cost can be less than $1/day or up to $30/day.</p>
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[7].includes(4)]
                },
                15: {
                    body: {
                        "en-ca": `
                            <p><strong>Do I have to be on a special diet?</strong></p>
                            <p>Most anticoagulant treatments, including direct oral anticoagulants and low molecular weight heparin, do not have diet restrictions.</p>
                            <p>If you are considering taking (or already taking) warfarin, your dietary intake may impact how well it works. Avoid dramatic dietary changes without contacting your health professionals.</p>
                            <p>For more information on diet for warfarin, <a href='https://thrombosiscanada.ca/resource/8/en-ca' target='_blank'>click here</a>.</p>
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[7].includes(5)]
                },
                16: {
                    body: {
                        "en-ca": `
                            <p><strong>When can I start exercising again?</strong></p>
                            <p>It is important for you to get back to being as active as you were prior to having your clot. You should gradually increase your level of activity, paying attention to how you feel. If you experience pain or soreness while exercising, you should reduce your level of activity and progress in a manner that listens to your body.</p>
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[7].includes(6)]
                },
                17: {
                    body: {
                        "en-ca": `
                            <p><strong>How do I know if the clot in my leg (DVT) is back?</strong></p>
                            <p>While you are taking anticoagulants, the likelihood of having clots come back is extremely small. Clots often return in the same area. Should clots return, assess for similar symptoms as before. Look for swelling, pain, and tenderness in the previously affected limb.</p>
                    
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[7].includes(7)]
                },
                18: {
                    body: {
                        "en-ca": `
                            <p><strong>How do I know if the clot in my lung (PE) is back?</strong></p>
                            <p>If the clot in your lung is back, you will likely have similar symptoms as the previous clot in your lung. Sudden onset shortness of breath and chest pain are the key symptoms most patients experience. While you are taking anticoagulants, the likelihood of having clots come back is extremely small.</p>
                            <p>For more information, <a href='https://thrombosiscanada.ca/clots/' target='_blank'>click here</a>.</p>
                    
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[7].includes(8)]
                },
                19: {
                    body: {
                        "en-ca": `
                            <p><strong>Do I need another diagnostic test?</strong></p>
                            <p>Repeat diagnostic testing (e.g., leg ultrasound or chest scanning) is typically not performed. With time, improvement in symptoms should occur and repeating testing will not impact future care. It may be done if troublesome signs or symptoms persist.</p>
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[7].includes(9)]
                },
                20: {
                    body: {
                        "en-ca": `
                            <p><strong>What is post-thrombotic syndrome (PTS)?</strong></p>
                            <p>PTS is a long-term complication that may occur after having a deep vein thrombosis (DVT), typically in the leg. It occurs as a result of poor blood flow in the affected extremity. The impacted limb is often larger, darker in color and has a feeling of pain or heaviness. In severe cases, ulcers can occur. It is common to have worse swelling at the end of the day or after increased use of the extremity. If this happens, elevating the limb above the level of your heart is recommended to help bring down the swelling.</p>
                    
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[7].includes(10)]
                },
                21: {
                    body: {
                        "en-ca": `
                            <p><strong>Should I use compression stockings?</strong></p>
                            <p>Compression stockings are tighter at the ankle and loosen as they move up the leg. Most patients use knee high stockings. It is recommended you wait 3- 4 weeks after the clot is diagnosed before using compression stockings, in order to improve fit. The stockings may help with any swelling or dull pain in your leg. </p>
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[7].includes(11)]
                },
                22: {
                    body: {
                        "en-ca": `
                            <p><strong>If I continue taking my anticoagulant medication beyond 6-12 months, will I need to change the dose?</strong></p>
                            <p>For apixaban and rivaroxaban, we have information that supports decreasing the dose in half (from apixaban 5 mg twice daily to apixaban 2.5 mg twice daily OR from rivaroxaban 20 mg daily to rivaroxaban 10 mg daily). Making this dose reduction confers similarly low rates of recurrent blood clots, with a little less risk of bleeding. We do not have dose reduction information for all other blood thinning medications taken by mouth (e.g., dabigatran, edoxaban, warfarin). </p>
                        `,
                        "fr-ca": null
                    },
                    references: [],
                    trigger: [form[7].includes(12)]
                }
            }
        },
        gotQuestions(){
            let kts = this.kts
            let wantInfo = [11,12,13,14,15,16,17,18,19,20,21,22]
            for(let kt_id in kts){
                let kt = kts[kt_id]
                if(wantInfo.includes(parseInt(kt_id,10)) && kt.trigger.includes(true)){
                    return true
                }
            }
            return false
        },
        css(){
            return `
            <style type="text/css">
                ul{
                    margin: 0px 0px 0px 25px;
                }
                li ul{
                    margin: 0px 0px 0px 25px;
                }
                p{
                    margin: 15px 0px 15px 0px;
                }
                .recommendations{
                    font-family: Roboto,sans-serif;
                    padding: 25px;
                    padding: 12px;
                    margin: 8px;
                }

                .kt-prompt{
                    padding: 8px;
                }

                .section{
                    margin-top: 25px;
                    font-weight: bold;
                    font-size: 1.2em;
                }
                .myAnswers ul{
                    margin: 0px 0px 0px 10px;
                }
                .myAnswers li ul{
                    margin: 0px 0px 0px 10px;
                }
            </style>
            `
        },
        required(){
            let invalid = []
            let questions = this.questions
            for(let i=0; i<questions.length; i++){
                let question = questions[i]
                if(question.display && !question.valid){
                    invalid.push(question)
                }
            }
            return invalid
        }
    },
    watch: {
        validForm(){
            if(this.ready && this.validForm){
                this.ui.snackbar.message = `Your personalized venous thromboembolism (VTE) information summary is <a href="javascript:document.getElementById('vteManagement-recommendations').scrollIntoView(800)">ready</a>`
                this.ui.snackbar.display = true
            }
        },
        questions:{
            deep: true,
            handler(after, before){
                let questions = this.questions
                let form = this.form
                for(let i=0; i<questions.length; i++){
                    let question = questions[i]
                    let qBefore = before.find((item)=>{return item.id==question.id})
                    if(!question.display && qBefore.display){
                        if(question.type=='checkbox'){                            
                            form[question.id] = question.type=='checkbox' ? [] : null
                        }else{
                            form[question.id] = question.type=='checkbox' ? [] : null
                        }
                    }
                }
            }
        },
        context:{
            deep: true,
            handler(after, before){
                if(after.exitAlert!=before.exitAlert){
                    if(after.exitAlert){
                        this.ui.snackbar.message = "It seems you may not need more information about VTEs; however, if you would like to learn more, please continue the questionnaire."
                        this.ui.snackbar.display = true
                    }else{
                        this.ui.snackbar.display = false
                    }

                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.debug{
    color: red;
    font-size: 0.7em;
}
.question{
    border: 1px solid lightgray;
    border-radius: 5px;
    margin: 15px;
    padding: 15px;
    font-size: 1.2em;
}
.question-subtitle{
    color: gray;
    font-size: 0.7em;
    font-style: italic;
    line-height: 1em;
    margin-top: 5px;
}

.question-input{
    min-height: 50px;
    width: 100%;
    padding: 5px;
    margin: 5px;
}

// .recommendations{
//     padding: 12px;
//     margin: 8px;
// }

// .kt-prompt{
//     padding: 8px;
//     // margin: 8px;
// }
</style>

<style>
.calcItem #vteManagement input[type="text"],.calcItem #vteManagement  input[type="number"]{
    all: unset;
}
.question .question-input sup{
    font-size: 0.6em;
}
.recommendations a{
    color: blue !important;
    text-decoration: underline !important;
    cursor: pointer !important;
}
</style>