<template>
<div v-if="service">
    <!-- Thrombophilia Algorithm -->
    <div class="calcDescription thrombophiliaAlgorithm">
            <h2 v-if="language==='fr-ca'">Algorithme des tests de dépistage de la thrombophilie</h2>
            <h2 v-else>Thrombophilia Testing Algorithm</h2>             
    </div>
    <div class="thrombophiliaAlgorithm calcItem groupItem">
        <div id="thrombophiliaAlgorithm">
            <template v-if="language==='fr-ca'">
                <div v-if="state=='VTE_OCCURANCE'">
                        <h3>S’agit-il de la première TEV du patient?</h3>
                        <v-radio-group v-model="form.VTE_OCCURANCE" :key="`thrombophiliaAlgorithm-VTE_OCCURANCE`">
                                <v-radio @click="service.send('YES')" label="Oui" value="YES"/>
                                <v-radio @click="service.send('NO')" label="Non" value="NO"/>
                        </v-radio-group>
                        <!-- <label>
                                <input 
                                        type="radio" 
                                        @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('YES');">
                                Yes
                        </label>
                        <label>
                                <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('NO')">
                                No
                        </label> -->
                </div>

                <div v-if="state=='SITE'">
                        <h3>Où siège la TEV?</h3>                                               
                        <v-radio-group v-model="form.SITE" :key="`thrombophiliaAlgorithm-SITE`">
                                <div style="display:inline-flex;">
                                        <v-radio @click="service.send('USUAL')" value="USUAL">
                                            <template v-slot:label>
                                                <b>Foyer habituel :</b>&nbsp;thrombose veineuse profonde (TVP) au bras ou à la jambe, embolie pulmonaire (EP)
                                            </template>
                                        </v-radio>
                                </div>
                                <div style="display: flex;">
                                        <div>
                                            <v-radio @click="service.send('UNUSUAL')" value="UNUSUAL">
                                                <template v-slot:label>
                                                    <b>Foyer inhabituel:</b></span>
                                                </template>
                                            </v-radio>
                                        </div>        
                                        <div style="display: inline-block; position: relative; top: 5px; left: 5px;">
                                            <div style="display: inline-block;">
                                                <a href="javascript:calcToggleExamples('#info-intracranial');"><u>intracrânien</u><span class="toggleExamplesWrapper">?</span></a>
                                            </div>        
                                            <div style="display: inline-block;">        
                                                <a href="javascript:calcToggleExamples('#info-abdominal');"><u>abdominal</u><span class="toggleExamplesWrapper">?</span></a>
                                            </div>        
                                            <div style="display: inline-block;">        
                                                <a href="javascript:calcToggleExamples('#info-art-ven');"><u>artériel et veineux</u><span class="toggleExamplesWrapper">?</span></a>
                                            </div>        
                                        </div>
                                </div>
                        </v-radio-group>
                        <!-- <label>
                                <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('USUAL')">
                                <b>Usual site:</b> arm or leg deep vein thrombosis (DVT), pulmonary embolism (PE)
                        </label>
                        <label>
                                <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('UNUSUAL')">
                                <b>Unusual site: </b><a href="javascript:calcToggleExamples('#info-intracranial');"><u>intracranial</u><span class="toggleExamplesWrapper" style="border:3px solid white;margin-right:6px">?</span></a><a href="javascript:calcToggleExamples('#info-abdominal');"><u>abdominal</u><span class="toggleExamplesWrapper" style="border:3px solid white;margin-right:6px">?</span></a><a href="javascript:calcToggleExamples('#info-art-ven');"><u>both arterial and venous</u><span class="toggleExamplesWrapper" style="border:3px solid white;">?</span></a>
                        </label> -->

                        <div>
                                <div id="info-intracranial" class="calc_definition examples" style="font-style:italic;color:#666;display: none;">
                                    <b>Intracrânien :</b> thrombose du sinus veineux cérébral
                                </div>
                                <div id="info-abdominal" class="calc_definition examples" style="font-style:italic;color:#666;display: none;">
                                    <b>Abdominal :</b> syndrome de Budd-Chiari, obstruction de la veine porte extra-hépatique, thrombose de la veine mésentérique, thrombose de la veine splénique, etc.
                                </div>
                                <div id="info-art-ven" class="calc_definition examples" style="font-style:italic;color:#666;display: none;">
                                    <b>Artériel et veineux :</b> AVC/embolie systémique ou infarctus du myocarde et thrombose veineuse
                                </div>
                        </div>
                </div>

                <div v-if="state=='VTE_STATE'">
                        <h3>La TEV est-elle provoquée ou non provoquée?</h3>
                        <v-radio-group v-model="form.VTE_STATE" :key="`thrombophiliaAlgorithm-VTE_STATE`">
                                <v-radio @click="service.send('PROVOKED')" label="Provoquée " value="PROVOKED"/>
                                <v-radio @click="service.send('UNPROVOKED')" label="Non provoquée" value="UNPROVOKED"/>
                        </v-radio-group>
                        <!-- <label>
                                <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('PROVOKED')">
                                Provoked
                        </label>
                        <label>
                                <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('UNPROVOKED')">
                                Unprovoked
                        </label> -->
                </div>

                <div v-if="state=='RISK_FACTORS'">
                        <h3>Ce patient présente-t-il des facteurs de risque importants ou faibles de TEV?</h3>
                        <v-radio-group v-model="form.RISK_FACTORS" :key="`thrombophiliaAlgorithm-RISK_FACTORS`">
                                <v-radio @click="service.send('STRONG')" value="STRONG">
                                        <template v-slot:label>
                                            <b>Facteur de risque important :</b>&nbsp;
                                                <span v-if="context.site.value=='USUAL'">cancer, intervention chirurgicale, traumatisme, immobilisation, affection grave, cathéter central, grossesse, post-partum.</span>
                                                <span v-if="context.site.value=='UNUSUAL'">cancer, infection/inflammation locale ou adjacente, intervention chirurgicale (y compris un caillot dans une veine abdominale avec césarienne), cirrhose, traumatisme, post-partum</span>
                                        </template>
                                </v-radio>
                                <v-radio @click="service.send('WEAK')" value="WEAK">
                                        <template v-slot:label>
                                                <b>Facteur de risque faible :</b> <span v-if="context.site.value=='USUAL'">hormones (p. ex., contraceptif oral, hormonothérapie), voyage de plus de 5 heures, obésité, blessure mineure (chute), plâtre en dessous du genou ou arthroscopie</span><span v-if="context.site.value=='UNUSUAL'"> hormones (p. ex., contraceptif oral, hormonothérapie), grossesse</span>
                                        </template>
                                </v-radio>
                        </v-radio-group>
                        <!-- <label>
                                <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('STRONG')">
                                <b>Strong risk factors</b> - <span v-if="context.site.value=='USUAL'">cancer, surgery, trauma, immobilization, major medical illness, central line, pregnancy, post-partum</span><span v-if="context.site.value=='UNUSUAL'">cancer, local or adjacent infection / inflammation, surgery (includes abdominal vein clot with Caesarean section), cirrhosis, trauma, post-partum</span>
                        </label>
                        <label>
                                <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('WEAK')">
                                <b>Weak Risk Factor</b> – <span v-if="context.site.value=='USUAL'">hormones (e.g. OCT, HRT), travel longer than 5 hours, obesity, minor injury (fall), below knee cast or arthroscopy</span><span v-if="context.site.value=='UNUSUAL'">hormones (e.g. OCT, HRT), pregnancy</span>
                        </label> -->
                </div>

                <div id="thrombophilia-patient-outcome" v-if="state == 'RECOMMENDATION'">
                        <div id="recommendation-container" classs="recommendation-container" v-show="displayRecommendationContainer===true">
                                <h3><u>RECOMMANDATION</u> 
                                        <span id="excludePDF">
                                        <v-btn depressed @click="download_PDF('#thrombophilia-patient-outcome',['#excludePDF','#show-hide-btn']);" alt="Télécharger le PDF" title="Télécharger le PDF" style="float:right;margin: 5px; border:1px solid #dcdcdc;">
                                                PDF
                                                <v-icon right>
                                                        mdi-download-circle-outline
                                                </v-icon>
                                        </v-btn>

                                        <!-- <image src="../guides/resources/downloadpdf.png" onClick="javascript:downloadPDF('#thrombophilia-patient-outcome',['#excludePDF','#show-hide-btn']);" alt="Download PDF" title="Download PDF" style="float:right;margin:-10px; border:1px solid #dcdcdc;"/> -->
                                </span></h3>

                                <div v-if="context.vte_occurance.value == 'NO'" class="summary">
                                        <h3>TEV récurrente</h3>
                                        Envisager de consulter un spécialiste et d’effectuer un test de dépistage d’une thrombophilie chez les patients dans au moins l’une des situations suivantes :
                                        <ul>
                                            <li>&lt;50 ans</li>
                                            <li>Forts antécédents familiaux (proches au premier degré affectés avant l’âge de 50 ans)</li>
                                            <li>Au moins une TEV non provoquée dans un foyer habituel ou inhabituel</li>
                                        </ul>
                                        <b>OU</b> deux TEV provoquées ou plus
                                </div>

                                <div v-if="context.vte_occurance.value == 'YES'">
                                        <div v-if="context.risk_factor.value == 'STRONG'">
                                            <p>Le test de dépistage d’une thrombophilie n’est PAS nécessaire.</p>
                                        </div>
                                        <div v-else>
                                                <p v-if="context.risk_factor.value == 'WEAK' || (context.site.value=='USUAL' && context.vte_state.value=='UNPROVOKED')">Le test de dépistage d’une thrombophilie* n’est PAS nécessaire pour la PLUPART des patients.</p>
                                                <p v-if="context.risk_factor.value == 'WEAK' || (context.site.value=='USUAL' && context.vte_state.value=='UNPROVOKED')"><b>Envisager un test de dépistage d’une thrombophilie</b> dans au moins l’une des situations suivantes :</p>
                                                <p v-if="context.site.value=='UNUSUAL' && context.vte_state.value=='UNPROVOKED'">Envisager un test de dépistage d’une thrombophilie* AVEC consultation d’un spécialiste dans au moins l’une des situations suivantes :</p>
                                                <p>
                                                    <ul>
                                                        <li>Maladie artérielle concomitante.</li>
                                                        <li>Jeune âge (&lt;50 ans).</li>
                                                        <li>Forts antécédents familiaux, p. ex., proches au premier degré affectés avant l’âge de 50 ans</li>
                                                        <li>Anomalies de la formule sanguine complète (p. ex., anémie, thrombocytopénie, thrombocytose, polyglobulie), envisager une <a href="javascript:calcToggleExamples('#info-mpn');"><u>NMP</u><span class="toggleExamplesWrapper">?</span></a>, un <a href="javascript:calcToggleExamples('#info-aps');"><u>SAP</u><span class="toggleExamplesWrapper">?</span></a>, une <a href="javascript:calcToggleExamples('#info-pnh');"><u>HPN</u><span class="toggleExamplesWrapper">?</span></a>, une <a href="javascript:calcToggleExamples('#info-hit');"><u>TIH</u><span class="toggleExamplesWrapper">?</span></a></li>
                                                        <li>Maladie auto-immune.</li>
                                                    </ul>
                                                </p>
                                                <p>Envisager un test de dépistage du SAP, en particulier en cas d’événements artériels ou récurrents, et/ou d’avortements spontanés récurrents. En cas d’anomalies dans les résultats, refaire le test dans 12 semaines, puis orienter le patient chez un spécialiste.</p>
                                                <p>Les anticoagulants peuvent interférer avec de nombreux tests de dépistage d’une thrombophilie. Votre patient est-il sous anticoagulants? <a href="javascript:void();" @click="toggleRecommendationContainerDisplay()">Cliquez ici pour de plus amples renseignements..</a></p>
                                                <p><b>*</b> <i>N’envisager le dépistage d’une thrombophilie que si les résultats du test peuvent avoir une incidence sur la prise en charge du patient.</i></p>
                                                <div>
                                                        <div id="info-mpn" class="calc_definition examples" style="font-style:italic;color:#666;display: none;">
                                                                <p><b>Quand effectuer un test de dépistage des néoplasies myéloprolifératives (NMP)?</b></p>
                                                                <ul>
                                                                    <li>Taux élevés de plaquettes, valeur élevée de l’hématocrite et/ou de la numération leucocytaire.</li>
                                                                    <li>Anomalies à l’analyse du frottis de sang comprenant des hématies en forme de larme, des globules rouges nucléés et/ou une pancytopénie.</li>
                                                                </ul>
                                                        </div>
                                                        <div id="info-aps" class="calc_definition examples" style="font-style:italic;color:#666;display: none;">
                                                                <p><b>Quand effectuer un test de dépistage du syndrome des antiphospholipides (SAP)?</b></p>
                                                                <ul>
                                                                        <li>Thromboembolie veineuse (TEV) récurrente non provoquée.</li>
                                                                        <li>TEV récurrente malgré un traitement anticoagulant adéquat.</li>
                                                                        <li>Maladie auto-immune (p. ex., lupus érythémateux disséminé ou LED).</li>
                                                                        <li>Avortements spontanés récurrents.</li>
                                                                </ul>
                                                        </div>
                                                        <div id="info-pnh" class="calc_definition examples" style="font-style:italic;color:#666;display: none;">
                                                                <p><b>Quand effectuer un test de dépistage de l’hémoglobinurie paroxystique nocturne (HPN)?</b></p>
                                                                <ul>
                                                                        <li>Jeune âge (&lt;50 ans)</li>
                                                                        <li>Foyer inhabituel et/ou thrombose récurrente non provoquée</li>
                                                                        <li>Signes d’hémolyse</li>
                                                                        <li>Anémie ou thrombocytopénie ou leucopénie</li>
                                                                </ul>
                                                        </div>
                                                        <div id="info-hit" class="calc_definition examples" style="font-style:italic;color:#666;display: none;">
                                                                <p><b>Quand effectuer un test de dépistage de la thrombocytopénie induite par l’héparine (TIH)?</b></p>
                                                                <ul>
                                                                        <li>Exposition récente (dans les 5 à 10 derniers jours) à l’héparine</li>
                                                                        <li>Thrombocytopénie (diminution de > 50 %)</li>
                                                                        <li>Thrombose confirmée ou risque élevé de thrombose (p. ex., postopératoire)</li>
                                                                        <li>Aucune autre cause de thrombocytopénie (p. ex., consommation)</li>
                                                                </ul>
                                                        </div>                                                    
                                                </div>                                                
                                        </div>                                                
                                </div>

                                <div id="thrombophilia-patient-summary" class="buttonContainer" style="text-align:center;">
                                        <div><v-btn id="show-hide-btn" @click="togglePatientSummaryDisplay()"><span v-if="displayPatientSummary===false">Afficher le résumé du patient</span><span v-else>Masquer le résumé du patient</span></v-btn></div>
                                        <div v-show="displayPatientSummary===true" class="calc_definition examples" style="font-size:12px;font-style:italic;color:#666; text-align:left;">
                                                <table border="1" cellpadding="11">
                                                        <thead style="background-color:darkgrey">
                                                                <tr>
                                                                        <th colspan="2">Profil du patient</th>
                                                                </tr>
                                                        </thead>
                                                        <tbody>
                                                                <tr>
                                                                        <td><b>S’agit-il de la première TEV du patient?</b></td>
                                                                        <td>{{context.vte_occurance.value_locale[context.vte_occurance.value][language.replace("-", '_')]}}</td>
                                                                </tr>
                                                                <tr v-if='context.site.value!==null'>
                                                                        <td><b>Foyer habituel ou inhabituel </b></td>
                                                                        <td>{{context.site.full_description[language.replace("-", '_')]}}</td>
                                                                </tr>
                                                                <tr v-if='context.vte_state.value!==null'>
                                                                        <td><b>TEV provoquée ou non provoquée</b></td>
                                                                        <td>{{context.vte_state.value_locale[context.vte_state.value][language.replace("-", '_')]}}</td>
                                                                </tr>
                                                                <tr v-if="context.risk_factor.value!==null">
                                                                        <td><b>Facteur de risque important ou facteur de risque faible</b></td>
                                                                        <td>{{context.risk_factor.full_description[language.replace("-", '_')]}}</td>
                                                                </tr>
                                                        </tbody>
                                                </table>
                                        </div>
                                </div>
                        </div>
                        <div id='thrombophilia-anticoagulant-extension' v-show="displayRecommendationContainer===false">
                                <p>Tests de dépistage d’une thrombophilie* pouvant être effectués pendant un traitement anticoagulant :</p>
                                <ul>
                                        <li>Mutation du facteur V de Leiden</li>
                                        <li>Mutation du gène de la prothrombine</li>
                                        <li>Mutation de JAK2</li>
                                        <li>Cytofluorométrie pour le diagnostic de l’HPN</li>
                                </ul>
                                <p style='text-indent: 25px;'><a href="https://s3.ca-central-1.amazonaws.com/thrombosiscanada.ca/uploads/hcp/practice/resources/Impact_of_Anticoagualants_on_Thrombopilia_Testing_26May2020_FR.pdf" target="_blank"><i>Effets des anticoagulants sur les tests courants de dépistage d’une thrombophilie</i></a></p>
                                <p>Les tests de dépistage d’une thrombophilie* qui nécessitent l’interruption de l’anticoagulothérapie sont <a href="https://s3.ca-central-1.amazonaws.com/thrombosiscanada.ca/uploads/hcp/practice/resources/Tests_to_Consider_When_Patients_are_Not_on_Anticoagulants_June11_2024_FR.pdf" target="_blank"><i>indiqués dans le tableau</i></a></p>
                                <p>L’interruption de l’anticoagulothérapie est définie comme suit :</p>
                                <ul>
                                        <li>Interruption de la warfarine pendant au moins 1 semaine</li>
                                        <li>Interruption de l’AOD pendant au moins 2 jours (4 jours si le patient prend le dabigatran et que sa ClCr est &lt;50 mL/min)</li>
                                        <li>Interruption de l’HNF ou de l’HBPM pendant au moins 24 heures</li>
                                </ul>
                                <p><b>*</b><i> N’envisager le dépistage d’une thrombophilie que si les résultats du test peuvent avoir une incidence sur la prise en charge du patient.</i></p>
                                <div style="text-align:center;">
                                        <v-btn @click="toggleRecommendationContainerDisplay()">&lt; Retour à la recommandation et au résumé</v-btn>
                                </div>
                        </div>
                </div>
            </template>
            <template v-else>
                <div v-if="state=='VTE_OCCURANCE'">
                        <h3>Is this the patient’s first VTE?</h3>
                        <v-radio-group v-model="form.VTE_OCCURANCE" :key="`thrombophiliaAlgorithm-VTE_OCCURANCE`">
                                <v-radio @click="service.send('YES')" label="YES" value="YES"/>
                                <v-radio @click="service.send('NO')" label="NO" value="NO"/>
                        </v-radio-group>
                        <!-- <label>
                                <input 
                                        type="radio" 
                                        @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('YES');">
                                Yes
                        </label>
                        <label>
                                <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('NO')">
                                No
                        </label> -->
                </div>

                <div v-if="state=='SITE'">
                        <h3>Where is the VTE?</h3>                                               
                        <v-radio-group v-model="form.SITE" :key="`thrombophiliaAlgorithm-SITE`">
                                <div style="display:inline-flex;">
                                        <v-radio @click="service.send('USUAL')" value="USUAL">
                                            <template v-slot:label>
                                                <b>Usual site:</b>&nbsp;arm or leg deep vein thrombosis (DVT), pulmonary embolism (PE)
                                            </template>
                                        </v-radio>
                                </div>
                                <div style="display: flex;">
                                        <div>
                                            <v-radio @click="service.send('UNUSUAL')" value="UNUSUAL">
                                                <template v-slot:label>
                                                    <b>Unusual site: </b></span>
                                                </template>
                                            </v-radio>
                                        </div>        
                                        <div style="display: inline-block; position: relative; top: 5px; left: 5px;">
                                            <div style="display: inline-block;">
                                                <a href="javascript:calcToggleExamples('#info-intracranial');"><u>intracranial</u><span class="toggleExamplesWrapper">?</span></a>
                                            </div>        
                                            <div style="display: inline-block;">        
                                                <a href="javascript:calcToggleExamples('#info-abdominal');"><u>abdominal</u><span class="toggleExamplesWrapper">?</span></a>
                                            </div>        
                                            <div style="display: inline-block;">        
                                                <a href="javascript:calcToggleExamples('#info-art-ven');"><u>both arterial and venous</u><span class="toggleExamplesWrapper">?</span></a>
                                            </div>        
                                        </div>
                                </div>
                        </v-radio-group>
                        <!-- <label>
                                <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('USUAL')">
                                <b>Usual site:</b> arm or leg deep vein thrombosis (DVT), pulmonary embolism (PE)
                        </label>
                        <label>
                                <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('UNUSUAL')">
                                <b>Unusual site: </b><a href="javascript:calcToggleExamples('#info-intracranial');"><u>intracranial</u><span class="toggleExamplesWrapper" style="border:3px solid white;margin-right:6px">?</span></a><a href="javascript:calcToggleExamples('#info-abdominal');"><u>abdominal</u><span class="toggleExamplesWrapper" style="border:3px solid white;margin-right:6px">?</span></a><a href="javascript:calcToggleExamples('#info-art-ven');"><u>both arterial and venous</u><span class="toggleExamplesWrapper" style="border:3px solid white;">?</span></a>
                        </label> -->

                        <div>
                                <div id="info-intracranial" class="calc_definition examples" style="font-style:italic;color:#666;display: none;">
                                        <b>Intracranial</b>: cerebral venous sinus thrombosis
                                </div>
                                <div id="info-abdominal" class="calc_definition examples" style="font-style:italic;color:#666;display: none;">
                                        <b>Abdominal</b>: including Budd-Chiari syndrome, extrahepatic portal vein obstruction, mesenteric vein thrombosis, splenic vein thrombosis
                                </div>
                                <div id="info-art-ven" class="calc_definition examples" style="font-style:italic;color:#666;display: none;">
                                        <b>Both arterial and venous</b>: stroke/systemic embolism or myocardial infarction and venous thrombosis
                                </div>
                        </div>
                </div>

                <div v-if="state=='VTE_STATE'">
                        <h3>Is the VTE provoked or unprovoked?</h3>
                        <v-radio-group v-model="form.VTE_STATE" :key="`thrombophiliaAlgorithm-VTE_STATE`">
                                <v-radio @click="service.send('PROVOKED')" label="PROVOKED" value="PROVOKED"/>
                                <v-radio @click="service.send('UNPROVOKED')" label="UNPROVOKED" value="UNPROVOKED"/>
                        </v-radio-group>
                        <!-- <label>
                                <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('PROVOKED')">
                                Provoked
                        </label>
                        <label>
                                <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('UNPROVOKED')">
                                Unprovoked
                        </label> -->
                </div>

                <div v-if="state=='RISK_FACTORS'">
                        <h3>Does this patient have strong or weak risk factors for VTE?</h3>
                        <v-radio-group v-model="form.RISK_FACTORS" :key="`thrombophiliaAlgorithm-RISK_FACTORS`">
                                <v-radio @click="service.send('STRONG')" value="STRONG">
                                        <template v-slot:label>
                                                <b>Strong risk factors</b> -&nbsp;
                                                <span v-if="context.site.value=='USUAL'">cancer, surgery, trauma, immobilization, major medical illness, central line, pregnancy, post-partum</span>
                                                <span v-if="context.site.value=='UNUSUAL'">cancer, local or adjacent infection / inflammation, surgery (includes abdominal vein clot with Caesarean section), cirrhosis, trauma, post-partum</span>
                                        </template>
                                </v-radio>
                                <v-radio @click="service.send('WEAK')" value="WEAK">
                                        <template v-slot:label>
                                                <b>Weak Risk Factor</b> – <span v-if="context.site.value=='USUAL'">hormones (e.g. OCT, HRT), travel longer than 5 hours, obesity, minor injury (fall), below knee cast or arthroscopy</span><span v-if="context.site.value=='UNUSUAL'">hormones (e.g. OCT, HRT), pregnancy</span>
                                        </template>
                                </v-radio>
                        </v-radio-group>
                        <!-- <label>
                                <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('STRONG')">
                                <b>Strong risk factors</b> - <span v-if="context.site.value=='USUAL'">cancer, surgery, trauma, immobilization, major medical illness, central line, pregnancy, post-partum</span><span v-if="context.site.value=='UNUSUAL'">cancer, local or adjacent infection / inflammation, surgery (includes abdominal vein clot with Caesarean section), cirrhosis, trauma, post-partum</span>
                        </label>
                        <label>
                                <input type="radio" @click="this.event.preventDefault();this.event.stopImmediatePropagation();calc.thrombophiliaAlgorithm.app.service.send('WEAK')">
                                <b>Weak Risk Factor</b> – <span v-if="context.site.value=='USUAL'">hormones (e.g. OCT, HRT), travel longer than 5 hours, obesity, minor injury (fall), below knee cast or arthroscopy</span><span v-if="context.site.value=='UNUSUAL'">hormones (e.g. OCT, HRT), pregnancy</span>
                        </label> -->
                </div>

                <div id="thrombophilia-patient-outcome" v-if="state == 'RECOMMENDATION'">
                        <div id="recommendation-container" classs="recommendation-container" v-show="displayRecommendationContainer===true">
                                <h3><u>RECOMMENDATION</u> 
                                        <span id="excludePDF">
                                            <!--
                                        <v-btn depressed @click="download_PDF('#thrombophilia-patient-outcome',['#excludePDF','#show-hide-btn']);" alt="Download PDF" title="Download PDF" style="float:right;margin: 5px; border:1px solid #dcdcdc;">
                                                PDF
                                                <v-icon right>
                                                        mdi-download-circle-outline
                                                </v-icon>
                                        </v-btn>
                                            -->
                                            <template>                                              
                                                <v-btn v-if="!pdfDownload.pdfLink.url || debug" alt="Generate PDF" title="Generate PDF"
                                                    depressed  
                                                    @click="getPDF('thrombophilia-patient-outcome')"
                                                    :loading="pdfDownload.generatingPDF"
                                                    style="float:right;margin: 5px; border:1px solid #dcdcdc; text-transform: capitalize;">
                                                        Generate PDF <v-icon right>mdi-cog-clockwise</v-icon>
                                                </v-btn>
                                                <v-btn v-if="pdfDownload.pdfLink.url || debug" alt="Download PDF" title="Download PDF"
                                                    style="float:right;margin: 5px; border:1px solid #dcdcdc; text-transform: capitalize;"
                                                    target="'_blank'" 
                                                    :href="pdfDownload.pdfLink.url">
                                                        Download PDF <v-icon right style="color:#261062;">mdi-cloud-download</v-icon>
                                                </v-btn>
                                            </template>
                                            
                                        <!-- <image src="../guides/resources/downloadpdf.png" onClick="javascript:downloadPDF('#thrombophilia-patient-outcome',['#excludePDF','#show-hide-btn']);" alt="Download PDF" title="Download PDF" style="float:right;margin:-10px; border:1px solid #dcdcdc;"/> -->
                                </span></h3>

                                <div v-if="context.vte_occurance.value == 'NO'" class="summary">
                                        <h3>Recurrent VTE</h3>
                                        Consider specialist consultation and thrombophilia testing for patients with at least one of:
                                        <ul>
                                                <li>&lt;50 years old</li>
                                                <li>Strong family history (first-degree family members affected at &lt;50 years old)</li>
                                                <li>At least one unprovoked VTE in usual or unusual site</li>
                                        </ul>							
                                        <b>OR</b> two or more provoked VTEs
                                </div>

                                <div v-if="context.vte_occurance.value == 'YES'">
                                        <div v-if="context.risk_factor.value == 'STRONG'">
                                                <p>Thrombophilia testing is NOT required.</p>
                                        </div>
                                        <div v-else>
                                                <p v-if="context.risk_factor.value == 'WEAK' || (context.site.value=='USUAL' && context.vte_state.value=='UNPROVOKED')">Thrombophilia testing* is NOT required for MOST patients.</p>
                                                <p v-if="context.risk_factor.value == 'WEAK' || (context.site.value=='USUAL' && context.vte_state.value=='UNPROVOKED')"><b>Consider thrombophilia testing</b> if 1 or more of:</p>
                                                <p v-if="context.site.value=='UNUSUAL' && context.vte_state.value=='UNPROVOKED'">Consider thrombophilia testing* WITH specialist consultation if 1 or more of:</p>
                                                <p>
                                                        <ul>
                                                                <li>Concomitant arterial disease</li>
                                                                <li>Young age (&lt;50 years old)</li>
                                                                <li>Strong family history, i.e. first-degree family members affected at &lt;50 years old </li>
                                                                <li>CBC abnormalities (e.g. anemia, thrombocytopenia, thrombocytosis, polycythemia), consider <a href="javascript:calcToggleExamples('#info-mpn');"><u>MPN</u><span class="toggleExamplesWrapper">?</span></a><a href="javascript:calcToggleExamples('#info-aps');"><u>APS</u><span class="toggleExamplesWrapper">?</span></a><a href="javascript:calcToggleExamples('#info-pnh');"><u>PNH</u><span class="toggleExamplesWrapper">?</span></a><a href="javascript:calcToggleExamples('#info-hit');"><u>HIT</u><span class="toggleExamplesWrapper">?</span></a></li>
                                                                <li>Autoimmune disease</li>
                                                        </ul>
                                                </p>
                                                <p>Consider APS testing, especially in the case of arterial or recurrent events, and/or recurrent pregnancy loss. If abnormal results, repeat testing in 12 weeks, then refer.</p>
                                                <p>Anticoagulants can interfere with many thrombophilia tests. Is your patient on anticoagulants? <a href="javascript:void();" @click="toggleRecommendationContainerDisplay()">Click here for more information.</a></p>
                                                <p><b>*</b> <i>Only consider thrombophilia testing if the test results will make a difference in how you manage the patient.</i></p>
                                                <div>
                                                        <div id="info-mpn" class="calc_definition examples" style="font-style:italic;color:#666;display: none;">
                                                                <p><b>When to test for myeloproliferative neoplasms (MPN)</b></p>
                                                                <ul>
                                                                                <li>elevated platelets, hematocrit and/or white count</li>
                                                                                <li>blood film abnormalities including teardrop cells, nucleated red blood cells, and/or pancytopenia</li>
                                                                </ul>
                                                        </div>
                                                        <div id="info-aps" class="calc_definition examples" style="font-style:italic;color:#666;display: none;">
                                                                <p><b>When to test for antiphospholipid syndrome (APS)</b></p>
                                                                <ul>
                                                                        <li>recurrent unprovoked venous thromboembolism (VTE)</li>
                                                                        <li>recurrent VTE despite adequate anticoagulant therapy</li>
                                                                        <li>autoimmune disease (e.g., systemic lupus erythematosus (SLE))</li>
                                                                        <li>recurrent pregnancy loss</li>
                                                                </ul>
                                                        </div>
                                                        <div id="info-pnh" class="calc_definition examples" style="font-style:italic;color:#666;display: none;">
                                                                <p><b>When to test for paroxysmal nocturnal hemoglobinuria (PNH)</b></p>
                                                                <ul>
                                                                        <li>young age (&lt;50 years)</li>
                                                                        <li>unusual site and/or recurrent unprovoked thrombosis</li>
                                                                        <li>evidence of hemolysis</li>
                                                                        <li>anemia or thrombocytopenia or leucopenia</li>
                                                                </ul>
                                                        </div>
                                                        <div id="info-hit" class="calc_definition examples" style="font-style:italic;color:#666;display: none;">
                                                                <p><b>When to test for heparin-induced thrombocytopenia (HIT)</b></p>
                                                                <ul>
                                                                        <li>recent (within 5-10 days) heparin exposure</li>
                                                                        <li>thrombocytopenia (&gt;50% decrease)</li>
                                                                        <li>confirmed thrombosis, or at high risk for thrombosis (e.g., post-operative)</li>
                                                                        <li>no alternative cause for thrombocytopenia (e.g., consumption)</li>
                                                                </ul>
                                                        </div>                                                    
                                                </div>                                                
                                        </div>                                                
                                </div>

                                <div id="thrombophilia-patient-summary" class="buttonContainer" style="text-align:center;">
                                        <div><v-btn id="show-hide-btn" @click="togglePatientSummaryDisplay()"><span v-if="displayPatientSummary===false">Show Patient Summary</span><span v-else>Hide Patient Summary</span></v-btn></div>
                                        <div v-show="displayPatientSummary===true" class="calc_definition examples" style="font-size:12px;font-style:italic;color:#666; text-align:left;">
                                                <table border="1" cellpadding="11">
                                                        <thead style="background-color:darkgrey">
                                                                <tr>
                                                                        <th colspan="2">Patient Summary</th>
                                                                </tr>
                                                        </thead>
                                                        <tbody>
                                                                <tr>
                                                                        <td><b>Is this the patient’s first VTE?</b></td>
                                                                        <td>{{context.vte_occurance.value_locale[context.vte_occurance.value][language.replace("-", '_')]}}</td>
                                                                </tr>
                                                                <tr v-if='context.site.value!==null'>
                                                                        <td><b>Where is the VTE?</b></td>
                                                                        <td>{{context.site.full_description[language.replace("-", '_')]}}</td>
                                                                </tr>
                                                                <tr v-if='context.vte_state.value!==null'>
                                                                        <td><b>Is the VTE provoked or unprovoked?</b></td>
                                                                        <td>{{context.vte_state.value_locale[context.vte_state.value][language.replace("-", '_')]}}</td>
                                                                </tr>
                                                                <tr v-if="context.risk_factor.value!==null">
                                                                        <td><b>Does this patient have strong or weak risk factors for VTE?</b></td>
                                                                        <td>{{context.risk_factor.full_description[language.replace("-", '_')]}}</td>
                                                                </tr>
                                                        </tbody>
                                                </table>
                                        </div>
                                </div>
                        </div>
                        <div id='thrombophilia-anticoagulant-extension' v-show="displayRecommendationContainer===false">
                                <p>Thrombophilia tests* that can be done during anticoagulant therapy:</p>
                                <ul>
                                        <li>Factor V Leiden mutation</li>
                                        <li>Prothrombin gene mutation</li>
                                        <li>JAK2 mutation</li>
                                        <li>PNH flow cytometry</li>
                                </ul>
                                <p style='text-indent: 25px;'><a href="https://s3.ca-central-1.amazonaws.com/thrombosiscanada.ca/uploads/hcp/practice/resources/Impact_of_Anticoagualants_on_Thrombopilia_Testing_26May2020.pdf" target="_blank"><i>Impact of anticoagulants on commonly ordered thrombophilia tests</i></a></p>
                                <p>Thrombophilia tests* that require anticoagulant interruption are <a href="https://s3.ca-central-1.amazonaws.com/thrombosiscanada.ca/uploads/hcp/practice/resources/Tests_to_ConsiderWhenPatientsAreNotOnAnticoagulants_06Aug2020.pdf" target="_blank"><i>shown in the table</i></a></p>
                                <p>Anticoagulant interruption is defined as:</p>
                                <ul>
                                        <li>Warfarin interruption for at least 1 week</li>
                                        <li>DOAC interruption for at least 2 days (4 days if patient on dabigatran and has CrCl &lt;50 mL/min)</li>
                                        <li>UFH or LMWH interruption for at least 24 hours</li>
                                </ul>
                                <p><b>*</b><i> Only consider thrombophilia testing if the test results will make a difference in how you manage the patient.</i></p>
                                <div style="text-align:center;">
                                        <v-btn @click="toggleRecommendationContainerDisplay()">&lt; Back to Recommendation & Summary</v-btn>
                                </div>
                        </div>
                </div>
            </template>
        </div>
    </div> 
</div>  
</template>

<script>
import { createMachine, actions, interpret, assign, cond } from 'xstate';
export default {
        props: {
                language: {
                        type: String,
                        require: true
                }
        },
        name: "thrombophiliaAlgorithm",
        created: function(){
                let baseContext = this.baseContext
		this.machine = createMachine({
			id: 'app',
			initial: 'VTE_OCCURANCE',
			context: JSON.parse(JSON.stringify(baseContext)),
			states: {
				VTE_OCCURANCE: {
					on: {
						YES: 'SITE',
						NO: 'RECOMMENDATION',
                                        },
                                        exit: [ (context, event) => context.vte_occurance.value = event.type ],
				},
				SITE: {
					on: {
                                                USUAL: 'VTE_STATE',
                                                UNUSUAL: 'VTE_STATE'
                                        },
                                        exit: [ 
						(context, event) => {
                                                context.site.full_description = event.type==='USUAL' ? {fr_ca:'Site habituel : thrombose veineuse profonde (TVP) du bras ou de la jambe, embolie pulmonaire (EP)', en_ca:'Usual site: arm or leg deep vein thrombosis (DVT), pulmonary embolism (PE)'}
                                                  : {fr_ca:'Site inhabituel : intracrânien, abdominal, artériel et veineux', en_ca: 'Unusual site: intracranial, abdominal, both arterial and venous'};
                                                context.site.value = event.type;
						}
					],
				},
                                VTE_STATE: {
					on: {
                                                PROVOKED: 'RISK_FACTORS',
                                                UNPROVOKED: 'RECOMMENDATION'
                                        },
                                        exit: [ (context, event) => context.vte_state.value = event.type ],
				},
				RISK_FACTORS: {
					on: {
                                                STRONG: 'RECOMMENDATION',
                                                WEAK: 'RECOMMENDATION'
                                        },
                                        exit: [ 
						(context, event) => {
                                                context.risk_factor.full_description = event.type==='STRONG' ?
                                                    {fr_ca: 'Facteurs de risque importants : cancer, chirurgie, traumatisme, immobilisation, maladie grave, cathéter central, grossesse, post-partum', en_ca:'Strong risk factors - cancer, surgery, trauma, immobilization, major medical illness, central line, pregnancy, post-partum'}
                                                  : context.site.value==='USUAL' ?
                                                    {fr_ca:'Facteur de risque faible – hormones (par exemple OCT, THS), voyage de plus de 5 heures, obésité, blessure mineure (chute), plâtre sous le genou ou arthroscopie', en_ca:'Weak Risk Factor – hormones (e.g. OCT, HRT), travel longer than 5 hours, obesity, minor injury (fall), below knee cast or arthroscopy'}
                                                  : {fr_ca:'Facteur de risque faible - hormones (par exemple OCT, THS), grossesse', en_ca:'Weak Risk Factor - hormones (e.g. OCT, HRT), pregnancy'};
                                                context.risk_factor.value = event.type 
						}
					],                                    
				},
                                RECOMMENDATION: {}                              
                        }
		});
                this.initialize();
                window.thrombophiliaAlgorithm_onreset = this.reset;
        },
        data: function(){
                return {
                        machine: null,
                        form: {
                                VTE_OCCURANCE: null,
                                SITE: null,
                                VTE_STATE: null,
                                RISK_FACTORS: null
                        },
                        state: null,
                        context: null,
                        service: null,
                        done: null,
                        displayPatientSummary: false,
                        displayRecommendationContainer: true,
                        debug: false,
                        pdfDownload: {
                            generatingPDF: false,
                            pdfLink: {},
                        },                            
                }
        },
        methods: {
                initialize: function(){
                        let self = this;
                        let machine = this.machine
                        let baseContext = this.baseContext
                        self.state = null;
                        self.service = null;
                        self.displayPatientSummary = true;
                        self.displayRecommendationContainer = true;
                        var service = interpret(machine).onTransition(state => {
                                if (state.event.type=='xstate.init'){
                                        state.context = JSON.parse(JSON.stringify(baseContext));
                                }
                                
                                self.state = state.value;
                                self.context = state.context;
                                self.done = state.nextEvents.length == 0					
                        });
                        this.service = service;	
                        for(let i in this.form){
                                this.form[i] = null
                        }
                        service.start();
                },
                reset: function(){
                        let self = this;
                        self.service.stop();
                        self.initialize();
                },
                togglePatientSummaryDisplay: function(){
                        this.displayPatientSummary = !this.displayPatientSummary;
                },
                toggleRecommendationContainerDisplay: function(){
                        this.displayRecommendationContainer = !this.displayRecommendationContainer
                },
                getPDF: function(nodeId=null){
                    if (nodeId===null){
                        return
                    }
                    let self = this
                    self.pdfDownload.generatingPDF = true
                    // self.linkImages()

                    setTimeout(function(){
                        let html = document.getElementById(nodeId).getInnerHTML()
                        self.sendRequestMakePDF('post', self.makePDF_API+'/pdf/make/tctools',{
                            html
                        }).then(function(response){
                            self.pdfDownload.generatingPDF = false
                            console.log(response)
                            if (response.status===200){
                                self.pdfDownload.pdfLink = response.data
                                window.open(self.pdfDownload.pdfLink.url,'Summary')
                            }
                        })
                    },1000)
                },
                downloadPDF: function( node, excludeIdList ) {
                        return new Promise((resolve, reject) => {
                                const $contentToOutput = $(node);
                                var excludeIdParentList = [];
                                for(let i=0; i<excludeIdList.length; i++){
                                    let $parent = $contentToOutput.find(excludeIdList[i]).parent();
                                    excludeIdParentList.push({
                                            parentElem: $parent,
                                            excludedElem: $parent.find(excludeIdList[i]).detach()
                                    });
                                }
                                
                                const position = $(node).offset();
                                const contentToOutput = $contentToOutput[0];
                                html2canvas(contentToOutput, { scale: 2, y: position.top }).then(canvas => {
                                if (canvas){
                                        const imgData = canvas.toDataURL("image/jpeg", 0.95);
                                        for(let i=0; i<excludeIdParentList.length; i++){
                                            excludeIdParentList[i]['excludedElem'].appendTo( excludeIdParentList[i]['parentElem'] );
                                        }
                                
                                        const doc = position.top > 200 ? new jsPDF("p", "mm", "a4") : new jsPDF("landscape", "mm");
                                        const imgProps= doc.getImageProperties(imgData);
                                        const pdfWidth = doc.internal.pageSize.getWidth();
                                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                                        doc.addImage(imgData, "JPEG", 30, 30, 148, pdfHeight*0.7);
                                        doc.save("tctool_download.pdf",{returnPromise: true});
                                        resolve(canvas);
                                }
                                else {
                                        for(let i=0; i<excludeIdParentList.length; i++){
                                            excludeIdParentList[i]['excludedElem'].appendTo( excludeIdParentList[i]['parentElem'] );
                                        }
                                        reject( new Error("PDF canvas failure"));
                                }
                                });
                        });
                },

            download_PDF(node, excludeIdList) {
                return new Promise((resolve, reject)=> {
                    if(screen.width < 1024) {
                        document.getElementById("viewport").setAttribute("content", "width=1200px");
                    }
                    const $contentToOutput = $(node)
                    let excludeIdParentList = [];
                    for(let i=0; i<excludeIdList.length; i++){
                        let $parent = $contentToOutput.find(excludeIdList[i]).parent();
                        excludeIdParentList.push({
                                parentElem: $parent,
                                excludedElem: $parent.find(excludeIdList[i]).detach()
                        });
                    }    
                    const contentToOutput = $contentToOutput[0];

                    let html2canvasOptions = {
                        allowTaint: true,
                        removeContainer: true,
                        backgroundColor: null,
                        imageTimeout: 15000,
                        logging: true,
                        scale: 2,
                        useCORS: true
                    };

                    html2canvas(contentToOutput, html2canvasOptions).then(canvas => {
                        if (canvas){
                            // Some necessary setting options
                            const contentDataURL = canvas.toDataURL('image/png', 0.95)
                            for(let i=0; i<excludeIdParentList.length; i++){
                              excludeIdParentList[i]['excludedElem'].appendTo( excludeIdParentList[i]['parentElem'] );
                            }
                            const imgWidth = 210;
                            const pageHeight = 295;
                            const imgHeight = canvas.height * imgWidth / canvas.width;
                            let heightLeft = imgHeight;

                            let pdf = new jsPDF('p', 'mm', 'a4', true); // A4 size page of PDF
                            let position = 0;

                            const imgProps= pdf.getImageProperties(contentDataURL);
                            const pdfWidth = pdf.internal.pageSize.getWidth();
                            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                            pdf.addImage(contentDataURL, "PNG", 30, 30, imgWidth*0.7, imgHeight*0.7, undefined, 'FAST');
                            heightLeft -= pageHeight;

                            /* handles multipage rendering */
                            while (heightLeft >= 0) {
                              position = heightLeft - imgHeight
                              pdf.addPage()
                              pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, undefined,'FAST')
                              heightLeft -= pageHeight
                            }

                            const link = pdf.output("bloburl"); 
                            setTimeout(() => { window.open(link, '_blank'); })

                            /*
                            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) && !window.MSStream;
                            if (isMobile) { 
                              const link = pdf.output("bloburl"); 
                              setTimeout(() => { window.open(link, '_top'); })
                              //setTimeout(() => { window.open(link, '_blank'); })
                            } 
                            else { 
                              pdf.save("tctool_download.pdf",{returnPromise: true}); 
                            }
                            */

                            if(screen.width < 1024) {
                              document.getElementById("viewport").setAttribute("content", "width=device-width, initial-scale=1");
                            }
                            resolve(canvas)
                        }
                        else {
                            for(let i=0; i<excludeIdParentList.length; i++){
                                excludeIdParentList[i]['excludedElem'].appendTo( excludeIdParentList[i]['parentElem'] )
                            }
                            reject( new Error("PDF canvas failure") )
                        }
                    });    

                })
            }
        },
        computed: {
            baseContext: function(){
                    return {
                            vte_occurance: {summary_identifier: "Is this the patient’s first VTE?", value: null, value_locale: {YES:{fr_ca:"Oui", en_ca:"Yes"}, NO:{fr_ca:"Non", en_ca:"No"}}},
                            site: {summary_identifier: "Where is the VTE?", value: null, full_description: null},
                            vte_state: {summary_identifier: "Is the VTE provoked or unprovoked?", value: null, value_locale: {UNPROVOKED:{fr_ca:"Oui", en_ca:"Non provoquée"}, PROVOKED:{fr_ca:"Provoquée ", en_ca:"PROVOKED"}}},
                            risk_factor: {summary_identifier: "Does this patient have strong or weak risk factors for VTE?", value: null, full_description: null},
                    }
            },
            makePDF_API: function(){ 
                return process.env.VUE_APP_PDF_MAKE_API
            },
        }
}
</script>

<style scoped>
#calcWrapper a {
    color: #1976d2;
}

a.toggleExamplesWrapper, a span.toggleExamplesWrapper {
    border-radius: 12.5px;
    background: #1d2a75;
    color: white;
    text-decoration: none;
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-top: 1px;
    margin-left: 5px;
    margin-right: 3px;    
    text-align: center;
    padding-right: 2px;
    font-size: 20px;
    font-weight: bolder;
    font-family: 'Material Design Icons';
}

#info-mpn p,
#info-aps p,
#info-pnh p,
#info-hit p
{
    margin-bottom: 2px;
}

@media screen and (max-width: 460px) {
    a.toggleExamplesWrapper {
            margin-top: 2px;
    }
}


::v-deep .v-input--selection-controls .v-radio>.v-label {
    display: inline-block;
}

@media screen and (max-width: 768px) {
::v-deep .v-input--selection-controls .v-radio>.v-label {
    font-size: 1em;
}
}
</style>