<template>
<div class="d-flex">
    
    <!-- debug panel -->
    <div style="position: fixed; top: 0; right: 0; z-index: 200" v-if="debug===true">
        <v-card>
            <v-card-title>PreopAlgo route options</v-card-title>
            <v-card-text>QID-1:  <span class="red--text font-weight-bold">{{getManuscriptOption('q1')}}</span></v-card-text>
            <v-card-text>QID-2:  <span class="red--text font-weight-bold">{{getManuscriptOption('q2')}}</span></v-card-text>
            <v-card-text>QID-3:  <span class="red--text font-weight-bold">{{getManuscriptOption('q3')}}</span></v-card-text>
            <v-card-text>QID-4:  <span class="red--text font-weight-bold">{{preop_selected.q4}}</span></v-card-text>
            <v-card-text>QID-5:  <span class="red--text font-weight-bold">{{preop_selected.q5}}</span></v-card-text>
            <v-card-text>QID-6:  <span class="red--text font-weight-bold">{{preop_selected.q6}}</span></v-card-text>
            <v-card-text>QID-7:  <span class="red--text font-weight-bold">{{preop_selected.q7}}</span></v-card-text>
            <v-card-text>QID-8:  <span class="red--text font-weight-bold">{{preop_selected.q8}}</span></v-card-text>
            <v-card-text>QID-9:  <span class="red--text font-weight-bold">{{preop_selected.q9}}</span></v-card-text>
            <v-card-text>QID-10:  <span class="red--text font-weight-bold">{{preop_selected.q10}}</span></v-card-text>
            <v-card-text>QID-11:  <span class="red--text font-weight-bold">{{preop_selected.q11}}</span></v-card-text>
            <v-card-text>QID-12:  <span class="red--text font-weight-bold">{{preop_selected.q12}}</span></v-card-text>
        </v-card>
    </div>
    
	<form id="calcWrapper" onsubmit="return false;" ref="calcWrapper">
		<div id="calcItems">
			<vueCalculators :language="language"/>

			<!-- Antothrombotic Algo -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription antithromboticAlgorithm">
                                    <h2>Posologie des anticoagulants en cas de fibrillation auriculaire</h2>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label class="withNumber">Âge (ans) <span class="validationMessage" style='color:red;'>Requis</span>
                                            <input value="" :required="activeCalc==='antithromboticAlgorithm'" type="number" onchange="calc.antithromboticAlgorithm('fr-ca')" onkeyup="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgAge">
                                    </label>

                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label class="withNumber">Poids (kg) <span class="validationMessage" style='color:red;'>Requis</span>
                                            <input value="" :required="activeCalc==='antithromboticAlgorithm'" type="number" onchange="calc.antithromboticAlgorithm('fr-ca')" onkeyup="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgWeight">
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label class="withNumber">Créatinine sérique <span class="calcUnit">(µmol/L)</span> <span class="validationMessage" style='color:red;'>Requis</span>
                                            <input value="" :required="activeCalc==='antithromboticAlgorithm'" type="number" onchange="calc.antithromboticAlgorithm('fr-ca')" onkeyup="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgSerumCreatinine">
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgHeart">
                                            Antécédents d’insuffisance cardiaque congestive
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgHyper">
                                            Antécédents d’hypertension artérielle
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgDiabetes">
                                            Antécédents de diabète
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgStroke">
                                            Antécédents d’AVC ou d’AIT
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgVascular">
                                            Antécédents de maladie macrovasculaire (coronaire, aortique ou périphérique)
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgIndicateWarfarin">
                                            Le patient présente une autre indication du traitement par la warfarine (p. ex., valve cardiaque mécanique, thrombus ventriculaire gauche, valvulopathie rhumatismale)
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgFemale">
                                            Sexe féminin
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm('fr-ca')" name="antithromboAlgConcomitantInhibitors">
                                            Utilisation concomitante d’inhibiteurs de la P-gp (sauf l’amiodarone et le vérapamil)
                                            <a href="javascript:calcToggleExamples('#concomitantInhibitorsExamples');" class="toggleExamplesWrapper">?</a>
                                            <div id="concomitantInhibitorsExamples" class="examples" style='font-style:italic;color:#666;display: none;'>
                                                    Y compris, entre autres :<br>
                                                    Carvédilol, clarithromycine, cyclosporine, érythromycine, itraconazole, kétoconazole, dronédarone, lapatinib, lopinavir, propafénone, quinidine, ranolazine, ritonavir, saquinavir, télaprévir, tipranavir. En cas d’utilisation concomitante de vérapamil ou d’amiodarone, la réduction de la dose d’edoxaban n’est pas requise.
                                            </div>
                                    </label>
                            </div>                            
                        </template>
                        <template v-else>
                            <div class="calcDescription antithromboticAlgorithm">
                                    <h2>Anticoagulant Dosing In Atrial Fibrillation</h2>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label class="withNumber">Age (years) <span class="validationMessage" style='color:red;'>required</span>
                                            <input value="" :required="activeCalc==='antithromboticAlgorithm'" type="number" onchange="calc.antithromboticAlgorithm()" onkeyup="calc.antithromboticAlgorithm()" name="antithromboAlgAge">
                                    </label>

                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label class="withNumber">Weight (kg) <span class="validationMessage" style='color:red;'>required</span>
                                            <input value="" :required="activeCalc==='antithromboticAlgorithm'" type="number" onchange="calc.antithromboticAlgorithm()" onkeyup="calc.antithromboticAlgorithm()" name="antithromboAlgWeight">
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label class="withNumber">Serum Creatinine <span class="calcUnit">(µmol/L)</span> <span class="validationMessage" style='color:red;'>required</span>
                                            <input value="" :required="activeCalc==='antithromboticAlgorithm'" type="number" onchange="calc.antithromboticAlgorithm()" onkeyup="calc.antithromboticAlgorithm()" name="antithromboAlgSerumCreatinine">
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgHeart">
                                            Congestive Heart Failure History
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgHyper">
                                            Hypertension History
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgDiabetes">
                                            Diabetes Mellitus History
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgStroke">
                                            Previous stroke or TIA
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgVascular">
                                            History of macrovascular disease (coronary, aortic or peripheral)
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgIndicateWarfarin">
                                            Patient has another indication for warfarin therapy (for example, mechanical heart valve, LV thrombus, rheumatic valvular heart disease)
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgFemale">
                                            Female Patient
                                    </label>
                            </div>
                            <div class="calcItem antithromboticAlgorithm">
                                    <label>
                                            <input type="checkbox" onclick="calc.antithromboticAlgorithm()" name="antithromboAlgConcomitantInhibitors">
                                            Concomitant use of P-gp inhibitors (except amiodarone and verapamil)
                                            <a href="javascript:calcToggleExamples('#concomitantInhibitorsExamples');" class="toggleExamplesWrapper">?</a>
                                            <div id="concomitantInhibitorsExamples" class="examples" style='font-style:italic;color:#666;display: none;'>
                                                    Including but not limited to:<br>
                                                    Carvedilol, clarithromycin, cyclosporin, erythromycin,itraconazole, ketoconazole, dronedarone, lapatinib, lopinavir, propafenone, quinidine, ranolazine, ritonavir, saquinavir, telaprevir, tipranavir. Dose reduction of edoxaban is not required with concomitant use of verapamil or amiodarone
                                            </div>
                                    </label>
                            </div>
                        </template>



                        

			<!--  Preoperative Anticoagulant Mangement Algo  -->
			<div class="calcDescription perioperativeAnticoagulantAlgorithm">
				<h2>Perioperative Anticoagulant Management Algorithm</h2>
			</div>
			<div class="perioperativeAnticoagulantAlgorithm preopAlgo_step01 calcItem groupItem step01">
                            <span class="error--text" v-show="debug===true">QID-1</span>
                            <h3>Please select the type of surgery: </h3>
				<div>
					<label>
						<input v-model="preop_selected.q1" type="radio" name="preopAlgoSurgeryType" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step01', '.preopAlgo_step1');" value="elective"> Elective
					</label>
				</div>
				<div>
					<label>
						<input v-model="preop_selected.q1" type="radio" name="preopAlgoSurgeryType" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step01', '.preopAlgo_step02');" value="emergency"> Emergency Surgery/procedure &lt;12 h (e.g. intracranial bleed, ruptured viscus, cardiac tamponade)

					</label>
				</div>
				<div>
					<label>
						<input v-model="preop_selected.q1" type="radio" name="preopAlgoSurgeryType" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step01', '.preopAlgo_step02');" value="urgent"> Urgent surgery/procedure 12-24 h (e.g. hip fracture repair, acute cholecystitis)
					</label>
				</div>
			</div>
			<div class="preopAlgo_step1 calcItem groupItem step1">
                            <span class="error--text" v-show="debug===true">QID-2</span>
				<h3>Procedural Bleeding Risk</h3>
				<div>
					<label>
						<input v-model="preop_selected.q2" type="radio" name="preopAlgoBleedRisk" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step1', '.preopAlgo_step2');" value="minor non-dental"> Low (minor non-dental procedure)
					</label>
					<a href="javascript:calcToggleExamples('#periopExamples1');" class="toggleExamplesWrapper">?</a>
					<ul id="periopExamples1" class="examples">
						<li>Cataract surgery</li>
						<li>Dermatologic procedures (e.g. biopsy)</li>
						<li>Gastroscopy or colonoscopy without biopsies</li>
						<li>Coronary angiography</li>
						<li>Permanent pacemaker insertion or internal defibrillator placement (if bridging anticoagulation is not used)</li>
						<li>Selected procedures (e.g. thoracentesis, paracentesis, arthrocentesis)</li>
					</ul>
				</div>
				<div>
					<label>
						<input v-model="preop_selected.q2" type="radio" name="preopAlgoBleedRisk" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step1', '.preopAlgo_step2');" value="minor dental"> Low (minor dental procedure)
					</label>
					<a href="javascript:calcToggleExamples('#periopExamples2');" class="toggleExamplesWrapper">?</a>
					<ul id="periopExamples2" class="examples">
						<li>Dental extractions (1 or 2 teeth)</li>
						<li>Endodontic (root canal) procedure</li>
						<li>Subgingival scaling or other cleaning</li>
					</ul>
				</div>
				<div>
					<label>
						<input v-model="preop_selected.q2" type="radio" name="preopAlgoBleedRisk" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step1', '.preopAlgo_step2');" value="moderate"> Moderate
					</label>
					<a href="javascript:calcToggleExamples('#periopExamples3');" class="toggleExamplesWrapper">?</a>
					<ul id="periopExamples3" class="examples">
						<li>Abdominal surgery (e.g. cholecystectomy, hernia repair, colon resection)</li>
						<li>Other general surgery (e.g. breast)</li>
						<li>Other intrathoracic surgery</li>
						<li>Other orthopedic surgery</li>
						<li>Other vascular surgery</li>
						<li>Non-cataract ophthalmologic surgery</li>
						<li>Gastroscopy or colonoscopy with biopsies</li>
						<li>Selected procedures (e.g. bone marrow biopsy, lymph node biopsy)</li>
						<li>Complex dental procedure (e.g. multiple tooth extractions)</li>
					</ul>
				</div>
				<div>
					<label>
                                            <input v-model="preop_selected.q2" type="radio" name="preopAlgoBleedRisk" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step1', '.preopAlgo_step2');" value="high"> High
					</label>
					<a href="javascript:calcToggleExamples('#periopExamples4');" class="toggleExamplesWrapper">?</a>
					<ul id="periopExamples4" class="examples">
						<li>Any surgery or procedure with neuraxial &nbsp;(spinal or epidural) anesthesia</li>
						<li>Neurosurgery (intracranial or spinal)</li>
						<li>Cardiac surgery (e.g. CABG, heart valve replacement)</li>
						<li>Major vascular surgery (e.g. aortic aneurysm repair, aortofemoral bypass)</li>
						<li>Major orthopedic surgery (e.g. hip/knee joint replacement surgery)</li>
						<li>Lung resection surgery</li>
						<li>Urological surgery (e.g. prostatectomy, bladder tumour resection)</li>
						<li>Extensive cancer surgery (e.g. pancreas, liver)</li>
						<li>Intestinal anastomosis surgery</li>
						<li>Reconstructive plastic surgery</li>
						<li>Selected procedures (e.g. kidney biopsy, prostate biopsy, cervical cone biopsy, pericardiocentesis, colonic polypectomy)</li>

					</ul>
				</div>
			</div>
			<div class="calcItem preopAlgo_step1" style="text-align:center;">
				<button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step1', '.preopAlgo_step01')" class="calcButton">&lt; Back</button>
			</div>

			<div class="preopAlgo_step2 calcItem groupItem">
                            <span class="error--text" v-show="debug===true">QID-3</span>
				<h3>Anticoagulant Used</h3>
				<label>
					<input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step3');" value="apixaban">
					Apixaban
				</label>
				<label>
					<input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step3');" value="dabigatran">
					Dabigatran
				</label>
				<label>
					<input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step3');" value="edoxaban">
					Edoxaban
				</label>
				<label>
					<input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step3');" value="rivaroxaban">
					Rivaroxaban
				</label>
				<label>
					<input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step3');" value="warfarin">
					Warfarin
				</label>
			</div>
			<div class="calcItem preopAlgo_step2" style="text-align:center;">
				<button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step1')" class="calcButton">&lt; Back</button>
			</div>
			<div class="preopAlgo_step02 calcItem groupItem">
                            <span class="error--text" v-show="debug===true">QID-3</span>
				<h3>Anticoagulant Used</h3>
				<label>
					<input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step02D');" value="apixaban">
					Apixaban
				</label>
				<label>
					<input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step02D');" value="dabigatran">
					Dabigatran
				</label>
				<label>
					<input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step02D');" value="edoxaban">
					Edoxaban
				</label>
				<label>
					<input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step02D');" value="rivaroxaban">
					Rivaroxaban
				</label>
				<label>
					<input v-model="preop_selected.q3" type="radio" name="preopAlgoDrug" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step02D');" value="warfarin">
					Warfarin
				</label>
			</div>
			<div class="calcItem preopAlgo_step02" style="text-align:center;">
				<button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step02', '.preopAlgo_step01')" class="calcButton">&lt; Back</button>
			</div>
                        
                        <!-- PreopAlog POPUP-1 -->    
			<div class="preopAlgo_step02N calcItem">
				<h3>Initial management</h3>
			</div>
			<div class="calcItem preopAlgo_step02N">
				<ul>
					<li>Refer for procedural/surgical intervention</li>
					<li>Check hemoglobin concentration and platelet count to help guide transfusion</li>
					<li>Transfusion therapy
						<ul>
							<li>RBC transfusion for symptomatic anemia</li>
							<li>Platelets for thrombocytopenia (platelets less than 50 x 10<sup>9</sup>/L) or patients on antiplatelet agents</li>
						</ul>
					</li>
					<li>Discontinue anticoagulant </li>
				</ul>
			</div>
			<div class="calcItem preopAlgo_step02N" style="text-align:center;">
				<button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step02N', '.preopAlgo_step02')" class="calcButton">&lt; Back</button>
				<button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step02N', '.preopAlgo_step03b');" class="calcButton">Next &gt;</button>
			</div>

			<div class="preopAlgo_step02W calcItem">
				<h3>Initial management</h3>
			</div>
			<div class="calcItem preopAlgo_step02W">
				<ul>
					<li>Refer for procedural/surgical intervention</li>
					<li>Check hemoglobin concentration and platelet count to help guide transfusion</li>
					<li>Transfusion therapy
						<ul>
							<li>RBC transfusion for symptomatic anemia</li>
							<li>Platelet transfusion for thrombocytopenia (platelets less than 50 x 10<sup>9</sup>/L) or patients on antiplatelet agents</li>
						</ul>
					</li>
					<li>Discontinue anticoagulant </li>
				</ul>
			</div>
			<div class="calcItem preopAlgo_step02W" style="text-align:center;">
				<button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step02W', '.preopAlgo_step02')" class="calcButton">&lt; Back</button>
				<button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step02W', '.preopAlgo_step03bw')" class="calcButton">Next &gt;</button>
			</div>

                        
                        <!--preopAlgo QID-4 -->
			<div class="preopAlgo_step3b preopAlgo_step03b calcItem">
                            <span class="error--text" v-show="debug===true">QID-4</span>
				<h3>Creatinine Clearance</h3>
			</div>
			<div class="preopAlgo_step03bw calcItem">
                            <span class="error--text" v-show="debug===true">QID-11</span>
				<h3>Weight</h3>
			</div>
			<div class="calcItem preopAlgo_step3b preopAlgo_step03b">
				<label>
					<input v-model="preop_selected.q4.female" type="checkbox" name="preopAlgoCrClisFemale" onclick="calc.preopAnticoagulantAlgorithmCrCl()">
					Female patient
				</label>
			</div>
			<div class="calcItem preopAlgo_step3b preopAlgo_step03b">
				<label class="withNumber">
					Age (years)
					<input v-model="preop_selected.q4.age" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" onchange="calc.preopAnticoagulantAlgorithmCrCl()" onkeyup="calc.preopAnticoagulantAlgorithmCrCl()" name="preopAlgoCrClAge">
					<div class="validationMessage">!</div>
				</label>
			</div>
			<div class="calcItem preopAlgo_step03bw">
				<label class="withNumber">
					Weight (kg)
					<input v-model="preop_selected.q11.weight" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" onchange="" onkeyup="" name="preopAlgoWeight">
					<div class="validationMessage">!</div>
				</label>
			</div>
			<div class="calcItem preopAlgo_step3b preopAlgo_step03b">
				<label class="withNumber">
					Weight (kg)
					<input v-model="preop_selected.q4.weight" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" onchange="calc.preopAnticoagulantAlgorithmCrCl()" onkeyup="calc.preopAnticoagulantAlgorithmCrCl()" name="preopAlgoCrClweight">
					<div class="validationMessage">!</div>
				</label>
			</div>
			<div class="calcItem preopAlgo_step3b preopAlgo_step03b">
				<label class="withNumber">
					Serum Creatinine <span class="calcUnit">(µmol/L)</span>
					<input  v-model="preop_selected.q4.serum_creatinine" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" onchange="calc.preopAnticoagulantAlgorithmCrCl()" onkeyup="calc.preopAnticoagulantAlgorithmCrCl()" name="preopAlgoCrClserumCreatinine">
					<div class="validationMessage">!</div>
				</label>
			</div>
			<div class="calcItem preopAlgo_step3b" style="text-align:center;">
				<button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step3b', '.preopAlgo_step2')" class="calcButton">&lt; Back</button>
				<button class="calcButton" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step3b', '.preopAlgo_step4');">Next &gt;</button>
			</div>
			<div class="calcItem preopAlgo_step03b" style="text-align:center;">
				<button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step03b', '.preopAlgo_step02BackD')" class="calcButton">&lt; Back</button>
				<button class="calcButton" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step03b', '.preopAlgo_step03bD');">Next &gt;</button>
			</div>
			<div class="calcItem preopAlgo_step03bw" style="text-align:center;">
				<button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step03bw', '.preopAlgo_step02BackD')" class="calcButton">&lt; Back</button>
				<button class="calcButton" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step03bw', '.preopAlgo_step03bD');">Next &gt;</button>
			</div>

			<div class="calcItem preopAlgo_step04">
                            <span class="error--text" v-show="debug===true">QID-12</span>
				<label class="withNumber">
					INR
					<input v-model="preop_selected.q12" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" name="preopAlgoINR">
					<div class="validationMessage">!</div>
				</label>
			</div>
			<div class="calcItem preopAlgo_step04" style="text-align:center;">
				<button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step04', '.preopAlgo_step02W')" class="calcButton">&lt; Back</button>
				<button class="calcButton" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step04', '.preopAlgo_step04D');">Next &gt;</button>
			</div>
				<div class="calcItem preopAlgo_step04D">
					<div class="calcItem preopAlgo_step04P3">
						<strong>Recommendations</strong>
                                                <p>Repeat INR before surgery</p>
						<ul>
							<li>If INR &le; 1.5 &#8594; no reversal needed</li>
							<li>Proceed to Surgery/Procedure</li>
							<li>Consider PCC for very high bleeding risk surgeries/proceedures</li>
						</ul>
					</div>
					<div class="calcItem preopAlgo_step04P4">
						<strong>Recommendations</strong>
						<br>Give vitamin K, 10 mg IV

						<br><br>
						Repeat INR  before surgery
						<ul>
							<li>If INR &le; 1.5 &#8594; no reversal needed, Proceed to Surgery/Procedure</li>
							<li>If INR >1.5, consider PCC</li>
						</ul>
					</div>
					<div class="calcItem preopAlgo_step04P2">
						<strong>Recommendations</strong>
						<ul>
							<li>If INR &le; 1.5 &#8594; no reversal needed</li>
							<li>Proceed to Surgery/Procedure</li>
						</ul>
					</div>
					<div class="calcItem preopAlgo_step04P5">
						<strong>Recommendations</strong>
						<p><strong>Give vitamin K (10 mg in 50 mL normal saline IV STAT) and <span class="PCC_VAR"></span> units of PCC (DO NOT REPEAT IF ALREADY GIVEN)</strong> <a href='#' @click.prevent="display_modal.PCCDosingWrapper=!display_modal.PCCDosingWrapper" style="color: #1976d2;">PCC Dosing and Administration</a>:</p>
						<ul>
							<li>Infuse PCC intravenously:
								<ul>
									<li>OCTAPLEX<sup>&reg;</sup> 1 mL/min followed by maximum 2-3 mL/min (180 mL/hr)</li>
									<li>BERIPLEX<sup>&reg;</sup> IV at 1 mL/min followed by maximum 8 mL/min (480 mL/hr)</li>
								</ul>
							</li>
							<li>PCC contraindicated in heparin-induced thrombocytopenia</li>
							<li>Inform patients/families regarding small (&lt;2%) thrombotic risk of PCC (e.g. stroke MI, DVT, PE), but consequences of uncontrolled surgical bleeding likely exceed this risk
							<ul>
								<li>If PCC is unavailable or contraindicated transfuse fresh frozen plasma 10-15 mL/kg (approx. 3-4 units for adults)</li>
							</ul></li>
							<li>If INR not reported or weight unknown and cannot delay PCC administration give PCC 2000 units IV and vitamin K 10 mg IV STAT</li>
						</ul>
						<strong>Repeat INR 15 min after PCC infusion</strong>
						<br>
						If INR &le; 1.5: Warfarin reversed<br>
						If INR > 1.5: Consider additional dose of PCC, consider other causes elevated INR (e.g. DIC, dilutional coagulopathy, liver failure)
					</div>
				</div>


			<div class="calcItem preopAlgo_step04Ea" style="text-align:center;">
				<button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step04Ea', '.preopAlgo_step04')" class="calcButton">&lt; Back</button>
			</div>

                        
                        <!--preopAlgo QID-10 -->
			<div class="preopAlgo_step05 calcItem">
                            <span class="error--text" v-show="debug===true">QID-10</span>
				<h3>Lab testing for Residual DOAC Effect</h3>
			</div>
			<div class="calcItem preopAlgo_step05">
				<label class="withNumber">
					ng/mL <span class="preopAlgoNOACEffectLabel"></span>
					<input  v-model="preop_selected.q10.doac_effect_level" :required="activeCalc==='perioperativeAnticoagulantAlgorithm'" type="number" name="preopAlgoNOACEffect" onclick="calc.preopAnticoagulantAlgorithmNOAC();" onkeyup="calc.preopAnticoagulantAlgorithmNOAC();">
					<div class="validationMessage">!</div>
				</label>
			</div>
			<div class="calcItem preopAlgo_step05">
				<label>
					<input v-model="preop_selected.q10.doac_effect_unknown" @click="syncDoacEffectLevel" type="checkbox" name="preopAlgoNOACEffectNotAvailable" onclick="calc.preopAnticoagulantAlgorithmNOAC()">
					Not available or DOAC effect not known
				</label>
			</div>

			<div class="calcItem preopAlgo_step05" style="text-align:center;">
				<!-- <button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step2', '.preopAlgo_step02')" class="calcButton">&lt; Back</button> -->
				<button class="calcButton" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step05', '.preopAlgo_step05D');">Next &gt;</button>
			</div>
				<div class="calcItem preopAlgo_step05D">
					<div class="calcItem preopAlgo_step05P8">
						<strong>Recommendations</strong>
						<ul>
							<li>Proceed to Surgery/Procedure</li>
						</ul>
					</div>
					<div class="calcItem preopAlgo_step05P7">
						<strong>Recommendations</strong>
						<ul>
							<li>Reassess anticoagulant effect just before surgery</li>
						</ul>
					</div>
					<div class="calcItem preopAlgo_step05P9d">
                                            <p>1. Determine plasma dabigatran concentration using <v-btn dense text link class="px-1" @click="display_modal.DabigatranTable=!display_modal.DabigatranTable" style="cursor: pointer;"><u>dilute thrombin time/Hemoclot</u><sup>&reg;</sup></v-btn>assay</p>

<ul>
	<li>If dabigatran level &lt;30 &ndash; 50 ng/mL: no reversal</li>
	<li>If dTT unavailable use dosing regimen, timing of last dose and creatinine clearance to determine presence of drug</li>
</ul>
<div class="d-flex">   
    <button href='#' @click="display_modal.HalfLifeDTable=!display_modal.HalfLifeDTable" style="padding: 5px;border: 1px solid black;border-radius: 10px; background: cornflowerblue; margin-left: 2px; margin-right: 2px;">View Half-life of dabigatran</button>
    <button href='#' @click="display_modal.DabigatranTable=!display_modal.DabigatranTable" style="padding: 5px;border: 1px solid black;border-radius: 10px; background: cornflowerblue;margin-left: 2px; margin-right: 2px;">View interpretation of coagulation tests</button>
</div>
<p>2. If dabigatran level &ge; 30-50 ng/mL, OR dTT unavailable and clinically significant dabigatran levels suspected:</p>

<ul>
	<li>Idarucizumab administered as two 50-mL bolus infusions containing 2.5 g each of idarucizumab (total 5 g) no more than 15 minutes apart</li>
</ul>

<p>3. If idarucizumab unavailable: alternative therapies</p>

<ul>
	<li>Prothrombin complex concentrate (PCC) (50 units/kg, max 3000 units)
	<ul>
		<li>OCTAPLEX&reg; IV at 1 mL/min followed by maximum 2-3 mL/min (180 mL/hr)</li>
		<li>BERIPLEX&reg; IV at 1 mL followed by maximum 8 mL/min (480 mL/hr)</li>
	</ul>
	</li>
	<li>PCC contraindicated in heparin-induced thrombocytopenia</li>
	<li>FEIBA&reg; 2000 units (50 units/kg , max 2000 units)</li>
	<li>Inform patients/families regarding small thrombotic risk of PCC and FEIBA&reg; (e.g. stroke MI, DVT, PE), but consequences of delaying the surgery/procedure likely exceed this risk.</li>
</ul>

<p>4. Adjunctive therapy</p>

<ul>
	<li>Hemodialysis (~65% removal after 4 hrs) if feasible</li>
</ul>

<p>&nbsp;</p>

<p><strong>Disclaimer:</strong><strong> </strong><em>These general recommendations do not replace clinical judgement. Physicians must consider relative risks and benefits in each patient in applying these recommendations.<br />
<br />
Recommended assays and thresholds for clinically relevant plasma NOAC concentrations are estimates based on available evidence that require further study/validation. The threshold may be higher or lower depending on the assay. </em></p>
					</div>
					<div class="calcItem preopAlgo_step05P9d">
                                            <ol>
                                                <li>Determine plasma dabigatran concentration using <a href='#' @click.prevent="display_modal.DabigatranTable=!display_modal.DabigatranTable" style="color: #1976d2;">dilute thrombin time (dTT)/Hemoclot<sup>&reg;</sup> assay</a>. If dTT unavailable use dosing regimen, timing of last dose and creatinine clearance to determine presence of drug. <a href='#' @click.prevent="display_modal.HalfLifeDTable=!display_modal.HalfLifeDTable" style="color: #1976d2;">View Half-life of dabigatran</a>.</li>
                                                <li>Consider idarucizumab (specific dabigatran reversal agent)
                                                    <ul>
                                                        <li>2.5 grams IV infusion, repeat 2.5 grams IV infusion within 15 minutes  </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                If idarucizumab not available, consider pro-hemostatic therapy
                                                <!-- Consider pro-hemostatic therapy -->
                                                    <ul>
                                                        <li>Prothrombin complex concentrate (PCC) (50 units/kg, max 3000 units)
                                                            <ul>
                                                                <li>OCTAPLEX<sup>&reg;</sup> IV 1 mL/min followed by maximum 2-3 mL/min (180 mL/hr)</li>
                                                                <li>BERIPLEX<sup>&reg;</sup> IV at 1 mL followed by maximum 8 mL/min (480 mL/hr)</li>
                                                            </ul>
                                                        </li>
                                                        <li>PCC contraindicated in heparin-induced thrombocytopenia</li>
                                                        <li>FEIBA <span class="FEIBA_VAR"></span> units (50 units/kg , max 2000 units)</li>
                                                        <li>Inform patients/families regarding small thrombotic risk of PCC and FEIBA (e.g. stroke MI, DVT, PE)</li>
                                                    </ul>
                                                </li>
                                                <li>Adjunctive therapy
                                                    <ul>
                                                        <li>Hemodialysis (~65% removal after 4 hrs) if feasible</li>
                                                    </ul>
                                                </li>
                                            </ol>
					</div>
					<div class="calcItem preopAlgo_step05P9a">
                                            <ol>
                                                <li>Determine presence of apixaban using <a href='#' @click.prevent="display_modal.ApixabanTable=!display_modal.ApixabanTable" style="color: #1976d2;">apixaban-calibrated anti-Xa activity assay</a>. If apixaban-calibrated anti-Xa activity assay unavailable use dosing regimen, timing of last dose and creatinine clearance to determine presence of drug. <a href='#' @click.prevent="display_modal.HalfLifeATable=!display_modal.HalfLifeATable" style="color: #1976d2;">View Half-life of apixaban</a>.</li>
                                                <li>Consider pro-hemostatic therapy (no clinical evidence)
                                                    <ul>
                                                        <li>Prothrombin complex concentrate (PCC) (50 units/kg, max 3000 units)
                                                            <ul>
                                                                <li>OCTAPLEX<sup>&reg;</sup> IV 1 mL/min followed by maximum 2-3 mL/min (180 mL/hr)</li>
                                                                <li>BERIPLEX<sup>&reg;</sup> IV at 1 mL followed by maximum 8 mL/min (480 mL/hr)</li>
                                                            </ul>
                                                        </li>
                                                        <li>PCC contraindicated in heparin-induced thrombocytopenia</li>
                                                        <li>Inform patients/families regarding small thrombotic risk of PCC (e.g. stroke MI, DVT, PE)</li>
                                                    </ul>
                                                </li>
                                            </ol>
					</div>
					<div class="calcItem preopAlgo_step05P9r">
                                            <ol>
                                                <li>Determine presence of rivaroxaban using <a href='#' @click.prevent="display_modal.RivaroxabanTable=!display_modal.RivaroxabanTable" style="color: #1976d2;">rivaroxaban-calibrated anti-Xa activity assay</a>. If rivaroxaban-calibrated anti-Xa activity assay unavailable use dosing regimen, timing of last dose and creatinine clearance to determine presence of drug. <a href='#' @click.prevent="display_modal.HalfLifeRTable=!display_modal.HalfLifeRTable" style="color: #1976d2;">View Half-life of rivaroxaban</a>.</li>
                                                <li>Consider pro-hemostatic therapy (no clinical evidence)
                                                    <ul>
                                                        <li>Prothrombin complex concentrate (PCC) (50 units/kg, max 3000 units)
                                                            <ul>
                                                                <li>OCTAPLEX<sup>&reg;</sup> IV 1 mL/min followed by maximum 2-3 mL/min (180 mL/hr)</li>
                                                                <li>BERIPLEX<sup>&reg;</sup> IV at 1 mL followed by maximum 8 mL/min (480 mL/hr)</li>
                                                            </ul>
                                                        </li>
                                                        <li>PCC contraindicated in heparin-induced thrombocytopenia</li>
                                                        <li>Inform patients/families regarding small thrombotic risk of PCC (e.g. stroke MI, DVT, PE)</li>
                                                    </ul>
                                                </li>
                                            </ol>
					</div>
					<div class="calcItem preopAlgo_step05P9e">
                                            <ol>
                                                <li>Determine presence of edoxaban using <a href='#' @click.prevent="display_modal.EdoxabanTable=!display_modal.EdoxabanTable" style="color: #1976d2;">edoxaban-calibrated anti-Xa activity assay</a>. If edoxaban-calibrated anti-Xa activity assay unavailable use dosing regimen, timing of last dose and creatinine clearance to determine presence of drug. <a href='#' @click.prevent="display_modal.HalfLifeETable=!display_modal.HalfLifeETable" style="color: #1976d2;">View Half-life of edoxaban</a>.</li>
                                                <li>Consider pro-hemostatic therapy (no clinical evidence)
                                                    <ul>
                                                        <li>Prothrombin complex concentrate (PCC) (50 units/kg, max 3000 units)
                                                            <ul>
                                                                <li>OCTAPLEX<sup>&reg;</sup> IV 1 mL/min followed by maximum 2-3 mL/min (180 mL/hr)</li>
                                                                <li>BERIPLEX<sup>&reg;</sup> IV at 1 mL followed by maximum 8 mL/min (480 mL/hr)</li>
                                                            </ul>
                                                        </li>
                                                        <li>PCC contraindicated in heparin-induced thrombocytopenia</li>
                                                        <li>Inform patients/families regarding small thrombotic risk of PCC (e.g. stroke MI, DVT, PE)</li>
                                                    </ul>
                                                </li>
                                            </ol>
					</div>
				</div>
                        
                        <!--preopAlgo QID-5 / QID-6 -->
			<div class="preopAlgo_step4 calcItem groupItem">
                            <span class="error--text" v-show="debug===true">QID-5/6</span>
				<h3>Indication for Antithrombotic</h3>
				<label><input v-model="preop_selected.q5" type="radio" name="preopAlgo_IndicationAT" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step4', '.preopAlgo_IndicationAT_AF');" value="atrial fibrillation"> Atrial Fibrillation</label>
				<label><input v-model="preop_selected.q5" type="radio" name="preopAlgo_IndicationAT" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step4', '.preopAlgo_IndicationAT_DVT_PE');" value="DVT/PE"> DVT/PE</label>
				<label class="warfarinOnly"><input v-model="preop_selected.q6" type="radio" name="preopAlgo_IndicationAT" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_step4', '.preopAlgo_IndicationAT_Valve');" value="mechanical valve"> Mechanical Valve</label>
			</div>
			<div class="calcItem preopAlgo_step4" style="text-align:center;">
				<button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_step4', '.preopAlgo_step3b')" class="calcButton">&lt; Back</button>
			</div>

                        <!-- QID-7 -->
			<div class="preopAlgo_IndicationAT_AF calcItem groupItem">
                            <span class="error--text" v-show="debug===true">QID-7</span>
				<h3>Thromboembolic Risk (Atrial Fibrillation)</h3>
				<label>
					<input v-model="preop_selected.q7" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_AF', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="high"> High
				</label>
				<ul class="examples">
					<li>CHADS is 5 - 6, or</li>
					<li>stroke/TIA within 3 months, or</li>
					<li>rheumatic mitral stenosis</li>
				</ul>
				<label>
					<input v-model="preop_selected.q7" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_AF', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="moderate"> Moderate
				</label>
				<ul class="examples">
					<li>CHADS is 3 - 4</li>
				</ul>
				<label>
					<input v-model="preop_selected.q7" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_AF', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="low"> Low
				</label>
				<ul class="examples">
					<li>CHADS is 0 - 2</li>
				</ul>
			</div>
			<div class="calcItem preopAlgo_IndicationAT_AF"  style="text-align:center;">
				<button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_AF', '.preopAlgo_step4')" class="calcButton">&lt; Back</button>
			</div>
                        
                        <!--preopAlgo QID-8 -->
			<div class="preopAlgo_IndicationAT_DVT_PE calcItem groupItem">
                            <span class="error--text" v-show="debug===true">QID-8</span>
				<h3>Thromboembolic Risk (DVT/PE)</h3>
				<label>
					<input v-model="preop_selected.q8" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_DVT_PE', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="high"> High
				</label>
				<ul class="examples">
					<li>DVT/PE within 3 months, or</li>
					<li>Known severe thrombophilia*</li>
				</ul>
				<label>
					<input v-model="preop_selected.q8" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_DVT_PE', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="moderate"> Moderate
				</label>
				<ul class="examples">
					<li>VTE within 3-12 months, or</li>
					<li>Recurrent VTE, or</li>
					<li>Active cancer</li>
				</ul>
				<label>
					<input v-model="preop_selected.q8" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_DVT_PE', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="low"> Low
				</label>
				<ul class="examples">
					<li>Single VTE > 12 months prior</li>
				</ul>
			</div>
			<div class="calcItem preopAlgo_IndicationAT_DVT_PE"  style="text-align:center;">
				<button onclick="javascript:calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_DVT_PE', '.preopAlgo_step4')" class="calcButton">&lt; Back</button>
			</div>

                        <!--preopAlgo QID-9 -->
			<div class="preopAlgo_IndicationAT_Valve calcItem groupItem">
                            <span class="error--text" v-show="debug===true">QID-9</span>
				<h3>Thromboembolic Risk (Mechanical Valves)</h3>
				<label>
					<input v-model="preop_selected.q9" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_Valve', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="high"> High
				</label>
				<ul class="examples">
					<li>Any mitral valve prothesis, or</li>
					<li>Caged ball or tilting disc aortic prosthesis, or</li>
					<li>stroke/TIA within 6 months  </li>

				</ul>
				<label>
					<input v-model="preop_selected.q9" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_Valve', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="moderate"> Moderate
				</label>
				<ul class="examples">
					<li>Bileaflet aortic valve with either atrial fibrillation or CHADS score >= 1</li>
				</ul>
				<label>
					<input v-model="preop_selected.q9" type="radio" onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_Valve', '.preopAlgoResults');" name="preopAlgoThromboembolicRisk" value="low"> Low
				</label>
				<ul class="examples">
					<li>Bileaflet aortic valve without atrial fibrillation and CHADS score = 0</li>
				</ul>
			</div>
			<div class="calcItem preopAlgo_IndicationAT_Valve"  style="text-align:center;">
				<button onclick="calc.preopAnticoagulantAlgorithm('.preopAlgo_IndicationAT_Valve', '.preopAlgo_step4')" class="calcButton">&lt; Back</button>
			</div>



			<div class="preopAlgoResults calcItem">
				<h3>Summary</h3>
				<div class="summary"></div>
				<br>
				<h3>Preoperative Recommendations</h3>
				<div class="results"></div>
				<br>
				<h3>Postoperative Recommendations</h3>
				<div class="postop"></div>
				<a href="javascript:void(0)" class="bridgingSample" onclick="jQuery('#sampleBridgingProtocolWrapper').toggle();return false;" style="display:block; margin-top:20px;">Click here for a sample of a perioperative bridging protocol.</a>

				<div class="drugScheduleSection">
					<h3>Schedule</h3>
					<table class="drugScheduleTable">
						<tr>
							<th>Day</th>
							<th>Instructions</th>
						</tr>
						<tr id="periopScheduleTemplate">
							<td class="$day"></td>
							<td class="$instructions"></td>
						</tr>
					</table>
				</div>
			</div>    

			<!--  Vivomap  -->
			<div class="calcDescription vivomap261">
                            <template v-if="language==='fr-ca'">
				<h2>Embolie pulmonaire</h2>
                                Algorithme interactif sur l’embolie pulmonaire (EP)
                            </template>
                            <h2 v-else>Pulmonary Embolism Management</h2>
			</div>
			<div class="calcItem vivomap261">
				<div id="vivomap261" class="vivomap_styles" style="width:100%;height:100%;overflow:hidden;"></div>
				<span style="text-align: center;display:block;">powered by Vivomap<sup>&reg;</sup></span>
			</div>
			<div class="calcDescription vivomap262">
				<h2>Atrial Fibrillation</h2>
			</div>
			<div class="calcItem vivomap262">
				<div id="vivomap262" class="vivomap_styles" style="width:100%;height:100%;overflow:hidden;"></div>
				<span style="text-align: center;display:block;">powered by Vivomap<sup>&reg;</sup></span>
			</div>
			<div class="calcDescription vivomap271">
				<h2>Bleed Management</h2>
			</div>
			<div class="calcItem vivomap271">
				<div id="vivomap271" class="vivomap_styles" style="width:100%;height:100%;overflow:hidden;"></div>
				<span style="text-align: center;display:block;">powered by Vivomap<sup>&reg;</sup></span>
			</div>
			<div class="calcDescription vivomap263">
                            <template v-if="language==='fr-ca'">
				<h2>Thrombose veineuse profonde (TVP)</h2>
                                Algorithme interactif sur la thrombose veineuse profonde (TVP)
                            </template>
                            <h2 v-else>Deep Vein Thrombosis</h2>
                        </div>
			<div class="calcItem vivomap263">
				<div id="vivomap263" class="vivomap_styles" style="width:100%;height:100%;overflow:hidden;"></div>
				<span style="text-align: center;display:block;">powered by Vivomap<sup>&reg;</sup></span>
			</div>
			<div class="calcDescription vivomap329">
                                <h2  v-if="language==='fr-ca'">Suivi du traitement anticoagulant oral direct (AOD)</h2>
                                <h2 v-else>DOAC Follow-up</h2>                                
			</div>
			<div class="calcItem vivomap329">
				<div id="vivomap329" class="vivomap_styles" style="width:100%;height:100%;overflow:hidden;"></div>
				<span style="text-align: center;display:block;">powered by Vivomap<sup>&reg;</sup></span>
			</div>



			<!--  CHADS2   -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription chads2">
                                    <h2>Score CHADS2 pour le risque d’AVC lié à la fibrillation auriculaire non valvulaire</h2>
                                    Estimation du risque d’AVC lié à la fibrillation auriculaire non valvulaire à l’aide des critères CHADS2.
                            </div>
                            <div class="calcItem chads2 congestiveHeardFailure">
                                    <label>
                                            <input onclick="calc.chads2('fr-ca');" type="checkbox" name="congestiveHeardFailure">
                                            Antécédent d’insuffisance cardiaque congestive?
                                    </label>
                            </div>
                            <div class="calcItem chads2">
                                    <label>
                                            <input onclick="calc.chads2('fr-ca');" type="checkbox" name="hypertension">
                                            Antécédent d’hypertension?
                                    </label>
                            </div>
                            <div class="calcItem chads2 ageOver75">
                                    <label>
                                            <input onclick="calc.chads2('fr-ca');" type="checkbox" name="ageOver75">
                                            Âge &ge; 75 ans?
                                    </label>
                            </div>
                            <div class="calcItem chads2 diabetese">
                                    <label>
                                            <input onclick="calc.chads2('fr-ca');" type="checkbox" name="diabetese">
                                            Antécédent de diabète?
                                    </label>
                            </div>
                            <div class="calcItem chads2 strokeOrTIA">
                                    <label>
                                            <input onclick="calc.chads2('fr-ca');" type="checkbox" name="strokeOrTIA">
                                            Antécédent d’AVC ou d’AIT?
                                    </label>
                            </div>
                        </template>
                        <template v-else>
                            <div class="calcDescription chads2">
                                    <h2>CHADS2 Score for Atrial Fibrillation Stroke Risk</h2>
                                    Estimates stroke risk in patients with atrial fibrillation by the CHADS2 criteria.
                            </div>
                            <div class="calcItem chads2 congestiveHeardFailure">
                                    <label>
                                            <input onclick="calc.chads2();" type="checkbox" name="congestiveHeardFailure">
                                            Congestive Heart Failure history?
                                    </label>
                            </div>
                            <div class="calcItem chads2">
                                    <label>
                                            <input onclick="calc.chads2();" type="checkbox" name="hypertension">
                                            Hypertension history?
                                    </label>
                            </div>
                            <div class="calcItem chads2 ageOver75">
                                    <label>
                                            <input onclick="calc.chads2();" type="checkbox" name="ageOver75">
                                            Age &ge; 75?
                                    </label>
                            </div>
                            <div class="calcItem chads2 diabetese">
                                    <label>
                                            <input onclick="calc.chads2();" type="checkbox" name="diabetese">
                                            Diabetes Mellitus history?
                                    </label>
                            </div>
                            <div class="calcItem chads2 strokeOrTIA">
                                    <label>
                                            <input onclick="calc.chads2();" type="checkbox" name="strokeOrTIA">
                                            Previous stroke or TIA?
                                    </label>
                            </div>
                        </template>



			<!--  CHADS2-VASC  -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription chads2vasc">
                                    <h2>Score CHA2DS2-VASc pour le risque d’AVC lié à la fibrillation auriculaire</h2>
                                    Permet de calculer le risque d’AVC des patients atteints de fibrillation auriculaire, éventuellement mieux que le score CHA2DS2-VASC.
                            </div>
                            <div class="calcItem chads2vasc">
                                    Âge<br>
                                    <div class="radiogroup">
                                            <label>
                                                    <input type="radio" onclick="calc.chads2Vasc('fr-ca');" name="chads2vascAge" value="0">
                                                    &lt; 65 ans <span class="calcItemValue">(+0)</span>
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.chads2Vasc('fr-ca');" name="chads2vascAge" value="1">
                                                    65-74 ans <span class="calcItemValue">(+1)</span>
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.chads2Vasc('fr-ca');" name="chads2vascAge" value="2">
                                                    &ge; 75 ans <span class="calcItemValue">(+2)</span>
                                            </label>
                                    </div>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('fr-ca')" class="chads2vasc" name="chads2vascHeart">
                                            Antécédents d’insuffisance cardiaque congestive <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('fr-ca')" class="chads2vasc" name="chads2vascHyper">
                                            Antécédents d’hypertension <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('fr-ca')" class="chads2vasc" name="chads2vascStroke">
                                            Antécédents d’AVC, d’AIT ou de thromboembolie <span class="calcItemValue">(+2)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('fr-ca')" class="chads2vasc" name="chads2vascVascHist">
                                            Antécédents de pathologie vasculaire (antécédents d’infarctus du myocarde, de maladie artérielle périphérique ou de plaque aortique)? <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('fr-ca')" class="chads2vasc" name="chads2vascDiabetes">
                                            Diabète sucré <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('fr-ca')" class="chads2vasc" name="chads2vascFemale">
                                            Sexe féminin <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                        </template>
                        <template v-else>
                            <div class="calcDescription chads2vasc">
                                    <h2>CHA2DS2-VASc Score for Atrial Fibrillation Stroke Risk</h2>
                                    Calculates stroke risk for patients with atrial fibrillation, possibly better than the CHADS2 score.
                            </div>
                            <div class="calcItem chads2vasc">
                                    Age<br>
                                    <div class="radiogroup">
                                            <label>
                                                    <input type="radio" onclick="calc.chads2Vasc('en-ca');" name="chads2vascAge" value="0">
                                                    &lt; 65 <span class="calcItemValue">(+0)</span>
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.chads2Vasc('en-ca');" name="chads2vascAge" value="1">
                                                    65-74 <span class="calcItemValue">(+1)</span>
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.chads2Vasc('en-ca');" name="chads2vascAge" value="2">
                                                    &ge; 75 <span class="calcItemValue">(+2)</span>
                                            </label>
                                    </div>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('en-ca')" class="chads2vasc" name="chads2vascHeart">
                                            Congestive Heart Failure History <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('en-ca')" class="chads2vasc" name="chads2vascHyper">
                                            Hypertension History <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('en-ca')" class="chads2vasc" name="chads2vascStroke">
                                            Stroke/TIA/Thromboembolism History <span class="calcItemValue">(+2)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('en-ca')" class="chads2vasc" name="chads2vascVascHist">
                                            Vascular Disease History? (previous MI, peripheral arterial disease or aortic plaque) <span class="calcItemValue">(+1
                                            )</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('en-ca')" class="chads2vasc" name="chads2vascDiabetes">
                                            Diabetes Mellitus <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>
                            <div class="calcItem chads2vasc">
                                    <label>
                                            <input type="checkbox" onclick="calc.chads2Vasc('en-ca')" class="chads2vasc" name="chads2vascFemale">
                                            Female Patient <span class="calcItemValue">(+1)</span>
                                    </label>
                            </div>                            
                        </template>


			<!--  Creatinine Clearance (Cockcroft-Gault Equation)  -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription cockcroft">
                                    <h2>Clairance de la créatinine (équation de Cockcroft-Gault)</h2>
                                    Permet de calculer la clairance de la créatinine à l’aide de l’équation de Cockgroft-Gault.
                            </div>
                            <div class="calcItem cockcroft">
                                    <label>
                                            <input type="checkbox" name="isFemale" onclick="calc.creatinineClearance('fr-ca')">
                                            Sexe féminin
                                    </label>
                            </div>
                            <div class="calcItem cockcroft age">
                                    <label class="withNumber">
                                            Âge (ans)
                                            <input :required="activeCalc==='cockcroft'" type="number" oninvalid="this.setCustomValidity('Veuillez remplir ce champ')" onchange="calc.creatinineClearance('fr-ca')" onkeyup="calc.creatinineClearance('fr-ca')" name="cockcroftAge">
                                            <div class="validationMessage">!</div>
                                    </label>
                            </div>
                            <div class="calcItem cockcroft">
                                    <label class="withNumber">
                                            Poids (kg)
                                            <input :required="activeCalc==='cockcroft'" type="number" oninvalid="this.setCustomValidity('Veuillez remplir ce champ')" onchange="calc.creatinineClearance('fr-ca')" onkeyup="calc.creatinineClearance('fr-ca')" name="weight">
                                            <div class="validationMessage">!</div>
                                    </label>
                            </div>
                            <div class="calcItem cockcroft">
                                    <label class="withNumber">
                                            Créatinine sérique <span class="calcUnit">(µmol/L)</span>
                                            <input :required="activeCalc==='cockcroft'" type="number" oninvalid="this.setCustomValidity('Veuillez remplir ce champ')" onchange="calc.creatinineClearance('fr-ca')" onkeyup="calc.creatinineClearance('fr-ca')" name="serumCreatinine">
                                            <div class="validationMessage">!</div>
                                    </label>
                            </div>
                        </template>
                        <template v-else>
                            <div class="calcDescription cockcroft">
                                    <h2>Creatinine Clearance (Cockcroft-Gault Equation)</h2>
                                    Calculates creatinine clearance according to the Cockgroft-Gault equation.
                            </div>
                            <div class="calcItem cockcroft">
                                    <label>
                                            <input type="checkbox" name="isFemale" onclick="calc.creatinineClearance()">
                                            Female patient
                                    </label>
                            </div>
                            <div class="calcItem cockcroft age">
                                    <label class="withNumber">
                                            Age (years)
                                            <input :required="activeCalc==='cockcroft'" type="number" onchange="calc.creatinineClearance()" onkeyup="calc.creatinineClearance()" name="cockcroftAge">
                                            <div class="validationMessage">!</div>
                                    </label>
                            </div>
                            <div class="calcItem cockcroft">
                                    <label class="withNumber">
                                            Weight (kg)
                                            <input :required="activeCalc==='cockcroft'" type="number" onchange="calc.creatinineClearance()" onkeyup="calc.creatinineClearance()" name="weight">
                                            <div class="validationMessage">!</div>
                                    </label>
                            </div>
                            <div class="calcItem cockcroft">
                                    <label class="withNumber">
                                            Serum Creatinine <span class="calcUnit">(µmol/L)</span>
                                            <input :required="activeCalc==='cockcroft'" type="number" onchange="calc.creatinineClearance()" onkeyup="calc.creatinineClearance()" name="serumCreatinine">
                                            <div class="validationMessage">!</div>
                                    </label>
                            </div>
                        </template>




			<!--  HAS-BLED  -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription has-bled">
                                    <h2>Score HAS-BLED du risque d’hémorragie grave</h2>
                                    Estimation du risque d’hémorragie grave des patients sous anticoagulothérapie, permettant de déterminer le rapport risques/avantages du traitement de la fibrillation auriculaire
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledHypertension">
                                            Antécédents d’hypertension (non maîtrisée, TA systolique > 160 mm Hg)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledRenal">
                                            Néphropathie (dialyse, greffe, Cr > 2,6 mg/dL ou > 200 µmol/L)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledLiver">
                                            Maladie du foie (cirrhose, taux de bilirubine > 2 fois la normale, AST/ALT/AP > 3 fois la normale)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledStroke">
                                            Antécédents d’AVC
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledBleeding">
                                            Antécédents d’hémorragie grave ou prédisposition aux saignements 
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="labile">
                                            Fluctuations du RIN (RIN instables/élevés)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledageOver65">
                                            Âge &ge; 65 ans
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledMedBleeding">
                                            Prise de médicaments prédisposant aux hémorragies (antiplaquettaires, AINS)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled('fr-ca')" type="checkbox" name="hasBledAlcohol">
                                            Antécédents de consommation d’alcool
                                    </label>
                            </div>
                        </template>
                        <template v-else>
                            <div class="calcDescription has-bled">
                                    <h2>HAS-BLED Score for Major Bleeding Risk</h2>
                                    Estimates risk of major bleeding for patients on anticoagulation to help determine risk-benefit in atrial fibrillation care.
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledHypertension">
                                            Hypertension History (uncontrolled, >160 mmHg systolic)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledRenal">
                                            Renal Disease (Dialysis, transplant, Cr > 2.6 mg/dl or > 200 &micro;mol/L)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledLiver">
                                            Liver Disease (Cirrhosis, Bilirubin > 2x Normal, AST/ALT/AP > 3x Normal)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledStroke">
                                            Stroke History
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledBleeding">
                                            Prior Major Bleeding or Predisposition to Bleeding
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="labile">
                                            Labile INR (Unstable/high INRs)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledageOver65">
                                            Age &ge; 65
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledMedBleeding">
                                            Medication Usage Predisposing to Bleeding (Antiplatelet agents, NSAIDS)
                                    </label>
                            </div>
                            <div class="calcItem has-bled">
                                    <label>
                                            <input onclick="calc.hasBled()" type="checkbox" name="hasBledAlcohol">
                                            Alcohol Usage History
                                    </label>
                            </div>                            
                        </template>


			<!--  ABCD2  -->
			<div class="calcDescription abcd2">
				<h2>ABCD2 Score for TIA</h2>
				Estimates risk of stroke after a TIA, according to patient risk factors.
			</div>
			<div class="calcItem abcd2">
				<label>
					<input onclick="calc.abcd2()" type="checkbox" name="abcdAgeOver60">
					Age &ge; 60
				</label>
			</div>
			<div class="calcItem abcd2">
				<label>
					<input onclick="calc.abcd2()" type="checkbox" name="abcdBP">
					BP &ge; 140/90 mmHg at initial evaluation
				</label>
			</div>
			<div class="calcItem abcd2">
				Clinical Features of the TIA
				<div class="radiogroup">
					<label>
						<input onclick="calc.abcd2()" type="radio" name="abcdFeat" value="2">
						Unilateral Weakness
					</label>
					<label>
						<input onclick="calc.abcd2()" type="radio" name="abcdFeat" value="1">
						Speech Disturbance Without Weakness
					</label>
					<label>
						<input onclick="calc.abcd2()" type="radio" name="abcdFeat" value="0">
						Other Symptom
					</label>
				</div>
			</div>
			<div class="calcItem abcd2">
				Duration of Symptoms
				<div class="radiogroup">
					<label>
						<input onclick="calc.abcd2()" type="radio" name="abcdDur" value="0">
						&lt; 10 minutes
					</label>
					<label>
						<input onclick="calc.abcd2()" type="radio" name="abcdDur" value="1">
						10-59 minutes
					</label>
					<label>
						<input onclick="calc.abcd2()" type="radio" name="abcdDur" value="2">
						&ge; 60 minutes
					</label>
				</div>
			</div>
			<div class="calcItem abcd2">
				<label>
					<input onclick="calc.abcd2()" type="checkbox" name="abcdDiab">
					Diabetes Mellitus in Patient's History
				</label>
			</div>





			<!--  Wells DVT  -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription wellsDVT">
                                    <h2>Algorithme interactif axé sur les critères de Wells pour le diagnostic d’une TVP</h2>
                                    Calcul du score de Wells reflétant le risque de TVP
                            </div>

                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells0">
                                            Cancer actif?
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells1">
                                            Immobilisation  récente pendant > 3 jours ou chirurgie majeure au cours des 4 dernières semaines?
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells2">
                                            Enflure du mollet de > 3 cm comparativement à la jambe controlatérale?
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells3">
                                            Présence de veines superficielles (non variqueuses) collatérales?
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells4">
                                            Enflure de toute la jambe?
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells5">
                                            Sensibilité localisée le long du système veineux profond
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells6">
                                            Œdème qui prend le godet (plus important sur la jambe symptomatique)?
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells7">
                                            Paralysie, parésie ou immobilisation (mise en plâtre) récente du membre inférieur?
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wells8">
                                            Antécédents de TVP avérée
                                    </label>
                            </div>
                            <div class="calcItem wellsDVT">
                                    <label>
                                            <input onclick="calc.wellsDVT('fr-ca')" type="checkbox" name="wellsAlt">
                                            Autre diagnostic que la TVP plus probable ou aussi probable?
                                    </label>
                            </div>                            
                        </template>
                        <template v-else>
			<div class="calcDescription wellsDVT">
				<h2>Wells' Criteria for DVT</h2>
				Calculates Wells' Score for risk of DVT.
			</div>

			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells0">
					Active cancer?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells1">
					Bedridden recently > 3 days or major surgery within four weeks?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells2">
					Calf swelling > 3cm compared to the other leg?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells3">
					Collateral (nonvaricose) superficial veins present?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells4">
					Entire leg swollen?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells5">
					Localized tenderness along the deep venous system?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells6">
					Pitting edema, greater in the symptomatic leg?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells7">
					Paralysis, paresis, or recent plaster immobilization of the lower extremety
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wells8">
					Previously documented DVT?
				</label>
			</div>
			<div class="calcItem wellsDVT">
				<label>
					<input onclick="calc.wellsDVT('en-ca')" type="checkbox" name="wellsAlt">
					Alternative diagnosis to DVT as likely or more likely?
				</label>
			</div>
      </template> <!--  Wells DVT End -->




                        
      <!--  wells' PE  -->
      <template v-if="language==='fr-ca'">
          <div class="calcDescription wellsPE">
                  <h2>Algorithme interactif axé sur les critères de Wells pour le diagnostic de l’embolie pulmonaire</h2>
                  Calcul du score de Wells reflétant le risque d’embolie pulmonaire (EP)
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('fr-ca');" name="wellPE1">
                          Signes cliniques et symptômes d’une TVP
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('fr-ca');" name="wellPE2">
                          L’EP est le diagnostic primaire, ou est tout aussi probable
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('fr-ca');" name="wellPE3">
                          Fréquence cardiaque > 100 bpm
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('fr-ca');" name="wellPE4">
                          Immobilisation d’au moins 3 jours ou intervention chirurgicale au cours des 4 semaines précédentes
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('fr-ca');" name="wellPE5">
                          Antécédents d’EP ou de TVP diagnostiquée de manière objective
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('fr-ca');" name="wellPE6">
                          Hémoptysie
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('fr-ca');" name="wellPE7">
                          Cancer dont le traitement remonte aux 6 derniers mois, ou soins palliatifs
                  </label>
          </div>                            
      </template>
      <template v-else>
          <div class="calcDescription wellsPE">
                  <h2>Wells' Criteria for PE Interactive Algorithm</h2>
                  Calculates Wells' Score for risk of PE.
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('en-ca');" name="wellPE1">
                          Clinical Signs and Symptoms of DVT
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('en-ca');" name="wellPE2">
                          PE Is #1 Diagnosis, or Equally Likely
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('en-ca');" name="wellPE3">
                          Heart Rate > 100
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('en-ca');" name="wellPE4">
                          Immobilization at least 3 days, or Surgery in the Previous 4 weeks
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('en-ca');" name="wellPE5">
                          Previous, objectively diagnosed PE or DVT
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('en-ca');" name="wellPE6">
                          Hemoptysis
                  </label>
          </div>
          <div class="calcItem wellsPE">
                  <label>
                          <input type="checkbox" onclick="calc.wellsPE('en-ca');" name="wellPE7">
                          Malignancy w/ Treatment within 6 mo, or palliative
                  </label>
          </div>
      </template>   <!--  end wells' PE  -->



                    <template v-if="language==='fr-ca'">
			<div class="calcDescription perc">
				<h2>Règle de PERC pour l’embolie pulmonaire</h2>
				Présentation des critères de PERC permettant d’écarter l’embolie pulmonaire (EP) si tous les critères sont remplis et si la probabilité prétest est &le; 15 %.
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc1">
					Âge &lt; 50 ans
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc2">
					FC &lt; 100
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc3">
          Saturation en O<sub>2</sub> à l’air ambiant &gt;94%
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc4">
					Aucun antécédent de TVP/d’EP
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc5">
					Absence de traumatisme récent ou d’intervention chirurgicale récente
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc6">
					Absence d’hémoptysie
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc7">
					Pas de prise d’œstrogène exogène
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc('fr-ca')" name="perc8">
					Aucun signe clinique évoquant une TVP
				</label>
			</div>                        
                    </template>
                    <template v-else>
			<div class="calcDescription perc">
				<h2>PERC Rule for Pulmonary Embolism</h2>
				Shows the PERC criteria, which can rule out PE if all criteria are present and pre-test probability is &le;15%.
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc1">
					Age &lt; 50
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc2">
					HR &lt; 100
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc3">
                                        O<sub>2</sub> Sat on Room Air &gt;94%
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc4">
					No Prior History of DVT/PE
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc5">
					No Recent Trauma or Surgery
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc6">
					No Hemoptysis
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc7">
					No Exogenous Estrogen
				</label>
			</div>
			<div class="calcItem perc">
				<label>
					<input type="checkbox" onclick="calc.perc()" name="perc8">
					No Clinical Signs Suggesting DVT
				</label>
			</div>
                    </template>





                    <template v-if="language==='fr-ca'">
			<div class="calcDescription timiNSTEMI">
				<h2>Algorithme interactif axé sur le score de risque TIMI pour l’angor instable/le NSTEMI</h2>
				Estimation de la mortalité des patients atteints d’angor instable et d’un infarctus du myocarde sans élévation du segment ST.
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI('fr-ca')" name="timiNSTEMI1">
					Âge &ge; 65 ans
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI('fr-ca')" name="timiNSTEMI2">
					&ge; 3 facteurs de risque de maladie coronarienne
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI('fr-ca')" name="timiNSTEMI3">
					Maladie coronarienne avérée (sténose &ge; 50 %)
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI('fr-ca')" name="timiNSTEMI4">
					Prise d’AAS au cours des 7 derniers jours
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI('fr-ca')" name="timiNSTEMI5">
					Angor sévère (&ge; 2 épisodes en 24 heures)
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI('fr-ca')" name="timiNSTEMI6">
					Décalages ST &ge; 0,5 mm
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI('fr-ca')" name="timiNSTEMI7">
					Marqueur cardiaque +
				</label>
			</div>                        
                    </template>
                    <template v-else>
			<div class="calcDescription timiNSTEMI">
				<h2>TIMI Risk Score for UA/NSTEMI</h2>
				Estimates mortality for patients with unstable angina and non-ST elevation MI.
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI()" name="timiNSTEMI1">
					Age &ge; 65 years
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI()" name="timiNSTEMI2">
					&ge; 3 Risk Factors for CAD
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI()" name="timiNSTEMI3">
					Known CAD (stenosis &ge; 50%)
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI()" name="timiNSTEMI4">
					ASA Use in Past 7d
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI()" name="timiNSTEMI5">
					Severe angina (&ge; 2 episodes w/in 24 hrs)
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI()" name="timiNSTEMI6">
					ST changes &ge; 0.5mm
				</label>
			</div>
			<div class="calcItem timiNSTEMI">
				<label>
					<input type="checkbox" onclick="calc.timiNSTEMI()" name="timiNSTEMI7">
					+ Cardiac Marker
				</label>
			</div>
                    </template>






			<!--  timi for STEMI -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription timiSTEMI">
                                    <h2>Algorithme interactif axé sur le score de risque TIMI lié au STEMI</h2>
                                    Estimations du taux de mortalité des patients ayant subi un STEMI .
                            </div>
                            <div class="calcItem timiSTEMI">
                                    Âge<br>
                                    <div class="radiogroup">
                                            <label>
                                                    <input type="radio" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMIAge" value="0" checked="checked">
                                                    &lt; 65 ans
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMIAge" value="2">
                                                    65-74 ans
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMIAge" value="3">
                                                    &ge; 75 ans
                                            </label>
                                    </div>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMI1">
                                            Diabète sucré ou hypertension ou angine
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMI2">
                                            TAS &lt; 100 mmHg
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMI3">
                                            FC &gt; 100 bpm
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMI4">
                                            Classe II-IV de Killip
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMI5">
                                            Poids &lt; 67 kg (147.7 lb)
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMI6">
                                            Élévation du segment ST antérieur ou bloc de branche gauche (BBG)
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('fr-ca');" name="timiSTEMI7">
                                            Délai précédant le traitement &gt; 4 h
                                    </label>
                            </div>
                        </template>
                        <template v-else>
                            <div class="calcDescription timiSTEMI">
                                    <h2>TIMI Risk Score for STEMI</h2>
                                    Estimates mortality in patients with STEMI.
                            </div>
                            <div class="calcItem timiSTEMI">
                                    Age<br>
                                    <div class="radiogroup">
                                            <label>
                                                    <input type="radio" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMIAge" value="0" checked="checked">
                                                    &lt; 65
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMIAge" value="2">
                                                    65-74
                                            </label>
                                            <label>
                                                    <input type="radio" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMIAge" value="3">
                                                    &ge; 75
                                            </label>
                                    </div>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMI1">
                                            DM or HTN or Angina
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMI2">
                                            SBP &lt; 100 mmHg
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMI3">
                                            HR &gt; 100 bpm
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMI4">
                                            Killip Class II-IV
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMI5">
                                            Weight &lt; 67 kg (147.7 lbs)
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMI6">
                                            Anterior ST Elevation or LBBB
                                    </label>
                            </div>
                            <div class="calcItem timiSTEMI">
                                    <label>
                                            <input type="checkbox" onclick="calc.timiSTEMI('en-ca');" name="timiSTEMI7">
                                            Time to Treatment &gt; 4 hrs
                                    </label>
                            </div>
                        </template>






			<!--  PESI  -->
                        <template v-if="language==='fr-ca'">
                            <div class="calcDescription pesi">
                                    <h2>Algorithme interactif de l’indice de gravité de l’embolie pulmonaire (score PESI)</h2>
                                    1.0	Permet de prédire l’issue clinique à 30 jours de patients atteints d’une embolie pulmonaire à l’aide de 11 critères cliniques
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            Âge (ans) <span class="calcItemValue">(+1 per year)</span> <span class="validationMessage">required</span>
                                            <input type="number" onchange="calc.pesi('fr-ca')" onkeyup="calc.pesi('fr-ca')" name="pesiAge" :required="activeCalc==='pesi'">
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiMale">
                                            Sexe masculin	 <span class="calcItemValue">(+10)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiCancer">
                                            Antécédent de cancer	 <span class="calcItemValue">(+30)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiHeartFailure">
                                            Antécédent d’insuffisance cardiaque	 <span class="calcItemValue">(+10)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiLung">
                                            Antécédent de maladie pulmonaire chronique	 <span class="calcItemValue">(+10)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiHeartRate">
                                            Fréquence cardiaque &ge; 110	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi'fr-ca')" name="pesiBloodPressure">
                                            Tension artérielle systolique &lt; 100 mmHg	 <span class="calcItemValue">(+30)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiRespiratoryRate">
                                            Fréquence respiratoire &ge; 30/min	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiTemp">
                                            Température &lt; 36&deg; C (96.8&deg; F)	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiMental">
                                            Altération de l’état mental (désorientation, léthargie, obnubilation mentale ou coma)	 <span class="calcItemValue">(+60)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi('fr-ca')" name="pesiO2">
                                            Saturation en O<sub>2</sub> &lt; 90 % à l’air ambiant	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                        </template>
                        <template v-else>
                            <div class="calcDescription pesi">
                                    <h2>Pulmonary Embolism Severity Index (PESI)</h2>
                                    Predicts 30-day outcome of patients with pulmonary embolism using 11 clinical criteria.
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            Age (years) <span class="calcItemValue">(+1 per year)</span> <span class="validationMessage">required</span>
                                            <input type="number" onchange="calc.pesi()" onkeyup="calc.pesi()" name="pesiAge" :required="activeCalc==='pesi'">
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiMale">
                                            Male Patient	 <span class="calcItemValue">(+10)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiCancer">
                                            History of Cancer	 <span class="calcItemValue">(+30)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiHeartFailure">
                                            History of heart failure	 <span class="calcItemValue">(+10)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiLung">
                                            History of chronic lung disease	 <span class="calcItemValue">(+10)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiHeartRate">
                                            Heart Rate &ge; 110	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiBloodPressure">
                                            Systolic Blood Pressure &lt; 100 mmHg	 <span class="calcItemValue">(+30)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiRespiratoryRate">
                                            Respiratory Rate &ge; 30/min	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiTemp">
                                            Temperature &lt; 36&deg; C (96.8&deg; F)	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiMental">
                                            Altered Mental Status (disorientation, lethargy, stupor, or coma)	 <span class="calcItemValue">(+60)</span>
                                    </label>
                            </div>
                            <div class="calcItem pesi">
                                    <label>
                                            <input type="checkbox" onclick="calc.pesi()" name="pesiO2">
                                            O<sub>2</sub> Saturation &lt; 90% on Room Air	 <span class="calcItemValue">(+20)</span>
                                    </label>
                            </div>
                        </template>





			<!--  simplified PESI -->
                        <template v-if="language==='fr-ca'">
			<div class="calcDescription simplifiedPesi">
				<h2>Algorithme interactif axé sur la version simplifiée de l’indice de gravité de l’embolie pulmonaire (PESI)</h2>
				Outil prédictif de l’issue à 30 jours d’une embolie pulmonaire à partir de critères moins nombreux que ceux de l’indice PESI original.
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'fr-ca')" name="simplePesiAge">
					Âge &gt; 80 ans <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'fr-ca')" name="simplePesiCancer">
					Antécédents de cancer	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'fr-ca')" name="simplePesiHeartFailure">
					Antécédents de maladie cardiopulmonaire chronique	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'fr-ca')" name="simplePesiHeartRate">
					Fréquence cardiaque &ge; 110/min	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'fr-ca')" name="simplePesiBloodPressure">
					Tension artérielle systolique &le; 100 mmHg	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'fr-ca')" name="simplePesiO2">
					Saturation en O<sub>2</sub> &lt; 90% à l’air ambiant	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(true, 'fr-ca')" name="simplePesiNone">
					Le patient ne remplit aucun de ces critères
				</label>
			</div>                            
                        </template>
                        <template v-else>
			<div class="calcDescription simplifiedPesi">
				<h2>Simplified PESI (Pulmonary Embolism Severity Index)</h2>
				Predicts 30-day outcome of patients with pulmonary embolism with fewer criteria than the original Pulmonary Embolism Severity Index.
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'en-ca')" name="simplePesiAge">
					Patient Age &gt; 80 (years) <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'en-ca')" name="simplePesiCancer">
					History of Cancer	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'en-ca')" name="simplePesiHeartFailure">
					History of Chronic Cardiopulmonary Disease	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'en-ca')" name="simplePesiHeartRate">
					Heart Rate &ge; 110	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'en-ca')" name="simplePesiBloodPressure">
					Systolic Blood Pressure &le; 100 mmHg	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(false, 'en-ca')" name="simplePesiO2">
					O<sub>2</sub> Saturation &lt; 90% on Room Air	 <span class="calcItemValue">(+1)</span>
				</label>
			</div>
			<div class="calcItem simplifiedPesi">
				<label>
					<input type="checkbox" onclick="calc.simplifiedPesi(true, 'en-ca')" name="simplePesiNone">
					Patient Has None of These
				</label>
			</div>
                        </template>    



		</div>

		<!-- end of calcs, and score/decription area -->
		<div class="scoreItem">
                        <p id="calc-Thrombophilia-Footer"></p>
			<div id="calcScoreLabel">Score</div>
			<div id="calcScoreWrapper">
				<span id="calcScore"></span>
				<span id="calcScoreUnits"></span>
			</div>
		</div>
		<div class="scoreDescription generalDesc"></div>
                <button v-if="isNativeToolCalc || isLegacyToolCalc" id="calcClear" @click="$emit('reset')"><Str :index="'clinical_tools.buttons.reset'" :language="language"/></button>
                <button v-else id="calcClear" @click="initToolSelection('preopAlgo')" onclick="calc.reset(); calc.clear();"><Str :index="'clinical_tools.buttons.reset'" :language="language"/></button>
                
                <v-btn v-if="debugView==='show'" @click="toggleDebug">ToggleDebug View</v-btn>
                
		<div id="calcFooter" style="display:none;">
			<img src="https://thrombosiscanada.ca/websiteResources/tc-logo-grey.jpg" alt="thrombosis canada logo">Brought to you by Thrombosis Canada
		</div>
	</form>

    

	<!-- Sample Bridging Protocol popup -->
	<div id="sampleBridgingProtocolWrapper" style="display: none;">
		<a class="buttonLink" href='#' onclick="javascript:jQuery('#sampleBridgingProtocolWrapper').toggle(); return false;">Close</a>
		<a id="printLink" class="buttonLink" target="_blank" href='guides/calculators/printSampleBridging.html'>Print</a>
		<h2>Sample Bridging Protocol </h2>
		<table class="sampleBridgingProtocol">
			<tr><th>Day</th> <th>Warfarin</th> <th>LMWH</th></tr>
			<tr><td>-6</td><td>
				<span class="checkmark">&#10004;</span>
				<img class="checkmarkImage" src="https://thrombosiscanada.ca/websiteResources/checkmark.png" alt="checked">
			</td><td> <span class="checkedNo">X</span> </td></tr>
			<tr><td>-5</td><td> <span class="checkedNo">X</span></td><td> <span class="checkedNo">X</span> </td></tr>
			<tr><td>-4</td><td> <span class="checkedNo">X</span></td><td> <span class="checkedNo">X</span> </td></tr>
			<tr><td>-3</td><td> <span class="checkedNo">X</span></td><td>
				<span class="checkmark">&#10004;</span>
				<img class="checkmarkImage" src="https://thrombosiscanada.ca/websiteResources/checkmark.png" alt="checked">
				 </td></tr>
			<tr><td>-2</td><td> <span class="checkedNo">X</span></td><td>
				<span class="checkmark">&#10004;</span>
				<img class="checkmarkImage" src="https://thrombosiscanada.ca/websiteResources/checkmark.png" alt="checked">
				 </td></tr>
			<tr><td>-1</td><td> <span class="checkedNo">X</span></td><td>
				<span class="checkmark">&#10004;</span>
				<img class="checkmarkImage" src="https://thrombosiscanada.ca/websiteResources/checkmark.png" alt="checked">
				* </td></tr>
			<tr><td>Surgery</td><td><span class="checkedNo">X</span> </td><td> <span class="checkedNo">X</span> </td></tr>
			<tr><td>+1</td><td>
				<span class="checkmark">&#10004;</span>
				<img class="checkmarkImage" src="https://thrombosiscanada.ca/websiteResources/checkmark.png" alt="checked">
			</td><td>
				<span class="checkmark">&#10004;</span>
				<img class="checkmarkImage" src="https://thrombosiscanada.ca/websiteResources/checkmark.png" alt="checked">
				** </td></tr>
			<tr><td>+2</td><td>
				<span class="checkmark">&#10004;</span>
				<img class="checkmarkImage" src="https://thrombosiscanada.ca/websiteResources/checkmark.png" alt="checked">
			</td><td>
				<span class="checkmark">&#10004;</span>
				<img class="checkmarkImage" src="https://thrombosiscanada.ca/websiteResources/checkmark.png" alt="checked">
				** </td></tr>
			<tr><td>+3</td><td>
				<span class="checkmark">&#10004;</span>
				<img class="checkmarkImage" src="https://thrombosiscanada.ca/websiteResources/checkmark.png" alt="checked">
			</td><td>
				<span class="checkmark">&#10004;</span>
				<img class="checkmarkImage" src="https://thrombosiscanada.ca/websiteResources/checkmark.png" alt="checked">
				*** </td></tr>
		</table>
		* Use half daily dose administered 24h prior to procedure <br>
		** If high bleeding risk, hold, or use prophylactic dose LMWH <br>
		*** Continue LMWH until INR in therapeutic range <br>
	</div>
	<!-- /Sample Bridging Protocol popup -->

        <v-dialog v-model="display_modal.PCCDosingWrapper" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="PCCDosingWrapper" class="myPopupMessage" style="display: block;">
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.PCCDosingWrapper=false">Close</v-btn>
                    </v-app-bar>
                    
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <h2 class="text-decoration-underline"><strong>PCC dosing (based on weight and INR)</strong></h2>

                        <table border="1" cellpadding="0" cellspacing="0" style="width:100%">
                            <tbody>
                                <tr>
                                    <td style="height:39px; width:148px">
                                    <p style="text-align: center;">&nbsp;</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;"><strong>INR 1.6-1.9</strong></p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;"><strong>INR 2.0-2.9</strong></p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;"><strong>INR 3.0-5.0</strong></p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;"><strong>INR &gt; 5.0</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height:39px; width:148px">
                                    <p style="text-align: center;"><strong>Wt &lt; 100 kg</strong></p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">500 units</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">1000 units</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">2000 units</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">3000 units (maximum)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height:39px; width:148px">
                                    <p style="text-align: center;"><strong>Wt &ge; 100 kg</strong></p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">1000 units</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">1500 units</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">2500 units</p>
                                    </td>
                                    <td style="width:203px">
                                    <p style="text-align: center;">3000 units (maximum)</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <p><strong>Vitamin K Dosing</strong><br />
                        INR &gt; 1.5 &#8594; 10 mg in 50 mL normal saline IV STAT</p>

                        <p>If INR not reported or weight unknown and cannot delay PCC administration give PCC 2000 units IV and vitamin K 10 mg IV STAT</p>

                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
            </div>
        </v-dialog>

	<div id="ApixabanTable" class="myPopupMessage" style="display: none;">
		<a class="buttonLink" href='#' onclick="javascript:jQuery('#ApixabanTable').toggle(); return false;">Close</a>
		<br><br>
		<table border="1" cellpadding="0" cellspacing="0" style="width:100%">
			<thead>
				<tr>
					<th colspan="4" scope="col">
					<p>Apixaban (Eliquis<sup>&reg;</sup>)**</p>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
					<p style="text-align:center"><strong>Test</strong></p>
					</td>
					<td>
					<p style="text-align:center"><strong>Characteristics</strong></p>
					</td>
					<td>
					<p style="text-align:center"><strong>Normal</strong></p>
					</td>
					<td>
					<p style="text-align:center"><strong>Abnormal</strong></p>
					</td>
				</tr>
				<tr>
					<td>
					<p style="text-align:center">Calibrated anti-Xa</p>
					</td>
					<td>
					<p style="text-align:center">Accurate, reliable</p>
					</td>
					<td>
					<p style="text-align:center">&lt;30-50 ng/mL</p>

					<p style="text-align:center">Likely no significant anticoagulant effect.*</p>
					</td>
					<td>
					<p style="text-align:center">&ge;30-50 ng/mL</p>

					<p style="text-align:center">Likely significant anticoagulant effect*</p>
					</td>
				</tr>
				<tr>
					<td>
					<p style="text-align:center">PT/INR</p>
					</td>
					<td>
					<p style="text-align:center">Poor sensitivity</p>
					</td>
					<td>
					<p style="text-align:center">Does not exclude anticoagulant effect.</p>
					</td>
					<td>
					<p style="text-align:center">May indicate anticoagulant effect.</p>

					<p style="text-align:center">Rule out other causes of abnormal PT/INR (e.g. DIC, coagulopathy of liver disease, vitamin K deficiency, warfarin).</p>
					</td>
				</tr>
				<tr>
					<td>
					<p style="text-align:center">aPTT</p>
					</td>
					<td>
					<p style="text-align:center">Poor sensitivity</p>
					</td>
					<td>
					<p style="text-align:center">Does not exclude anticoagulant effect.</p>
					</td>
					<td>
					<p style="text-align:center">May indicate anticoagulant effect.</p>

					<p style="text-align:center">Rule out other causes of abnormal aPTT (e.g. coagulopathy of liver disease, direct thrombin inhibitor, coagulation factor deficiency)</p>
					</td>
				</tr>
			</tbody>
		</table>

		<p>Anti-Xa, anti-factor Xa activity assay; aPTT, activated partial thromboplastin time; INR, international normalized ratio; PT, prothrombin time.</p>

		<p>*There are no data to establish a hemostatic threshold below which drug levels are unlikely to affect hemostasis. The level chosen (&lt;30-50 ng/mL) is extrapolated from rivaroxaban data and is in agreement with recent guidelines [Pernod 2013].</p>

		<p>**Best practices for the use and interpretation of coagulation tests in patient on DOACs is evolving and will be informed by ongoing research; the guidance herein reflects a summation of the available evidence.</p>


		<p style="text-align:right">&nbsp;</p>

	</div>
       
        <v-dialog v-model="display_modal.ApixabanTable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="ApixabanTable" class="myPopupMessage" style="display: block;">
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.ApixabanTable = false">Close</v-btn>
                    </v-app-bar>          
                    
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">        
                        <table border="1" cellpadding="0" cellspacing="0" style="width:100%">
                            <thead>
                                    <tr>
                                        <th colspan="4" scope="col"><div style="display: inline-flex;">Apixaban (Eliquis<div style="font-size:11px;">&reg;</div>)**</div></th>
                                    </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p style="text-align:center"><strong>Test</strong></p></td>
                                    <td><p style="text-align:center"><strong>Characteristics</strong></p></td>
                                    <td><p style="text-align:center"><strong>Normal</strong></p></td>
                                    <td><p style="text-align:center"><strong>Abnormal</strong></p></td>
                                </tr>
                                <tr>
                                    <td><p style="text-align:center">Calibrated anti-Xa</p></td>
                                    <td><p style="text-align:center">Accurate, reliable</p></td>
                                    <td>
                                        <p style="text-align:center">&lt;30-50 ng/mL</p>
                                        <p style="text-align:center">Likely no significant anticoagulant effect.*</p>
                                    </td>
                                    <td>
                                        <p style="text-align:center">&ge;30-50 ng/mL</p>
                                        <p style="text-align:center">Likely significant anticoagulant effect*</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p style="text-align:center">PT/INR</p></td>
                                    <td><p style="text-align:center">Poor sensitivity</p></td>
                                    <td><p style="text-align:center">Does not exclude anticoagulant effect.</p></td>
                                    <td>
                                        <p style="text-align:center">May indicate anticoagulant effect.</p>
                                        <p style="text-align:center">Rule out other causes of abnormal PT/INR (e.g. DIC, coagulopathy of liver disease, vitamin K deficiency, warfarin).</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p style="text-align:center">aPTT</p></td>
                                    <td><p style="text-align:center">Poor sensitivity</p></td>
                                    <td><p style="text-align:center">Does not exclude anticoagulant effect.</p></td>
                                    <td>
                                        <p style="text-align:center">May indicate anticoagulant effect.</p>
                                        <p style="text-align:center">Rule out other causes of abnormal aPTT (e.g. coagulopathy of liver disease, direct thrombin inhibitor, coagulation factor deficiency)</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <p>Anti-Xa, anti-factor Xa activity assay; aPTT, activated partial thromboplastin time; INR, international normalized ratio; PT, prothrombin time.</p>
                        <p>*There are no data to establish a hemostatic threshold below which drug levels are unlikely to affect hemostasis. The level chosen (&lt;30-50 ng/mL) is extrapolated from rivaroxaban data and is in agreement with recent guidelines [Pernod 2013].</p>
                        <p>**Best practices for the use and interpretation of coagulation tests in patient on DOACs is evolving and will be informed by ongoing research; the guidance herein reflects a summation of the available evidence.</p>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
            </div>
        </v-dialog>

        <v-dialog v-model="display_modal.DabigatranTable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="DabigatranTable" class="myPopupMessage" style="display: block;">
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.DabigatranTable = false">Close</v-btn>
                    </v-app-bar>          
                    
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <table align="center" border="1" cellpadding="0" cellspacing="0" style="width:100%">
                                <thead>
                                    <tr>
                                        <th colspan="4" scope="col">
                                            <div style="display: inline-flex;">Dabigatran (Pradaxa<div style="font-size:11px;">&reg;</div>)**</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p style="text-align: center;"><strong>Test</strong></p></td>
                                        <td><p style="text-align: center;"><strong>Characteristics</strong></p></td>
                                        <td><p style="text-align: center;"><strong>Normal</strong></p></td>
                                        <td><p style="text-align: center;"><strong>Abnormal</strong></p></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style="text-align: center;">Dilute TCT (Hemoclot<sup>&reg;</sup>)</p>
                                            <p style="text-align: center;">ECA</p>
                                            <p style="text-align: center;">ECT</p>
                                        </td>
                                        <td><p style="text-align: center;">Accurate, reliable</p></td>
                                        <td>
                                            <p style="text-align: center;">&lt;30-50 ng/mL</p>
                                            <p style="text-align: center;">Likely no significant anticoagulant effect.*</p>
                                        </td>
                                        <td>
                                            <p style="text-align: center;">&ge;30-50 ng/mL</p>
                                            <p style="text-align: center;">Likely significant anticoagulant effect.*</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><p style="text-align: center;">TCT</p></td>
                                        <td><p style="text-align: center;">Very sensitive</p></td>
                                        <td><p style="text-align: center;">Likely no significant anticoagulant effect.</p></td>
                                        <td><p style="text-align: center;">Anticoagulant effect present</p></td>
                                    </tr>
                                    <tr>
                                        <td><p style="text-align: center;">aPTT</p></td>
                                        <td><p style="text-align: center;">Moderate sensitivity, high variability</p></td>
                                        <td><p style="text-align: center;">May not exclude significant anticoagulant effect.</p></td>
                                        <td><p style="text-align: center;">Anticoagulant effect present</p></td>
                                    </tr>
                                    <tr>
                                        <td><p style="text-align: center;">PT/INR</p></td>
                                        <td><p style="text-align: center;">Poor sensitivity, high variability</p></td>
                                        <td><p style="text-align: center;">Does not exclude anticoagulant effect.</p></td>
                                        <td>
                                            <p style="text-align: center;">May indicate anticoagulant effect.</p>
                                            <p style="text-align: center;">Rule out other causes of abnormal PT/INR (e.g. DIC, coagulopathy of liver disease, vitamin K deficiency, warfarin).</p>
                                        </td>
                                    </tr>
                                </tbody>
                        </table>

                        <p>aPTT, activated partial thromboplastin time; ECA, ecarin chromogenic assay; ECT, ecarin clotting time; TCT, thrombin clotting time.</p>

                        <p>*There are no data to establish a hemostatic threshold below which drug levels are unlikely to affect hemostasis. The level chosen (&lt;30-50 ng/mL) is a conservative estimate based on the estimated dabigatran levels in patients undergoing surgical procedures in the RE-LY trial in which there was no increased risk of perioperative bleeding with dabigatran compared to warfarin, and is in agreement with recent guidelines (Healy 2012, Pernod 2013).&nbsp; Note that the lower limit of detection of the Hemoclot<sup>&reg;</sup> assay is 50 ng/mL.&nbsp;</p>

                        <p>**Best practices for the use and interpretation of coagulation tests in patient on DOACs is evolving and will be informed by ongoing research; the guidance herein reflects a summation of the available evidence.</p>


                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>                
            </div>
        </v-dialog>    
        
        <v-dialog v-model="display_modal.RivaroxabanTable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="RivaroxabanTable" class="myPopupMessage" style="display: block;">
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.RivaroxabanTable = false">Close</v-btn>
                    </v-app-bar>          
                    
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">        
                        <table border="1" cellpadding="0" cellspacing="0" style="width:100%">
                            <thead>
                                <tr>
                                    <th colspan="4" scope="col"><div style="display: inline-flex;">Rivaroxaban (Xarelto<div style="font-size:11px;">&reg;</div>)**</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p style="text-align: center;"><strong>Test</strong></p></td>
                                    <td><p style="text-align: center;"><strong>Characteristics</strong></p></td>
                                    <td><p style="text-align: center;"><strong>Normal</strong></p></td>
                                    <td><p style="text-align: center;"><strong>Abnormal</strong></p></td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">Calibrated anti-Xa</p></td>
                                    <td><p style="text-align: center;">Accurate, reliable</p></td>
                                    <td>
                                        <p style="text-align: center;">&lt;30-50 ng/mL</p>
                                        <p style="text-align: center;">Likely no significant anticoagulant effect.*</p>
                                    </td>
                                    <td>
                                        <p style="text-align: center;">&ge;30-50 ng/mL</p>
                                        <p style="text-align: center;">Likely significant anticoagulant effect.*</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">PT/INR</p></td>
                                    <td><p style="text-align: center;">Moderate sensitivity, high variability</p></td>
                                    <td><p style="text-align: center;">Does not exclude anticoagulant effect.</p></td>
                                    <td><p style="text-align: center;">Anticoagulant effect present.</p></td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">aPTT</p></td>
                                    <td><p style="text-align: center;">Poor sensitivity, high variability</p></td>
                                    <td><p style="text-align: center;">Does not exclude anticoagulant effect.</p></td>
                                    <td>
                                        <p style="text-align: center;">May indicate anticoagulant effect.</p>
                                        <p style="text-align: center;">Rule out other causes of abnormal aPTT (e.g. coagulopathy of liver disease, direct thrombin inhibitor, coagulation factor deficiency).</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <p>Anti-Xa, anti-factor Xa activity assay; aPTT, activated partial thromboplastin time; INR, international normalized ratio; PT, prothrombin time.</p>
                        <p>* There are no data to establish a hemostatic threshold below which drug levels are unlikely to affect hemostasis. The level chosen (&lt; 30-50 ng/mL) is based on the estimated drug levels in patients undergoing surgical procedures in the ROCKET-AF trial in which there was no increased risk of perioperative bleeding compared to warfarin, and is in agreement with recent guidelines [Patel 2011, Pernod 2013].</p>
                        <p>**Best practices for the use and interpretation of coagulation tests in patient on DOACs is evolving and will be informed by ongoing research; the guidance herein reflects a summation of the available evidence.</p>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
            </div>
        </v-dialog>

        <v-dialog v-model="display_modal.EdoxabanTable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="EdoxabanTable" class="myPopupMessage" style="display: block;">
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.EdoxabanTable = false">Close</v-btn>
                    </v-app-bar>          
                    
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">         
                        <table border="1" cellpadding="0" cellspacing="0" style="width:100%">
                            <thead>
                                    <tr>
                                        <th colspan="4" scope="col"><div style="display: inline-flex;">Edoxaban (Lixiana<div style="font-size:11px;">&reg;</div>)*</div></th>
                                    </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p style="text-align: center;"><strong>Test</strong></p></td>
                                    <td><p style="text-align: center;"><strong>Characteristics</strong></p></td>
                                    <td><p style="text-align: center;"><strong>Normal</strong></p></td>
                                    <td><p style="text-align: center;"><strong>Abnormal</strong></p></td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">Calibrated anti-Xa</p></td>
                                    <td><p style="text-align: center;">Accurate, reliable</p></td>
                                    <td>
                                        <p style="text-align: center;">&lt;30-50 ng/mL</p>
                                        <p style="text-align: center;">Likely no significant anticoagulant effect.</p>
                                    </td>
                                    <td>
                                        <p style="text-align: center;">&ge;30-50 ng/mL</p>
                                        <p style="text-align: center;">Likely significant anticoagulant effect.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">PT/INR</p></td>
                                    <td><p style="text-align: center;">Moderate sensitivity, high variability</p></td>
                                    <td><p style="text-align: center;">Does not exclude anticoagulant effect.</p></td>
                                    <td><p style="text-align: center;">Anticoagulant effect present.</p></td>
                                </tr>
                                <tr>
                                    <td><p style="text-align: center;">aPTT</p></td>
                                    <td><p style="text-align: center;">Poor sensitivity, high variability</p></td>
                                    <td><p style="text-align: center;">Does not exclude anticoagulant effect.</p></td>
                                    <td>
                                        <p style="text-align: center;">May indicate anticoagulant effect.</p>
                                        <p style="text-align: center;">Rule out other causes of abnormal aPTT (e.g. coagulopathy of liver disease, direct thrombin inhibitor, coagulation factor deficiency).</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <p>Anti-Xa, anti-factor Xa activity assay; aPTT, activated partial thromboplastin time; INR, international normalized ratio; PT, prothrombin time.</p>
                        <p>*Best practices for the use and interpretation of coagulation tests in patient on DOACs is evolving and will be informed by ongoing research; the guidance herein reflects a summation of the available evidence.</p>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
            </div>
        </v-dialog>
        
        <v-dialog v-model="display_modal.HalfLifeDTable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="HalfLifeDTable" class="myPopupMessage" style="display: block;">
                <v-card width="100%">
                    <v-app-bar dark color="#5b5d68" dense>                
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.HalfLifeDTable = false">Close</v-btn>
                    </v-app-bar>    

                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <h4 class="text-decoration-underline">Effect of renal failure on half-life of dabigatran</h4>
                        <table border="1" style="width:100%;">
                            <tr class="" style="background-color: lightblue;">
                                <td colspan="2"><div style="font-weight: bold;font-size:16px;display: inline-flex;">Dabigatran (Pradaxa<div style="font-size:11px;">&reg;</div>)</div></td>                                
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Renal Function </td>
                                <td style="font-weight:bold">Half-life*</td>
                            </tr>
                            <tr class="CrCl_normal">
                                <td>Normal to Mild Impairment <br>(CrCl &ge; 50 mL/min)</td>
                                <td>7 – 17 hrs</td>
                            </tr>
                            <tr class="CrCl_moderate">
                                <td>Moderate Impairment <br>(CrCl 30 to 49 mL/min)</td>
                                <td>17 - 20 hrs</td>
                            </tr>
                            <tr class="CrCl_severe">
                                <td>Severe Impairment <br>(CrCl &lt; 30 mL/min)</td>
                                <td>21 - 35 hrs</td>
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Dosing regimens</td>
                                <td>150 mg bid, 110 mg bid, 220 mg daily, 150 mg daily, 110 mg daily, 75 mg daily</td>
                            </tr>
                        </table>
                        CrCl = creatinine clearance
                        <p>*Reported half-lives are estimates and may vary in individual patients depending on drug dosage, administration schedule and comorbid conditions.</p>

                        <p>
                            When estimating amount of drug in circulation remember to consider:
                            <ul>
                                <li>Dosing regimen </li>
                                <li>Timing of last dose </li>
                            </ul>
                        </p>
                        <span class="references">
                            <strong>References</strong>
                            <ol>
                                <li>Stangier et al. Influence of renal impairment on the pharmacokinetics and pharmacodynamics of oral dabigatran etexilate: an open-label, parallel-group, single-centre study. Clin Pharmacokinet. 2010 Apr;49(4):259-68.</li>
                                <li>Stangier, J. et al. The pharmacokinetics, pharmacodynamics and tolerability of dabigatran etexilate, a new oral direct thrombin inhibitor, in healthy male subjects. Br J Clin Pharmacol. 2007; 64:292–303.</li>
                                <li>Blech, S. et al. The metabolism and disposition of the oral direct thrombin inhibitor, dabigatran, in humans. Drug Metab. Dispos. 2008; 36:386–399.</li>
                                <li>Boehringer Ingelheim Canada Ltd. Pradaxa Product Monograph. <a target="_blank" href="https://www.boehringer-ingelheim.com/ca/bipdf/pradaxa-product-monograph">Link</a>. Accessed March 23, 2020</li>
                            </ol>
                        </span>

                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
            </div>
        </v-dialog>    

        <v-dialog v-model="display_modal.HalfLifeATable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="HalfLifeATable" class="myPopupMessage" style="display: block;">
                <v-card width="100%">                    
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.HalfLifeATable = false">Close</v-btn>
                    </v-app-bar>                

                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <h4 class="text-decoration-underline">Effect of renal failure on half-life of apixaban</h4>
                        <table border="1" style="width:100%;">
                            <tr class="" style="background-color: lightgreen;">
                                <td colspan="2"><div style="font-weight: bold;font-size:16px;display: inline-flex;">Apixaban (Eliquis<div style="font-size:11px;">&reg;</div>)</div></td>
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Renal Function </td>
                                <td style="font-weight:bold">Half-life*</td>
                            </tr>
                            <tr class="CrCl_normal">
                                <td>Normal to Mild Impairment <br>(CrCl &ge; 50 mL/min)</td>
                                <td>8 – 12 hrs</td>
                            </tr>
                            <tr class="CrCl_moderate">
                                <td>Moderate Impairment <br>(CrCl 30 to 49 mL/min)</td>
                                <td>8 - 12 hrs</td>
                            </tr>
                            <tr class="CrCl_severe">
                                <td>Severe Impairment <br>(CrCl &lt; 30 mL/min)</td>
                                <td>12 - 17 hrs</td>
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Dosing regimens</td>
                                <td>10 mg bid, 5 mg bid, 2.5 mg bid</td>
                            </tr>
                        </table>
                        CrCl = creatinine clearance
                        <p>*Reported half-lives are estimates and may vary in individual patients depending on drug dosage, administration schedule and comorbid conditions.</p>

                        When estimating amount of drug in circulation remember to consider:
                        <ul>
                            <li>Dosing regimen </li>
                            <li>Timing of last dose </li>
                        </ul>
                        <span class="references">
                            <strong>References</strong>
                            <ol>
                                <li>Chang et al. Effect of renal impairment on the pharmacokinetics, pharmacodynamics and safety of apixaban. J Clin Pharmacol. 2015 Sep 11. doi: 10.1002/jcph.633. [Epub ahead of print]</li>
                                <li>Raghavan, N. et al. Apixaban metabolism and pharmacokinetics after oral administration to humans. Drug Metab. Dispos. 2009; 37:74–81.</li>
                                <li>Frost, C. et al. Apixaban, an oral, direct factor Xa inhibitor: single-dose safety, pharmacokinetics, pharmacodynamics and food effect in healthy subjects. Br. J. Clin. Pharmacol. 2013; 75:476–487.</li>
                                <li>Barrett, Y.C. et al. A randomised assessment of the pharmacokinetic, pharmacodynamic and safety interaction between apixaban and enoxaparin in healthy subjects. Thromb. Haemost. 2012; 107:916–924.</li>
                                <li>Pfizer. Eliquis Product Monograph (2019). <a target="_blank" href="https://www.pfizer.ca/en/our-products/eliquis-apixaban">Link</a>. Accessed October 07, 2019</li>
                            </ol>
                        </span>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
            </div>
        </v-dialog>

        <v-dialog v-model="display_modal.HalfLifeRTable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="HalfLifeRTable" class="myPopupMessage" style="display: block;">
                <v-card width="100%">                    
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.HalfLifeRTable = false">Close</v-btn>
                    </v-app-bar>                
                    
                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <h4 class="text-decoration-underline">Effect of renal failure on half-life of rivaroxaban</h4>
                        <table border="1" style="width:100%;">
                            <tr class="" style="background-color: lightgrey;">
                                <td colspan="2"><div style="font-weight: bold;font-size:16px;display: inline-flex;">Rivaroxaban (Xarelto<div style="font-size:11px;">&reg;</div>)</div></td>                                
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Renal Function </td>
                                <td style="font-weight:bold">Half-life*</td>
                            </tr>
                            <tr class="CrCl_normal">
                                <td>Normal to Mild Impairment <br>(CrCl &ge; 50 mL/min)</td>
                                <td>7 - 11 hrs</td>
                            </tr>
                            <tr class="CrCl_moderate">
                                <td>Moderate Impairment <br>(CrCl 30 to 49 mL/min)</td>
                                <td>7 - 11 hrs</td>
                            </tr>
                            <tr class="CrCl_severe">
                                <td>Severe Impairment <br>(CrCl &lt; 30 mL/min)</td>
                                <td>11 - 15 hrs</td>
                            </tr>
                            <tr class="">
                                <td style="font-weight:bold">Dosing regimens</td>
                                <td>15 mg bid, 20 mg daily, 15 mg daily, 10 mg daily</td>
                            </tr>
                        </table>
                        CrCl = creatinine clearance
                        <p>*Reported half-lives are estimates and may vary in individual patients depending on drug dosage, administration schedule and comorbid conditions.</p>

                        When estimating amount of drug in circulation remember to consider:
                        <ul>
                            <li>Dosing regimen </li>
                            <li>Timing of last dose </li>
                        </ul>

                        <span class="references">
                            <strong>References</strong>
                            <ol>
                                <li>Kubitza D et al. Effects of renal impairment on the pharmacokinetics, pharmacodynamics and safety of rivaroxaban, an oral, direct Factor Xa inhibitor. B J Clin Pharmacol 2010; 70:703-12.</li>
                                <li>Kubitza, D. et al. Safety, pharmacodynamics, and pharmacokinetics of single doses of BAY 59-7939, an oral, direct factor Xa inhibitor. Clin Pharmacol Ther. 2005; 78,:412–421.</li>
                                <li>Kubitza, D. et al. (2005) Safety, pharmacodynamics, and pharmacokinetics of BAY 59-7939 – an oral, direct factor Xa inhibitor – after multiple dosing in healthy male subjects. Eur J Clin Pharmacol. 2005; 61:873–880.</li>
                                <li>Bayer PHarma AG. Xarelto Product Monograph (2023). <a target="_blank" href="https://www.bayer.com/sites/default/files/2020-11/xarelto-pm-en.pdf">Link</a>. Accessed April 17, 2023</li>
                            </ol>
                        </span>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
            </div>
        </v-dialog>
        
        <v-dialog v-model="display_modal.HalfLifeETable" transition="dialog-bottom-transition" persistent max-width="900">
            <div id="HalfLifeETable" class="myPopupMessage" style="display: block;">
                <v-card width="100%">                    
                    <v-app-bar dark color="#5b5d68" dense>
                        <v-spacer/>
                        <v-btn dark text outlined @click="display_modal.HalfLifeETable = false">Close</v-btn>
                    </v-app-bar>

                    <div :class="['px-1', $vuetify.breakpoint.xsOnly ? 'mt-13' : 'mt-5']">
                        <h4 class="text-decoration-underline">Effect of renal failure on half-life of edoxaban</h4>

                        <p>Half-life (t&frac12;) of edoxaban is 10-14 hours and may be prolonged in patients with renal impairment. </p>
                        <p>When estimating amount of drug in circulation remember to consider:</p>
                        <ul>
                            <li>Dosing regimen</li>
                            <li>Timing of last dose</li>
                        </ul>

                        <span class="references">
                            <strong>References</strong>
                            <ol>
                                <li>Servier Canada Inc. Lixiana Product Monograph (2021). <a href='https://servier.ca/wp-content/uploads/sites/24/2022/03/Product-monograph-Lixiana-December-1-2021.pdf?time=1660220963' target='_blank'>Link</a>.</li>
                            </ol>
                        </span>
                        <p style="text-align:right">&nbsp;</p>
                    </div>
                </v-card>
            </div>
        </v-dialog>
</div>
</template>

<script>
import vueCalculators from './vueCalculators.vue'
export default {
	props: {
		language: {
                    type: String,
                    required: true
		},
                activeCalc: {
                    type: String,
                    required: false
                },
	},
	components: {
		vueCalculators
	},
        data() {
            return {
                preop_selected: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: {
                        female: null,
                    },
                    q5: null,
                    q6: null,
                    q7: null,
                    q8: null,
                    q9: null,
                    q10: {doac_effect_level: null, doac_effect_unknown: null,},
                    q11: {weight: null},
                    q12: null,
                },
                preopOptionValueMap: {
                    q1: {elective: 'a', emergency: 'b', urgent: 'c'},
                    q2: {minor_non_dental: 'a', minor_dental: 'b', moderate: 'c', high: 'd'},
                    q3: {apixaban: 'a', dabigatran: 'b', edoxaban: 'c', rivaroxaban: 'd', warfarin: 'e'},
                    q4: {female: null,},
                    q5: null,
                    q6: null,
                    q7: null,
                    q8: null,
                    q9: null,
                    q10: {doac_effect_level: null, doac_effect_unknown: null,},
                    q11: {weight: null},
                    q12: null
                },
                display_modal: {
                    ApixabanTable: false,
                    DabigatranTable: false,            
                    EdoxabanTable: false,
                    RivaroxabanTable: false,                   
                    HalfLifeATable: false,
                    HalfLifeDTable: false,
                    HalfLifeETable: false,
                    HalfLifeRTable: false,
                    PCCDosingWrapper: false,
                },
                debugFlag: false    
            }
        },
        methods: {
            initToolSelection(tool){
                let self = this
                setTimeout(()=>{
                    console.log('reset')
                    if (tool==='preopAlgo'){
                        self.preop_selected=
                            {q1: null,q2: null,q3: null,q4: {female: null,},q5: null,q6: null,q7: null,q8: null,q9: null,q10: {doac_effect_level: null, doac_effect_unknown: null,},q11: {weight: null},q12: null,}
                    }
                    self.$forceUpdate()
                }, 200)
            },
            getManuscriptOption(qid){
                console.log(qid)
                if (this.preop_selected[qid]){
                    let fixIt = this.preop_selected[qid]
                    if (qid=='q2') {
                        fixIt = this.preop_selected[qid].replace(' ', '_').replace('-', '_')
                    }
                    console.log(fixIt)
                    return this.preopOptionValueMap[qid][fixIt]
                }
            },
            toggleDebug(){
                this.debugFlag = !this.debugFlag
            },
            syncDoacEffectLevel(){
                let self = this
                setTimeout(()=>{
                    self.preop_selected.q10.doac_effect_level = self.preop_selected.q10.doac_effect_unknown===true ? null : self.preop_selected.q10.doac_effect_level
                    self.$forceUpdate()
                }, 200)
            },
            mutualExcludeWeight(excludeQID){
                let self = this
                console.log(excludeQID ,self.preop_selected[excludeQID])
                setTimeout(()=>{
                    self.preop_selected[excludeQID].weight = null
                    self.$forceUpdate()
                }, 200)
            }
        },
        computed: {
            isNativeToolCalc(){
                let nativeToolList = ['thrombophiliaAlgorithm', 'vipitAlgorithm', 'vteManagement']
                return nativeToolList.includes(this.activeCalc)
            },
            isLegacyToolCalc(){
                let legacyToolList = ['vivomap261', 'vivomap262', 'vivomap263', 'vivomap271', 'vivomap329']
                return legacyToolList.includes(this.activeCalc)
            },
            debug(){
                return this.debugFlag
            },
            debugView(){
                let routeQuery = this.$route.query
                return routeQuery.debugView!==undefined ? routeQuery.debugView : false
            }
        },
        watch: {
            debugView(after, before){
                return this.debugFlag = after!==false ? after==='show' : false
            }
        }        
}
</script>

<style lang="scss" scoped>
a.toggleExamplesWrapper, a span.toggleExamplesWrapper {
    border-radius: 25px;
    background: #1d2a75;
    color: white;
    text-decoration: none;
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-top: 2px;
    text-align: center;
    padding-top: 2px;
    padding-right: 2px;
    border: 10px solid white;
    font-size: 20px;
    font-weight: bolder;
    font-family: 'Material Design Icons';
}

@media screen and (max-width: 460px) {
    a.toggleExamplesWrapper {
            margin-top: 2px;
    }
}

@media screen and (max-width: 600px) {
    .myPopupMessage {
        font-size: 12px;
        
        .v-app-bar {
            position: fixed;
        }
        
        table {
            margin-top: 12px;
        }
        
        .v-card {
            overflow-x: scroll;
        }
    }
}

</style>
